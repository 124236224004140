import React, {Component} from "react";
import  {withRouter} from "react-router-dom";
import axios from "axios";
import api from "../../../../api";
import {connect} from "react-redux";
import MusicTable from "../../../Fragments/MusicTable/MusicTable";

class ProfileFavorites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            saleData:false,
            favorites: {
                pagination:{
                    nextStart:0
                },
                results:[]
            },
        }
    }
    componentDidMount() {
       this.getFavorites(5)
    }

    getFavorites=(fetch_data)=>{
        this.setState({loading:true})
        let scrollPos = window.pageYOffset;
        if(this.state.favorites.pagination.nextStart<500&&fetch_data!==5){
            this.setState({
                loading:false,
                favorites:{
                    pagination:{
                        nextStart:this.state.favorites.pagination.nextStart+20,
                        totalCount:this.props.state.favorites.length
                    },
                    results:this.state.favorites.results.concat(this.props.state.favorites.slice(this.state.favorites.pagination.nextStart,this.state.favorites.pagination.nextStart+20))
                }
            })
            window.scroll(0, scrollPos)
        }else{
            axios.request( {
                url:`${api.User.Get.url}${this.props.state.auth.user_id}/favoriteSongs?start=${this.state.favorites.pagination.nextStart?this.state.favorites.pagination.nextStart:0}${fetch_data===5?'&pageSize=500':''}`,
                method: api.User.Get.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'cache-control':'no-cache',
                    'Authorization': this.props.state.auth.access_token
                },
            }).then(response=>response.data).then(response=>{
                if(response.pagination){
                    this.props.onGetFavs(response.results)
                    this.setState({
                        favorites:{
                            pagination:{
                                totalCount:response.pagination.totalCount,
                                nextStart:fetch_data===5?20:response.pagination.nextStart
                            },
                            results:this.state.favorites.results.concat(response.results.slice(0,20)),
                        },
                        loading:false,
                    });
                }
            }).catch((err)=>console.log(err)).finally(()=>this.setState({loading:false}))
        }



    }
    render() {
        return (
           this.props.state.favorites && this.props.state.favorites.length ?
                    <MusicTable header={this.props.header}
                                getSongs={this.getFavorites}
                                musicList={this.state.favorites}
                                loading={this.state.loading}
                                Containered={true}
                    />
                    : <p className={'secondary-desc-text'}>Your favorites list is still empty. To add the first one you can press the
                        star button
                        next
                        to any song and it'll appear here in your profile.</p>

        )
    }
}

export default connect(state => ({
                state
            }),
        dispatch => ({
            onGetFavs: (favs) => {
                dispatch({
                    type: "FETCH_FAVS",
                    payload: favs
                })
            },
    }))(withRouter(ProfileFavorites));
