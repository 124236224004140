import React, {Component} from "react";
import './Shop.sass'
import Header from "../../Fragments/Header";
import Footer from "../../Fragments/Footer";
import {Col, DropdownButton, Row, Dropdown, ButtonGroup, Button, Form, Container} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import api from '../../../api';
import MusicTable from "../../Fragments/MusicTable/MusicTable";
import Preloader from "../../Fragments/Preloader";
import axios from "axios";
import MetaTags from "react-meta-tags";
import moment from "moment";


class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            musicList:{},
            categories:{},
            moods:[],
            soundslikes:[],
            savedParams:this.props.pageParams?this.props.pageParams:[],
            tempo:'all',
            gender:'all',
            loading:true,
            genres:[],
            start:0,
            pageLoading:true,
            filtersCollapse:false,
            affiliate:null
        }
        this.search =React.createRef();
        this.header = React.createRef();
    }

    collapseFilters = () => {
        this.setState({
            filtersCollapse:!this.state.filtersCollapse
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const params = {start: this.state.start};
        if(this.props.location.search){
            const urlParams = new URLSearchParams(this.props.location.search);
            for(const p of urlParams){
                if(params[p[0]]){
                    params[p[0]].push(p[1])
                }else{
                    params[p[0]] = [p[1]]
                }
            }
        }
        if(params.token && params.name){
            this.setState({
                affiliate:params.name[0]
            })
            this.props.onRegisterAffiliate({
                key:params.token[0],
                name:params.name[0],
                date:moment()
            })
        }
            axios.request( {
                url:api.Category.url,
                method: api.Category.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response=>{
                this.setState({
                    pageLoading:false,
                    categories:response.data,
                    moods:params.moods?params.moods:[],
                    soundslikes:params.soundslikes?params.soundslikes:[],
                    genres:params.genres?params.genres:[],
                    tempo:params.tempo?params.tempo[0]:'all',
                    gender:params.gender?params.gender[0]:'all',
                })
            })
             axios.request( {
                url:`${api.Songs.url}${this.props.location.search}${this.props.location.search?this.state.savedParams.length?'&':'':this.state.savedParams.length?'?':''}${this.state.savedParams}`,
                method: api.Songs.method,
                headers: {
                    'Accept': 'application/json',
                    'cache-control':'no-cache',
                    'Content-Type': 'application/json'
                }
            }).then(response=> {
                if(this.search.current){
                    this.search.current.value = params.keyword ? params.keyword : '';
                }
                 this.setState({
                     musicList: response.data,
                     start: response.data.pagination ? response.data.pagination.nextStart : 0,
                     loading: false,
                 })
             })

    }
    filterHandler=(e)=>{
        const key = e.target.classList[0].split('-')[0]
        const value =e.target.innerText;
        if(this.state[key].length && this.state[key].includes(value)){
            this.state[key].splice(this.state[key].indexOf(value),1)
        }else{
            if(this.state[key].length===2) {
                this.state[key].shift()
            }
            this.state[key].push(value)
        }
        this.setState({
           ...this.state
        })
    }
    resetFilters=()=>{
        ['genres','soundslikes','moods'].map(singleFilter=>{
           if(this.state[singleFilter].length>=0){
               this.state[singleFilter].map(selectedFilter=>{
                   const firstItemClass = singleFilter+'-'+selectedFilter.split(' ').join('_')+'-button';
                   if(document.getElementsByClassName(firstItemClass)[0]){
                       document.getElementsByClassName(firstItemClass)[0].classList.remove('active');
                   }
                   return null
               })
           }
           return null;

        })
        this.search.current.value='';
        this.setState({
            genres:[],
            soundslikes:[],
            moods:[],
            gender:'all',
            tempo:'all',
        },this.filterSongs)
        this.props.history.push(this.props.history.location?.pathname)
    }
    getSongs=()=>{
        let scrollPos = window.pageYOffset;
        this.setState({loading:true})
        const params = {start: this.state.start};
        if(this.props.location.search){
            const urlParams = new URLSearchParams(this.props.location.search);
            for(const p of urlParams){
                if(Array.isArray(params[p[0]])){
                    params[p[0]].push(p[1])
                }else{
                    params[p[0]] = [p[1]]
                }
            }
        }
        if(this.state.start){
            params['start'] = this.state.start;
        }

        const searchString=  Object.keys(params).map(k =>
            `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');

        this.props.history.push(`${this.props.history.location?.pathname}?${searchString}`);
        axios.request( {
            url:`${api.Songs.url}?${searchString}${this.state.savedParams.length?'&'+this.state.savedParams:''}`,
            method: api.Songs.method,
            headers: {
                'Accept': 'application/json',
                'cache-control':'no-cache',
                'Content-Type': 'application/json'
            }
        }).then(response=>response.data).then(response=>{
            if(response.pagination){
                this.setState({
                    musicList:{
                        ...response,
                        results:this.state.musicList.results.concat(response.results)},
                    start:response.pagination.nextStart,
                    loading:false,
                },()=>window.scroll(0, scrollPos));
            }else{
                this.setState({
                    loading:false,
                });
            }

        })
    }
    filterSongs=()=>{
       this.setState({loading:true,musicList:[]})
        let params =[]
        if(this.search.current){
             params = Object.assign({start:0},
                this.state.moods.length?{moods:[...this.state.moods]}: {},
                this.state.soundslikes.length?{soundslikes:[...this.state.soundslikes]}:{},
                this.state.genres.length?{genres:[...this.state.genres]}:{},
                this.state.gender&& this.state.gender!=='all'?{gender:this.state.gender}:{},
                this.state.tempo && this.state.tempo!=='all'?{tempo:this.state.tempo}:{},
                this.search.current.value!==''?{keyword:this.search.current.value}:{});
        }

        const searchString=  Object.keys(params).map(k =>
            `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
        this.props.history.push({
            pathname: this.props.history.location?.pathname,
            search: searchString
        });
       axios.request( {
            url:`${api.Songs.url}?${searchString}${this.state.savedParams.length?'&'+this.state.savedParams:''}`,
            method: api.Songs.method,
            headers: {
                'Accept': 'application/json',
                'cache-control':'no-cache',
                'Content-Type': 'application/json'
            }
        }).then(response=>response.data).then(response=>
            this.setState({
                musicList:response,
                start:response.pagination?response.pagination.nextStart:0,
                loading:false,
            }));
    }

    render() {
        return (
           <div className={'shopping-page-content'}>
               <MetaTags>
                   <title>{this.props.pageTitle??'Find a Song - Rocket Songs'}</title>
               </MetaTags>
               <Preloader display={this.state.pageLoading?'block':'none'}/>
               <Header active={this.props.pageName?this.props.pageName:'FAS'} ref={this.header} title={this.state.affiliate? <><h2 style={{color:'#f64e2f',fontWeight:400}}>{'Welcome ' + this.state.affiliate+ ' User!'}</h2></>:this.props.title?this.props.title:"Find a song"} subtitle={this.props.subtitle?this.props.subtitle:'BROWSE THE ROCKET SONGS TRACK LIBRARY\n'}/>
              <section className={'shop-filters-section'}>
                  <hr style={{top:'-62px'}} className={'white-wave'}/>
                <Container className={'shop-filters'}>



                    <Row className={'filters-row'}  >
                        <Col><div onClick={this.collapseFilters}
                                     className={'mobile-filters-button'}>Filter</div></Col>
                        <Col><div onClick={this.collapseFilters} className={'mobile-filters-button clearfilters-button'+(!this.state.filtersCollapse?' collapse-open ':'')}><FontAwesomeIcon icon={faCaretDown}/></div></Col>
                    </Row>
                    <Row className={'filters-body '+(this.state.filtersCollapse?'collapsed':'')}>
                        <Col lg={4} md={6} sm={6}>
                            <label className={'filter-label'}>Genres (up to 2)</label>
                            <DropdownButton id="dropdown-basic-button" title={<span>{this.state.genres.length?this.state.genres.toString():"all genres"}</span>}>
                                {this.state.categories.genres ? this.state.categories.genres.map((genre, index) => {
                                    return <Dropdown.Item key={index} onClick={(e)=>{this.filterHandler(e)}} className={'genres-'+genre.name.replace(/ /g,'_')+'-button '+(this.state.genres.includes(genre.name)?'active':'')} as={"button"}>{genre.name}</Dropdown.Item>
                                }) : null}
                            </DropdownButton>
                        </Col>


                        <Col lg={4} md={6} sm={6}>
                            <label className={'filter-label'}>Sounds Like (up to 2)</label>
                            <DropdownButton id="dropdown-basic-button" title={<span>{this.state.soundslikes.length?this.state.soundslikes.toString():"all sounds like"}</span>}>
                                {this.state.categories.soundsLikes ? this.state.categories.soundsLikes.map((soundsLike, index) => {
                                    return <Dropdown.Item key={index} onClick={this.filterHandler} className={'soundslikes-'+soundsLike.name.replace(/ /g,'_')+'-button '+(this.state.soundslikes.includes(soundsLike.name)?'active':'')} as={"button"}>{soundsLike.name}</Dropdown.Item>
                                }) : null}
                            </DropdownButton>
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                            <label className={'filter-label'}>Moods (up to 2)</label>
                            <DropdownButton id="dropdown-basic-button" title={<span>{this.state.moods.length?this.state.moods.toString():"all moods"}</span>}>
                                {this.state.categories.moods ? this.state.categories.moods.map((moods, index) => {
                                    return <Dropdown.Item key={index} onClick={this.filterHandler} className={'moods-'+moods.name.replace(/ /g,'_')+'-button '+(this.state.moods.includes(moods.name)?'active':'')} as={"button"}>{moods.name}</Dropdown.Item>
                                }) : null}
                            </DropdownButton>
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                            <label className={'filter-label'}>Tempo</label>
                            <ButtonGroup className={'select'} aria-label="Basic example">
                                <Button onClick={()=>this.setState({tempo:'all'})} variant={"secondary"} className={"all "+(this.state.tempo==='all'?'active':'')}>all</Button>
                                {this.state.categories.tempos?this.state.categories.tempos.map((tempo,index)=><Button key={index} onClick={()=>this.setState({tempo:tempo.name})} variant={"secondary"} className={tempo.name+' '+(this.state.tempo===tempo.name?'active':'')}>{tempo.name}</Button>):null}
                            </ButtonGroup>
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                            <label className={'filter-label'}>Gender</label>
                            <ButtonGroup aria-label="Basic example">
                                <Button  onClick={()=>this.setState({gender:'all'})} variant={"secondary"} className={"all "+(this.state.gender==='all'?'active':'')}>all</Button>
                                {this.state.categories.genders?this.state.categories.genders.map((gender,index)=><Button key={index} onClick={()=>this.setState({gender:gender.name})} variant={"secondary "+gender.name+' '+(this.state.gender===gender.name?'active':'')}>{gender.name}</Button>):null}
                            </ButtonGroup>
                        </Col>
                        <Col lg={2} md={4} sm={4}><Button onClick={this.filterSongs} className={'find-button'} variant="primary">find
                            songs</Button></Col>
                        <Col lg={2} md={2} sm={2}><Button onClick={this.resetFilters} className={'reset-button'}
                                                          variant="outline-dark">reset</Button></Col>
                    </Row>
                </Container>
              </section>
                <Container className={'search-container'}>
                    <div className={'search-row'}>
                            <Form>
                                <Form.Group className={'search-input'}
                                            controlId="exampleForm.ControlInput1">
                                    <Row style={{width:'100%'}}>
                                        <Col lg={10} md={10} sm={8} xs={7}>
                                            <Form.Control ref={this.search} type="text" placeholder={"Search"}/>
                                        </Col>
                                        <Col lg={2} md={2} sm={4} xs={5}>
                                            <Button type={'submit'} className={'search-button'} onClick={(e)=>{e.preventDefault();this.filterSongs()}} variant="primary">SEARCH</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                    </div>
                </Container>
               <div style={{minHeight:100}}>
               {!this.state.loading && this.state.musicList.results &&!this.state.musicList.results.length?<section className={'search-container'}><Row className={'search-row'}><Col><p >No songs match that search criteria. Try again with different filters. </p></Col></Row> </section>:<MusicTable header={this.header} getSongs={this.getSongs} musicList={this.state.musicList} loading={this.state.loading}/>
               }
               </div>
                <Footer/>
            </div>
        )
    }
}

export default connect( state => ({state}), (dispatch) => ({
    onRegisterAffiliate: (token) => {
        dispatch({
            type: "CREATE_AFFILIATE",
            payload: token
        })
    },
}))(withRouter(Shop));
