import React, { Component } from "react";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import ContactForm from "../../Fragments/ContactForm";
import './ContactUs.sass'

class ContactUs extends Component {
    componentDidMount() {
        document.title = "Contact Us"
    }

    render() {
        return (<div className={"contact-us-page"}>
            <Header title={'Contact Us'} />
            <ContactForm />
            <Footer />
        </div>
        )
    }
}

export default ContactUs;
