import React, { Component } from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import Footer from "../Fragments/Footer";
import Header from "../Fragments/Header";
import { Link } from "react-router-dom";

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible:false
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Frequently Asked Questions"
    }

    render() {
        return (<div className={"faq-page"}>
            <Header title={'Frequently Asked Questions'} />
            <section style={{position:'relative'}}>
                <hr style={{top:'-56px'}} className={'white-wave'}/>
                <Container>
                    <Row>
                        <Col className={'faq'} lg={12} md={12}>
                            <h3 className="title">GENERAL QUESTIONS</h3>
                            <a href="#general-question-q1">What can I do with a song I get from Rocket Songs?</a><br />
                            <a href="#general-question-q2">Can I remix, mash up or alter the song in any way?</a><br />
                            <a href="#general-question-q3">Can I translate a song into another language?</a><br />
                            <a href="#general-question-q4">Can I change the key of the song? Or Is it possible to purchase a song in a different key as the one advertised?</a><br />
                            <a href="#general-question-q5">Can I change the arrangement of a song?</a><br />
                            <a href="#general-question-q6">Can I change the genre of the song?  </a><br />
                            <a href="#general-question-q7">Can I change the lyrics from she to he? From us to them?</a><br />
                            <a href="#general-question-q8">Can I make changes to the lyrics beyond what’s answered above?</a><br />
                            <a href="#general-question-q9">Is there any limit to how many songs from Rocket Songs I can release?</a><br />
                            <a href="#general-question-q10">Are we allowed to record several of the songs and put them on a digital album together as a compilation album?</a><br />
                            <a href="#general-question-q11">For streaming services, how do we sum up the royalty payments to the original songwriters and producers?</a><br />
                            <a href="#general-question-q12">Who is the "Licensee"?</a><br />
                            <a href="#general-question-q13">Does that mean I can transfer the Rocket Songs License?</a><br />
                            <a href="#general-question-q14">How old do I have to purchase a license from Rocket Songs?</a><br />
                            <a href="#general-question-q15">How long does my license last?</a><br />
                            <a href="#general-question-q16">Are the songs I acquire exclusive to me?</a><br />
                            <a href="#general-question-q17">What are the options for an Exclusive?</a><br />
                            <a href="#general-question-q18">Does this Rocket Songs License transfer any ownership to me, the Licensee?</a><br />
                            <a href="#general-question-q19">What do you mean by "Song"?</a><br />
                            <a href="#general-question-q20">What do you mean by "Track"?</a><br />
                            <a href="#general-question-q21">What is a copyright of a Song?</a><br />
                            <a href="#general-question-q22">Who or what is a "Content Provider"?</a><br />
                            <a href="#general-question-q23">What is a New Recorded Master?</a><br />
                            <a href="#general-question-q24">What if I record a new song using the instrumental “Track” from Rocket Songs, who owns the rights to that New Recorded Master?</a><br />
                            <a href="#general-question-q25">Do I own the Songs or Tracks I download?</a><br />
                            <a href="#general-question-q26">What is the copyright of Music Recording?</a><br />
                            <a href="#general-question-q27">Prior to releasing my rendition of the song, what am I required to do?</a><br />
                            <a href="#general-question-q28">If I release and sell a rendition that doesn’t use the Instrumental Backing Tracks provided by Rocket Songs, what am I required to do, and what do I owe?</a><br />
                            <a href="#general-question-q29">How do I get the co-writer and other credit information for the New Song?</a><br />
                            <a href="#general-question-q30">If I release and sell a rendition that uses the Instrumental Backing Tracks provided by Rocket Songs, what am I required to do, and what do I owe?</a><br />
                            <a href="#general-question-q31">When I record a song I licensed from Rocket Songs that doesn’t use the instrumental backing tracks, do I own the rights to that New Recorded Master?</a><br />
                            <a href="#general-question-q34">Can I get a refund if I decide not to use the song?</a><br />
                            <a href="#general-question-q35">Does the Rocket Songs License apply worldwide?</a><br />
                            <a href="#general-question-q36">What happens if I violate this Rocket Songs License?</a><br />
                            <a href="#general-question-q37">What is my obligation to defend and indemnify Rocket Songs?</a><br />
                            <a href="#general-question-q38">Is there a limitation of liability?</a><br />
                            <a href="#general-question-q39">Can this Rocket Songs License be modified?</a><br />
                            <a href="#general-question-q40">How do I report a problem about the website or provide general feedback?</a><br />
                            <a href="#general-question-q41">Is Rocket Songs open to ideas from its users?</a><br />
                            <a href="#general-question-q42">What if my question isn't here?</a><br />
                            <br /><br />

                            <h3 className="title">ACCOUNT MANAGEMENT QUESTIONS</h3>
                            <a href="#account-management-q1">What happens if I've forgotten my password?</a><br />
                            <a href="#account-management-q2">What happens if I've forgotten my email address?</a><br />
                            <a href="#account-management-q3">Can I change my artist name or username?</a><br />
                            <a href="#account-management-q4">How do I change the password to my account?</a><br />

                            <br /><br />

                            <h3 className="title">GENERAL ANSWERS</h3>

                            <p className="highlight" id="general-question-q1">What can I do with a song I get from Rocket Songs?</p>
                            <p>You can record, perform, share, stream, and sell your version of the song anywhere from Spotify to YouTube, and share in the royalties as the artist.</p>
                            <p className="highlight" id="general-question-q2">Can I remix, mash up or alter the song in any way?</p>
                            <p>Absolutely! </p>
                            <p className="highlight" id="general-question-q3">Can I translate a song into another language?</p>
                            <p>Of course! You would be responsible for the translation though.</p>
                            <p className="highlight" id="general-question-q4">Can I change the key of the song? Or Is it possible to purchase a song in a different key as the one advertised?</p>
                            <p>Once you have a song from Rocket Songs you can re-record it in whatever key you want. Since our demos are the end product of days and days of refinement and re-recording it's not feasible to ask our Content Providers to recreate their songs in different keys. Thus, we only offer what you see in our marketplace.</p>
                            <p>NOTE that if you want to use the mixed instrumental of the songs, they are always provided in the key the song was originally recorded.</p>
                            <p className="highlight" id="general-question-q5">Can I change the arrangement of a song?</p>
                            <p>Absolutely.</p>
                            <p className="highlight" id="general-question-q6">Can I change the genre of the song? Can I change the lyrics from she to he? From us to them?</p>
                            <p>YES. You can make minor changes to the lyrics, such as for gender reference, age appropriate concerns, or for your own stylist approach without any additional approval. </p>
                            <p className="highlight" id="general-question-q7">Can I make changes to the lyrics beyond what’s answered above? </p>
                            <p>If you want to make additional lyric changes, please send us the changes you want to make so we can have them approved for you.</p>
                            <p className="highlight" id="general-question-q8">Is there any limit to how many songs from Rocket Songs I can release?</p>
                            <p>No.</p>
                            <p className="highlight" id="general-question-q9">Are we allowed to record several of the songs and put them on a digital album together as a compilation album?</p>
                            <p>Yes, you can record as many songs as you want, and use them for an album or as a single release.</p>
                            <p className="highlight" id="general-question-q10">For streaming services, how do we sum up the royalty payments to the original songwriters and producers?</p>
                            <p>For the streaming revenue generated by your rendition of the song that uses the instrumental mix or STEMS we provide, the streaming royalty rate payable to Rocket Songs is 20% of what you collect. </p>
                            <p>Please also be aware that the streaming companies pay the performance royalties directly to the copyright owners (songwriters and publishers) independently of the streaming revenue.</p>
                            <p className="highlight" id="general-question-q11">Who is the "Licensee"?</p>
                            <p>You individually are the Licensee. Regardless of whether the Licensee is a company or other organization, you agree that you will also be bound by the terms of the Rocket Songs License.</p>
                            <p>If you entered a company or other organization, then the company or organization is the Licensee (and you are assuring us that you have the right to agree to this Rocket Songs License on their behalf).</p>
                            <p className="highlight" id="general-question-q12">Does that mean I can transfer the Rocket Songs License?</p>
                            <p>No, you cannot transfer, assign or sublicense the song. The license is only for use by you, the Licensee.</p>
                            <p className="highlight" id="general-question-q13">How old do I have to purchase a license from Rocket Songs?</p>
                            <p>To purchase a license from Rocket Songs you must be 13 years of age or older.</p>
                            <p className="highlight" id="general-question-q14">How long does my license last?</p>
                            <p>We don’t place any restrictions on the length of time of the license.</p>
                            <p className="highlight" id="general-question-q15">Are the songs I acquire exclusive to me?</p>
                            <p>You can purchase an Exclusive for the song, and this will ensure that no one else can purchase the song from Rocket Songs after you purchase the Exclusive License for as long as the exclusive term you purchased. You can renew your Exclusive License prior to it’s expiration if you need to extend the length of the term.</p>
                            <p className="highlight" id="general-question-q16">What are the options for an Exclusive?</p>
                            <p>Rocket Songs provides 3 Exclusivity options: </p>
                            <p>3-Months </p>
                            <p>6-Months </p>
                            <p>1-Year.  </p>
                            <p>You can renew the term before it expires. All the Exclusive options are posted on the product page of any song. Just click on the title of the song to see the product page.</p>
                            <p className="highlight" id="general-question-q17">Does this Rocket Songs License transfer any ownership to me, the Licensee?</p>
                            <p>No. We are licensing you certain rights, but the songs and the original instrumental tracks we provide continue to be owned by copyright owners of the song.</p>
                            <p className="highlight" id="general-question-q18">What do you mean by "Song"?</p>
                            <p>According to copyright law, a Song is the melody and lyric of a given musical composition.</p>
                            <p className="highlight" id="general-question-q19">What do you mean by "Track"?</p>
                            <p>On Rocket Songs a Track is a fully produced instrumental musical bed with no vocals.</p>
                            <p className="highlight" id="general-question-q20">What is a copyright of a Song?</p>
                            <p>The copyright of a Song is the ownership in what is written and the legal right to the publishing interest in the Song.</p>
                            <p className="highlight" id="general-question-q21">How do I get the copyright and other credit information for the New Song?</p>
                            <p>Rocket Songs can provide you the copyright information (Songwriters and Publishers) upon request at <Link to="mailto:AnR@rocketsongs.com">AnR@rocketsongs.com</Link></p>
                            <p className="highlight" id="general-question-q22">Who or what is a "Content Provider"?</p>
                            <p>A Content Provider is the copyright owner of the master recording of (a) Song(s) and/or Track(s) available for license on Rocket Songs. We license the rights from Content Providers and make their Songs & Tracks available to licensees.</p>
                            <p className="highlight" id="general-question-q23">What is a New Recorded Master?</p>
                            <p>When a Member licenses one of our songs and uses or doesn’t use the associated instrumental backing tracks in the recording of the new vocal, they then create a New Recorded Master.</p>
                            <p className="highlight" id="general-question-q24">What if I record a new song using the instrumental “Track” from Rocket Songs, who owns the rights to that New Recorded Master?</p>
                            <p>You own the rights to that New Recorded Master. However, the writer and publisher of the song own the copyright of the song and the Content Provider owns the instrumental backing tracks you used and therefore are all entitled to be paid from any sales and streams of the recording of your version of the song.</p>
                            <p className="highlight" id="general-question-q25">Do I own the Songs or Tracks I download?</p>
                            <p>We’re granting you a license on behalf of our copyright owners of the song to use the song in a brand new recording, or to use the Track you download in the recording of your vocals in a New Recorded Master.</p>
                            <p>You would own the new recording or video of the song as the “Artist” of the song, and can collect revenue and royalties for your version of the song as the “Artist” of the song. The copyright of the song remains with the songwriters and publishers of the song, and they are entitled to the songwriter and publisher royalties of the song.</p>
                            <p className="highlight" id="general-question-q26">What is the copyright of Music Recording?</p>
                            <p>The copyright of Music Recording is the ownership in the actual recording of a Song and the legal right to sell or license that specific recording. For our purposes, Members who use a Rocket Songs Track to add their vocals have no rights to copyright any recorded master using a Rocket Songs Track, nor do they have any rights to the Track, separate and apart from using it as a sample to create a New Recorded Master.</p>
                            <p className="highlight" id="general-question-q27">Prior to any release of my rendition of the song, what am I required to do?</p>
                            <p>Prior to any commercial release of your rendition of a song, request the songwriter and publisher info of the song (copyright info) by emailing us at <Link to="mailto:AnR@rocketsongs.com">AnR@rocketsongs.com</Link>. You’ll need to include this info on your CD, or when you upload your video or audio to your website, YouTube, Soundcloud, Spotify, iTunes, or any streaming or distribution platform you may use.</p>
                            <p className="highlight" id="general-question-q28">If I release and sell a rendition that doesn’t use the Instrumental Backing Tracks or STEMS provided by Rocket Songs, what am I required to do, and what do I owe?</p>
                            <p>You must credit the Songwriter(s) and Publisher(s) of the song on your release, and pay royalties associated with any sales of the song to Rocket Songs according to the following:</p>
                            <ol>
                                <li>Pay 20% of the revenue to Rocket Songs for administration of the royalties to the Songwriters(s), Publisher(s), and Producer(s) of the song.</li>
                                <li>Royalty payments are due semi-annually, beginning January 1, within fifteen (15) days after the end of each six (6) month period.</li>
                                <li>With your payment, include an accounting of the gross revenue you received along with the name of the song(s) and Content Provider of the song(s).</li>
                                <li > Royalties due are payable to Rocket Songs through PayPal: <Link to="#" onClick={e=>{
                                    e.preventDefault();
                                    this.setState({
                                        modalVisible:true
                                    })
                                }}>royalties@rocketsongs.com	</Link>	Radar Management Group LLC, DBA Rocket Songs</li >
                            </ol >
                            <p className="highlight" id="general-question-q29">If I release and sell a rendition that uses the Instrumental Backing Tracks or STEMS provided by Rocket Songs, what am I required to do, and what do I owe?</p>
                            <p>You must credit the Songwriter(s) and Publisher(s) of the song on your release, and pay royalties associated with any sales of the song to Rocket Songs according to the following:</p>
                            <ol>
                                <li>Pay 30% of the revenue to Rocket Songs for administration of the royalties to the Songwriters(s), Publisher(s), and Producer(s) of the song.</li>
                                <li>Royalty payments are due semi-annually, beginning January 1, within fifteen (15) days after the end of each six (6) month period.</li>
                                <li>With your payment, include an accounting of the gross revenue you received along with the name of the song(s) and Content Provider of the song(s).</li>
                                <li>Royalties due are payable to Rocket Songs through PayPal: <Link to="#" onClick={e=>{
                                    e.preventDefault();
                                    this.setState({
                                        modalVisible:true
                                    })
                                }}>royalties@rocketsongs.com</Link> Radar Management Group LLC, DBA Rocket Songs</li>
                            </ol>
                            <Modal className={'pay-pal-modal'} show={this.state.modalVisible} onHide={() => this.setState({ modalVisible: false })}>
                                <Modal.Body className={'pay-pal-modal-body'}>
                                    <Row>
                                       <Col lg={12}>
                                           <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                               <input type="hidden" name="cmd" value="_s-xclick"/>
                                               <input type="hidden" name="hosted_button_id" value="JXK275MKFSKEC"/>
                                               <table>
                                                   <tr>
                                                       <td><input type="hidden" name="on0" value="Royalties for Which Song"/>Royalties
                                                           for Which Song</td>
                                                   </tr>
                                                   <tr>
                                                       <td><input type="text" name="os0" maxLength="200"/></td>
                                                   </tr>
                                               </table>
                                               <input type="image"
                                                      src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                                                      border="0" name="submit"
                                                      alt="PayPal - The safer, easier way to pay online!"/>
                                               <img alt="" border="0"
                                                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1"
                                                    height="1"/>
                                           </form>
                                       </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>

                            <p className="highlight" id="general-question-q30">When I record a song I licensed from Rocket Songs that doesn’t use the instrumental backing tracks, do I own the rights to that New Recorded Master?</p>
                            <p>Yes. When you re-create the recording of the song without using the instrumental backing tracks provided by Rocket Songs, then you would own 100% of the New Recorded Master. However, the songwriter and publisher of the song own the copyright of the song and therefore are still entitled to those respective royalties paid from any sales of the recording of the song.</p>
                            <p className="highlight" id="general-question-q31">Can I get a refund if I decide not to use the song?</p>
                            <p>No, your purchase is non-refundable because we are selling you permission to use the song (the License), not the song itself.</p>
                            <p className="highlight" id="general-question-q32">Does the Rocket Songs License apply worldwide?</p>
                            <p>Yes, your license under the Rocket Songs License is worldwide.</p>
                            <p className="highlight" id="general-question-q33">What happens if I violate this Rocket Songs License?</p>
                            <p>If you violate any portion of this Rocket Songs License, we can; (a) terminate your song/track license agreement (b) require you to stop using any version of the song in any manner whatsoever, (c) sue you for damages and for an injunction (a court order that prohibits you from doing something or requires you to do something). You’ll have to defend and indemnify us for any claims against us.
                                    You agree that you will pay our reasonable attorneys fees and court costs if we prevail in any lawsuit between you and us (regardless of who brings the suit), and in return, we agree to pay your reasonable attorneys fees and court costs if you prevail.</p>
                            <p className="highlight" id="general-question-q34">What is my obligation to defend and indemnify Rocket Songs?</p>
                            <p>If any third party threatens to, or does, bring a claim against us based in whole or in part on a violation by you of any portion of this Rocket Songs License, then you will defend and indemnify us against any claims, actions, damages, losses, costs, liabilities, settlements, and reasonable attorneys fees and court costs.</p>
                            <p className="highlight" id="general-question-q35">Is there a limitation of liability?</p>
                            <p>Yes. Rocket Songs’ MAXIMUM LIABILITY TO YOU UNDER ANY CIRCUMSTANCES IS TO REFUND THE PRICE YOU PAID FOR THE LICENSE TO THE SONG.</p>
                            <p className="highlight" id="general-question-q36">Can this Rocket Songs License be modified?</p>
                            <p>No modification or waiver of the Rocket Songs License or any provisions is binding unless made in writing and signed by you and by an officer of Rocket Songs. Failure to exercise a right or remedy is not a waiver.</p>
                            <p className="highlight" id="general-question-q37">How do I report a problem about the website or provide general feedback?</p>
                            <p>You can email us at <Link to="mailto:info@rocketsongs.com"> info@rocketsongs.com</Link></p>
                            <p className="highlight" id="general-question-q38">Is Rocket Songs open to ideas from its users?</p>
                            <p>Absolutely! We love hearing new ideas from our users because you guys always have such great ideas. We built Rocket Songs for you and if there’s anything we can do to make it better, let us know at <Link to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link></p>
                            <p className="highlight" id="general-question-q39">What if my question isn't here?</p>
                            <p>Just ask us at <Link to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link></p>
                            <br /><br />
                            <h3 className="title">ACCOUNT MANAGEMENT ANSWERS</h3>
                            <p className="highlight" id="account-management-q1">What happens if I've forgotten my password?</p>
                            <p>No problem. Just click the “Help, I forgot my password” button on the login screen at RocketSongs.com</p>
                            <p className="highlight" id="account-management-q2">What happens if I've forgotten my email address?</p>
                            <p>Please email us at <Link to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link> and we will retrieve it for you.</p>
                            <p className="highlight" id="account-management-q3">Can I change my artist name or username?</p>
                            <p>Yes. Log in to your account. In your dashboard you can edit your username. As long as nobody else is using the same name you should be good to go.</p>
                            <p className="highlight" id="account-management-q4">How do I change the password to my account?</p>
                            <p>Log in to your account, and scroll to the bottom of your account dashboard and update your password.</p>

                        </Col>
                    </Row >
                </Container >
            </section >
            <Footer />
        </div >)
    }
}

export default Faq;
