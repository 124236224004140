export default function favorites(state = [], action){
    if(action.type === 'FETCH_FAVS'){
        return action.payload

    }
    if(action.type === 'ADD_TO_FAVS'){
        return[
            ...state,
           action.payload,
        ]
    }
    if(action.type === 'REMOVE_FROM_FAVS'){
        state=state.filter(fav=>fav.songId!==action.payload.songId)
        return [
            ...state
        ]
    }
    if(action.type === 'LOGOUT'){
        return []
    }

    return state;
}
