import React, {Component} from "react";
import Shop from "./Shop/Shop";

class Masters extends Component {
    componentDidMount() {
        document.title = "Find a song: STEMS Only"
    }
    render() {
        return (
            <Shop pageName={'S'} pageParams={'licenseTypes=BAF94F4B-5BAA-4FF8-8E51-DD07F26056BA&licenseTypes=5E868377-865F-4F70-AE31-F6D51FA79D01&licenseTypes=82D084B5-ACB5-4EC9-A77B-318723350C1B&licenseTypes=6CEC9E1D-B740-441C-95E0-9906931DFD9E&licenseTypes=6CEC9E1D-B740-441C-95E0-9906931DFDFF&licenseTypes=AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'} title={'STEMS'} subtitle={'Get the individual Audio Files Of The Song'}/>
        )
    }
}

export default Masters;
