import React, {Component} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import './ToastComp.sass';

class ToastComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer:null
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.state.notification.message!==prevProps.state.notification.message){
            if(this.state.timer){
                clearTimeout(this.state.timer)
            }
            this.setState({
                timer: setTimeout(()=>{
                    this.props.HideToast()
                },5000)
            })

        }
    }

    render() {
        let notification = this.props.state.notification
        return (
            <div className={'notification-warn'+(notification.message?' show':'')+(notification.type==='success'?' success-nofification':notification.type==='cart-message'?' yellow-nofification':notification.type==='error'?' error-notification':' primary-nofification')}>
                    <div>
                        {notification.message}
                    </div>
                    <div onClick={this.props.HideToast}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        HideToast: () => {
            dispatch({
                type: "HIDE_NOTIFICATION",
            })
        },
    }))(ToastComp);
