import moment from "moment";

export default function subscription(state = {
    lastShown:null,
    subscribed:false
}, action){
    if(action.type === 'SHOW_MODAL'){
        return {
            lastShown:moment().utc(true)
        }
    }
    if(action.type === 'USER_SUBSCRIBED'){
        return {
            subscribed: true
        }
    }

    return state;
}
