import React, {Component} from "react";
import {Button, Col, Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";



class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown:0,
        };
        this.dropdown=React.createRef();

    }


    handleClick(){
        this.dropdown.current.classList.toggle('visible');
        this.props.openFucntion();
    }
    render() {
        return (
            <div className={'resposive-dropdown'}>
                <div ref={this.dropdown} className={'dropdown-content'}>
                    <Row style={{backgroundColor:'#272727'}}>
                        <Col lg={{span: 9,order:1,offset: 1}} md={{span: 9,order:1,offset: 1}} sm={{span: 9,order:1,offset: 1}} xs={{span: 9,order:1,offset: 1}}>
                            <Link onClick={this.handleClick.bind(this)} to={'/HowItWorksArtist'}> <Button className={'dropdown-buttons '+(this.props.active==='AU'?'active':'')}>How it works</Button></Link>
                            <Link onClick={this.handleClick.bind(this)} to={'/shop'}><Button className={'dropdown-buttons '+(this.props.active==='FAS'?'active':'')}>Find a Song</Button></Link>
                            <Link onClick={this.handleClick.bind(this)} to={'/stems'}><Button className={'dropdown-buttons '+(this.props.active==='S'?'active':'')}>STEMS</Button></Link>
                            <Link onClick={this.handleClick.bind(this)} to={'/newsongs'}><Button className={'dropdown-buttons '+(this.props.active==='NS'?'active':'')}>New Songs</Button></Link>
                        </Col>
                        <Col style={{padding:0}} lg={{span: 1,order:2}} md={{span: 1,order:2}} sm={{span: 1,order:2}} xs={{span: 1,order:2}}>
                            <span className={'music-close'} style={{right:'-100%'}} onClick={this.handleClick.bind(this)}><FontAwesomeIcon icon={faWindowClose}/></span>
                        </Col>
                    </Row>
                </div>
                <div  className="resposive-icons">
                    <div onClick={this.handleClick.bind(this)} className={'menu-dropdown-i-c'}>
                        <div className={'menu-dropdown-i'}/>
                    </div>
                    <div onClick={this.props.navOpen} className={'logged-in-icon-container'}>
                        <div className={'logged-in-i'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dropdown;
