import React, {Component} from "react";
import {Container, Button, Card, Col, Dropdown, Form, Row, Spinner} from "react-bootstrap";
import Header from "../../Fragments/Header";
import Footer from "../../Fragments/Footer";
import {Link, withRouter} from "react-router-dom";
import MusicTable from "../../Fragments/MusicTable/MusicTable";
import {connect} from "react-redux";
import api from "../../../api";
import Preloader from "../../Fragments/Preloader";
import AudioPlayer, {RHAP_UI} from "react-h5-audio-player";
import axios from "axios";
import './Songs.sass';
import MetaTags from "react-meta-tags";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const masterLicense = {
    '295': 'baf94f4b-5baa-4ff8-8e51-dd07f26056ba',
    '495': '5e868377-865f-4f70-ae31-f6d51fa79d01',
    '695': '82d084b5-acb5-4ec9-a77b-318723350c1b',
    '895': '6cec9e1d-b740-441c-95e0-9906931dfd9e',
}
const exclusiveLicense = {
    '2ac1e267-27ea-4262-916c-b8ce6bc32637':{
        name:'3-Month Exclusive',
        isExclusive:1,
        price:60
    },
    '3f73a9e4-8954-4d8f-9376-8ce18779a9fd':{
        name:'6-Month Exclusive',
        isExclusive:1,
        price:100
    },
    'efa4e66a-b6f0-427b-adbf-fdab2fa53539':{
        name:'1-Year Exclusive',
        isExclusive:1,
        price:150
    },
    'a1773a78-6a17-4b52-8abd-34da4620e80f':{
        name:'Royalty Free',
        isRoyaltyFree:1,
        price:99
    },
}
class Songs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasExclusivity: false,
            musicList: [],
            music: {},
            loading: true,
            owner: false,
            pageLoading: true,
            playingState:false,
            musicType:false,
            exclusiveLicense:'2ac1e267-27ea-4262-916c-b8ce6bc32637',
            collapse:{},
            selectedSongType:false,
            start:20,
            buttonsLoading:{},
            borderedSongs:false
        }
        this.header = React.createRef();
        this.player = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            loading: true,
        })
        if (this.props.location.search === '?owner=true') {
            this.setState({
                owner: true
            })
        } else {
            this.setState({
                owner: false
            })
        }

        Promise.all([
            axios.request({
                url: `${api.Song.url}${this.props.match.params.id}`,
                method: api.Song.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'cache-control':'no-cache'
                }
            }).then(response => response.data),
            axios.request({
                url: `${api.Song.url}${this.props.match.params.id}/hasExclusiveAvailable`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'cache-control':'no-cache'
                },
                method: api.Song.method,
            }).then(response => response.data),
        ])
            .then(responses => {
                if (responses[0]) {
                    this.setState({
                        pageLoading: false,
                        hasExclusivity: responses[1],
                        music: responses[0],
                    })
                    axios.request({
                        url: `${api['Songs'].url}?genres=${responses[0].genres.includes(',') ? responses[0].genres.split(',')[0] : responses[0].genres}`,
                        method: api['Songs'].method,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'cache-control':'no-cache'
                        }
                    }).then(response => response.data).then(musicList => {
                        document.title = responses[0].title;
                        this.setState({
                            musicList: musicList,
                            loading: false,
                            genres:responses[0].genres.includes(',') ? responses[0].genres.split(',')[0] : responses[0].genres
                        })
                    })
                }

            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            if (document.getElementsByClassName('current-plays')[0]) {
                document.getElementsByClassName('current-plays')[0].classList.remove('current-plays')
            }
            this.player.current.audio.current.src = undefined;
            this.setState({
                owner: false,
                hasExclusivity: true,
                musicList: [],
                music: {},
                loading: false,
                pageLoading: true,
                playingState:false,
                musicType:false,
            })
            this.componentDidMount();
        }
    }
    addToCart = (song, license, licenseData,buttonId) => {
        if(Object.keys(exclusiveLicense).includes(license)){
            licenseData = exclusiveLicense[license]
        }
        const cartData = {
            UserId: this.props.state.auth.user_id,
            items: [
                {
                    ProductId: song.songId,
                    LicenseId: license,
                },
            ]
        };
        if (this.props.state.auth.user_id) {

                if((this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId && item.license.licenseId===license).length)){
                    this.props.Toast({
                        type:'primary',
                        message:'You already have this product in your cart'
                    })
                }else{
                    if(!this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId).length && Object.keys(exclusiveLicense).includes(license)) {
                        this.props.Toast({
                            type:'primary',
                            message:'At first, you should choose one of your products.'
                        })
                    }else {
                    if(buttonId)
                        this.setState({
                            buttonsLoading:{
                                [buttonId]:true
                            }
                        })
                    axios.request({
                        url: api.Cart.Add.url,
                        method: api.Cart.Add.method,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': this.props.state.auth.access_token
                        },
                        data: JSON.stringify(cartData),
                    }).then(response => response.data).then(response => {
                        this.props.onAddtoCart(response.cartItems)
                        this.props.Toast({
                            type:'success',
                            message:'Successfully added to cart'
                        })
                    }).catch(err => {
                        this.props.Toast({
                            type:'primary',
                            message:'At first, you should choose one of your products.'
                        })
                        this.setState({
                            borderedSongs:true
                        })
                    }).finally(()=>{
                        this.setState({
                            buttonsLoading:{}
                        })
                    })
                }
                }

        } else {
            if(!this.props.state.cart?.Items?.filter(item=>item.product.songId===song.songId).length && Object.keys(exclusiveLicense).includes(license)) {
                this.props.Toast({
                    type:'primary',
                    message:'At first, you should choose one of your products.'
                })
            }else {


                const cartItem = {
                    product: song,
                    ProductId: song.songId,
                    LicenseId: license,
                    license: {
                        name: licenseData.name,
                        isRoyaltyFree:licenseData.isRoyaltyFree,
                        isExclusive:licenseData.isExclusive,
                        licenseId: license
                    },
                    price: parseInt(licenseData.price)
                }
                this.props.onAddtoCart(cartItem)
                this.setState({
                    buttonsLoading: {}
                })
                this.props.Toast({
                    type: 'success',
                    message: 'Successfully added to cart'
                })
            }
        }
    }
    addorRemoveFavorites = (music) => {
        if (this.props.state.auth.user_id) {
            const data = JSON.stringify({songId: music.songId})
            if (this.props.state.favorites.length && this.props.state.favorites.filter(song => song.songId === music.songId).length) {
                axios.request({
                    url: `${api.User.RemoveFavorite.url}${this.props.state.auth.user_id}/favorites/${music.songId}`,
                    method: api.User.RemoveFavorite.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.props.onRemoveFromFavs(music)
                    }
                })
            } else {
                axios.request({
                    url: `${api['User']['AddFavorite'].url}${this.props.state.auth.user_id}/favorites`,
                    method: api['User']['AddFavorite'].method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    },
                    data: data
                }).then(response => {
                    if (response.status === 200) {
                        this.props.onAddToFavs(music)
                    }
                })
            }
        } else {
            this.header.current.navOpen();
        }
    }
    onChangeCustomCheckboxes = (e,check)=>{
        let checkbox = e.currentTarget.childNodes[0].childNodes[0];
        if(check){
            if(!checkbox.disabled){
                if(!checkbox.checked){
                    this.setState({
                        selectedSongType:checkbox.value
                    })
                }else{
                    this.setState({
                        selectedSongType:null
                    })
                }

             checkbox.checked = !checkbox.checked
            }
        }else {
            if (document.getElementsByClassName('custom-checked')[0]) {
                document.getElementsByClassName('custom-checked')[0].classList.remove('custom-checked')
            }
            if (!checkbox.checked) {
                e.currentTarget.classList.add('custom-checked')
                checkbox.checked = true

                this.setState({
                    exclusiveLicense: checkbox.value
                })
            } else {
                this.setState({
                    exclusiveLicense: null
                })
                e.currentTarget.classList.remove('custom-checked')
                checkbox.checked = false
            }
        }
    }
    handleSongCardCollapse =(id)=>{
        this.setState({
            collapse:{
                ...this.state.collapse,
                [id]:!this.state.collapse[id]
            }
        })

    }
    getSongs=()=>{
        let scrollPos = window.pageYOffset;
        this.setState({loading:true})
        axios.request( {
            url:`${api.Songs.url}?start=${this.state.start}&genres=${this.state.genres}`,
            method: api.Songs.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response=>response.data).then(response=>{
            if(response.pagination){
                this.setState({
                    musicList:{
                        ...response,
                        results:this.state.musicList.results.concat(response.results)},
                    start:response.pagination.nextStart,
                    loading:false,
                },()=>window.scroll(0, scrollPos));
            }else{
                this.setState({
                    loading:false,
                });
            }

        })
    }
    render() {
        const ifPerf = this.props.state.auth.access_token && (this.props.state.auth.user.type === 'Provider' || this.props.state.auth.user.type === 'Applicant');
        const {music} = this.state;
        return (
            <div className={'songs-page'}>
                <MetaTags>
                    <title>{music.title} - Rocket Songs</title>
                </MetaTags>
                <Preloader display={this.state.pageLoading ? 'block' : 'none'}/>
                <Header ref={this.header} title={music.title} subtitle={music.owner?<Link className={'def-link'} to={`/TrackOwner/${music.owner.vendorId}`}>{music.owner.name}</Link>:null}/>

                <div className={'Single-player'}>
                    <AudioPlayer ref={this.player} autoPlay={false}
                                 layout={'horizontal'}
                                 autoPlayAfterSrcChange={false}
                                 src={music.vocals?music.vocals:undefined}
                                 customProgressBarSection={[
                                     <div className={'standard-col first-order'}>
                                         <div className={'player-icon-container'}>
                                             <div onClick={(e)=>{
                                                 if( this.player.current&&this.player.current.audio.current.src!==music.vocals) {
                                                     this.player.current.audio.current.src = music.vocals;
                                                     this.setState({
                                                         musicType:false,
                                                         playingState:true
                                                     })
                                                 }else if(this.player.current){
                                                     this.setState({
                                                         playingState:!this.state.playingState
                                                     })
                                                 }
                                                 this.player.current.togglePlay(e)
                                             }}  className={'song-m-container '+(!this.state.musicType?'current-type-play':'')}>
                                                 <div className={'song-icon'}/>
                                             </div>
                                             Song
                                         </div>
                                         {music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted &&<div onClick={(e)=>{
                                             if( this.player.current&&this.player.current.audio.current.src!==music.mp3InstrumentalClip) {
                                                 this.player.current.audio.current.src = music.mp3InstrumentalClip;
                                                 this.setState({
                                                     musicType:true,
                                                     playingState:true
                                                 })
                                             }else if(this.player.current){
                                                 this.setState({
                                                     playingState:!this.state.playingState
                                                 })
                                             }
                                             this.player.current.togglePlay(e)
                                         }} className={'player-icon-container'}>
                                             <div className={'track-m-container '+(this.state.musicType?'current-type-play':'')}>
                                                 <div className={'track-icon '}/>
                                             </div>
                                             TRACK
                                         </div>}
                                     </div>,
                                     <div className={'controls-container'}>
                                         <div onClick={()=>{
                                            this.player.current.setJumpTime(-10000)
                                         }} className={'rollback-m-container'}>
                                             <div className={'rollback-icon'}/>
                                         </div>
                                         <div className={'play-pause-layer-3'}>
                                             <div className={'play-pause-layer-2'} onClick={(e)=>{
                                                 this.setState({
                                                     playingState:!this.state.playingState
                                                 });
                                                 this.player.current.togglePlay(e);
                                             }}>
                                                 <div className={'play-pause-layer-1'}>
                                                     <div className={'play-pause-m-container'}>
                                                         <div className={'play-p-icon '+(!this.state.playingState?'paused':'')}/>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                         <div onClick={()=>{
                                             this.player.current.setJumpTime(10000)
                                         }} className={'reversed rollback-m-container'}>
                                             <div className={'rollback-icon'}/>
                                         </div>
                                     </div>,
                                     RHAP_UI.CURRENT_LEFT_TIME,
                                     RHAP_UI.PROGRESS_BAR,
                                     RHAP_UI.DURATION,
                                     RHAP_UI.VOLUME_CONTROLS,
                                     <div className={'standard-col second-order'}>
                                         <div className={'icon-container '+(ifPerf?'disabled-button ':' ') + (this.props.state.auth.user && this.props.state.favorites.filter(fav => fav.songId === music.songId).length ? 'myfav' : '')}>
                                             <div onClick={(e) =>this.addorRemoveFavorites(music, e)} className={'favorite-mask-container'}>
                                                 <div className={'favorite-icon'}/>
                                             </div>
                                             FAVORITES
                                         </div>
                                         <Dropdown className={'shop-col standard-col filtered-dropdown'}>
                                             <Dropdown.Toggle className={'icon-container'} disabled={ifPerf}>
                                                 <div className={'buy-mask-container'}>
                                                     <div className={'buy-icon'}/>
                                                 </div>
                                                 BUY
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu disabled={true}>
                                                 <Dropdown.Item onClick={() =>this.addToCart(music, music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted?'281689e0-b84e-4320-8b1e-17a9d367600c':'43a75d7c-841e-49d7-892f-a23aaccd31ad', {
                                                     name: 'STANDARD LICENSE',
                                                     price:music.hasWavInstrumental && !music.isAllInstrumentalsDeleted ?'60': music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'50':'30'
                                                 })} className={'d-song-item'}>
                                                     <div className={'d-song-mask-container'}>
                                                         <div className={'d-song-icon'}/>
                                                     </div>
                                                     SONG
                                                 </Dropdown.Item>
                                                 {music.master && !music.isSTEMSDeleted &&<Dropdown.Item onClick={() => this.addToCart(music, masterLicense[music.master], {
                                                     name: 'MASTER USE LICENSING',
                                                     price: music.master
                                                 })}  className={'d-stems-item'}>
                                                     <div className={'d-stems-mask-container'}>
                                                         <div className={'d-stems-icon'}/>
                                                     </div>
                                                     STEMS</Dropdown.Item>}
                                             </Dropdown.Menu>
                                         </Dropdown>
                                     </div>,

                                 ]}
                                 customControlsSection={[

                                 ]}/>
                </div>
                <Container className={'song-info-section'}>
                    <h3 style={{textAlign:'center',margin:'40px 0'}}>Sell, stream, and share your rendition of our songs anywhere from
                        iTunes to Spotify to YouTube!</h3>
                    {!this.state.hasExclusivity?<h4 style={{textAlign:'center',margin:'40px 0',color:'red'}}>This song is currently on Exclusive License hold and is not available for purchase</h4>:null}
                    <Row  className={'song-cards'}>
                        <Col lg={4} md={12} className={'song-section-cards'}>
                            <Card className={'lyrics-card'} style={{textAlign:'center'}}>
                                <Card.Body>
                                    <div className={'lyrics-song-title'}>{music.title}</div>
                                    <h5>Lyrics</h5>
                                    <div className="lyric-txt">
                                        {music.lyrics?.replace(/(<([^>]+)>)|(&rsquo;)|(&nbsp;)/gi , (matched)=>{
                                            if(matched==='&rsquo;'){
                                                return '\''
                                            }else if(matched==='<br />'){
                                                return '\n'
                                            }else {
                                             return ''
                                            }
                                        })}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className={'song-section-cards'}>
                            <Card  className={'product-card '+(this.state.borderedSongs?'border-warn':'')}>
                                <Card.Header>
                                    <div onClick={e=>this.onChangeCustomCheckboxes(e,true)}  className={'custom-check-container'}>
                                        <Form.Check disabled={!((this.state.hasExclusivity && !ifPerf) || this.state.owner)}  type="radio" name={'song-type'} value={music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted?'281689e0-b84e-4320-8b1e-17a9d367600c':'43a75d7c-841e-49d7-892f-a23aaccd31ad'} label={  <div className={'title'}> <div className={'song-comp-m-container'+(music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted?' has':'')}>
                                            <div className={'song-comp-i'}/>
                                        </div>SONG{ music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted&&' + TRACK'} ${music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted ?'60':music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted?'50':'30'}</div>} />
                                    </div>
                                    <div className={'dropdown-col'} onClick={e=>this.handleSongCardCollapse('f')} ><FontAwesomeIcon icon={faCaretDown}/></div>
                                </Card.Header>
                                <Card.Body className={this.state.collapse['f']?'collapsed-card':''}>

                                    <span className={'month-div'}>Ideal if you plan to re-record your song from scratch. <br/>Here’s what you get:</span>
                                    <Row className={'checked-rows'}>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Song with vocals
                                        </Col>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Copy of lyrics
                                        </Col>
                                        {music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted&&<><Col lg={12}>
                                        <div className={'collapse-check-icon-container'}>
                                            <div className={'collapse-check-icon'}/>
                                        </div>
                                        Track - instrumental version of song (MP3)
                                    </Col>
                                        {music.hasWavInstrumental &&  !music.isWAVDeleted && !music.isAllInstrumentalsDeleted &&<Col lg={12}>
                                        <div className={'collapse-check-icon-container'}>
                                            <div className={'collapse-check-icon'}/>
                                        </div>
                                        Track - instrumental version of song (WAV)
                                    </Col>}</>}

                                    </Row>
                                    <div className={'select-button-container'}>
                                        <Button disabled={!((this.state.hasExclusivity && !ifPerf) || this.state.owner)} onClick={() =>this.addToCart(music, music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'281689e0-b84e-4320-8b1e-17a9d367600c':'43a75d7c-841e-49d7-892f-a23aaccd31ad', {
                                            name: 'STANDARD LICENSE',
                                            price: music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted ?'60':music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'50':'30'
                                        },'song')}  className={'find-button'}>{this.state.buttonsLoading['song']?<Spinner animation="border"/>:'SELECT PRODUCT'}</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className={'song-section-cards'}>
                            <Card className={'product-card '+(this.state.borderedSongs?'border-warn':'')} >
                                <Card.Header>
                                    <div onClick={e=>this.onChangeCustomCheckboxes(e,true)}  className={'custom-check-container'}>
                                        <Form.Check disabled={!(music.master && !music.isSTEMSDeleted && ((this.state.hasExclusivity && !ifPerf)  || this.state.owner))}   type="radio" name={'song-type'} value={masterLicense[music.master]} label={   <div className={'title has'}><div className={'stems-mask-container'}>
                                            <div className={'stems-icon'}/>
                                        </div>STEMS  {music.master && !music.isSTEMSDeleted?'$'+music.master:'Unavailable'}</div>} />
                                    </div>
                                    <div className={'dropdown-col'} onClick={e=>this.handleSongCardCollapse('e')}><FontAwesomeIcon icon={faCaretDown}/></div>
                                </Card.Header>
                                <Card.Body className={this.state.collapse['e']?'collapsed-card':''}>
                                    <span className={'month-div'}>Ideal if you plan to record on a DAW:<br/> Here’s what you get:</span>
                                    <Row className={'checked-rows'}>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Song with vocals
                                        </Col>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Copy of lyrics
                                        </Col>
                                        {(music.hasWavInstrumental ||music.mp3InstrumentalClip)&& !music.isAllInstrumentalsDeleted&& <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Track - instrumental version of song ({music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted&&'MP3'}{music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted && !music?.isWAVDeleted&&'/WAV'})
                                        </Col>}

                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            The digital multi-track master STEM files (Pro Tools, Logic, etc)
                                        </Col>
                                        <span className={'month-div'}> *Please keep in mind that it may take up to 5 business days to provide you the STEM files.</span>
                                    </Row>
                                    <div className={'select-button-container'}>
                                        <Button disabled={!(music.master && !music.isSTEMSDeleted && ((this.state.hasExclusivity && !ifPerf)  || this.state.owner))} className={'find-button'}  onClick={() => this.addToCart(music, masterLicense[music.master], {
                                            name: 'MASTER USE LICENSING',
                                            price: music.master
                                        })}>{this.state.buttonsLoading['master']?<Spinner animation="border"/>:'SELECT PRODUCT'}</Button>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className={'mobile-add-button'}>
                            <Button disabled={!((this.state.hasExclusivity && !ifPerf)  || this.state.owner) || !this.state.selectedSongType} className={'find-button'}  onClick={() =>{
                                this.addToCart(music, this.state.selectedSongType, (this.state.selectedSongType===masterLicense[music.master]?{
                                    name: 'MASTER USE LICENSING',
                                    price: music.master
                                }:{
                                    name: 'STANDARD LICENSE',
                                    price: music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted ?'60':music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'50':'30'
                                }),'master')
                            } }>{this.state.buttonsLoading['global']?<Spinner animation="border"/>:'SELECT PRODUCT'}</Button>
                        </Col>
                        <Col className={'exclusive-col'} lg={12}>
                            <Card >
                                <Card.Body>
                                    <div className={'exclusive-flex-container'}>
                                        <div className={'exclusive-title'}>
                                            <div className={'exclusive-m-container'}>
                                                <div className={'exclusive-icon'}/>
                                            </div>

                                        </div>
                                        <div  className={'exclusive-text'}>
                                            <div className={'title'}>EXCLUSIVE</div>
                                            <span className={'month-div'}>Make this song exclusively yours by taking it out of our Marketplace.</span>
                                            <Row className={'checked-rows'}>
                                                <Col lg={12}>
                                                    <div className={'collapse-check-icon-container'}>
                                                        <div className={'collapse-check-icon'}/>
                                                    </div>
                                                    Exclusive use of song for the term you select.
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className={'exclusive-select'}>
                                            <div onClick={this.onChangeCustomCheckboxes}  className={'custom-check-container'+(this.state.exclusiveLicense==='2ac1e267-27ea-4262-916c-b8ce6bc32637'?' custom-checked':'')}>
                                                <Form.Check checked={this.state.exclusiveLicense==='2ac1e267-27ea-4262-916c-b8ce6bc32637'}  type="radio" name={'master-price'} value={'2ac1e267-27ea-4262-916c-b8ce6bc32637'} label={<div className={'title'}>$60 <span className={'month-div'}> / 3 months</span></div>} />
                                            </div>
                                        </div>
                                        <div className={'exclusive-select'}>
                                            <div onClick={this.onChangeCustomCheckboxes}  className={'custom-check-container'}>
                                                <Form.Check checked={this.state.exclusiveLicense==='3f73a9e4-8954-4d8f-9376-8ce18779a9fd'} type="radio" name={'master-price'} value={'3f73a9e4-8954-4d8f-9376-8ce18779a9fd'} label={<div className={'title'}>$100 <span className={'month-div'}> / 6 months</span></div>} />
                                            </div>
                                        </div>
                                        <div className={'exclusive-select'} style={{marginRight:'25px'}}>
                                            <div onClick={this.onChangeCustomCheckboxes}  className={'custom-check-container'}>
                                                <Form.Check checked={this.state.exclusiveLicense==='efa4e66a-b6f0-427b-adbf-fdab2fa53539'} type="radio" name={'master-price'} value={'efa4e66a-b6f0-427b-adbf-fdab2fa53539'} label={<div className={'title'}>$150 <span className={'month-div'}> / 12 months</span></div>} />
                                            </div>
                                        </div>
                                        <div className={'exclusive-button'}>
                                            <Button disabled={!((this.state.hasExclusivity && !ifPerf) || this.state.owner)||!this.state.exclusiveLicense} className={'find-button'} onClick={() => this.addToCart(music, this.state.exclusiveLicense, 'exclusive','exclusive')} >{this.state.buttonsLoading['exclusive']?<Spinner animation="border"/>:'ADD TO PRODUCT'}</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                         <Col className={'exclusive-col '+(!music.hasRoyaltyFree?'disabled-button':'')} lg={12}>
                            <Card >
                                <Card.Body>
                                    <div className={'exclusive-flex-container'}>
                                        <div className={'exclusive-title'}>
                                            <div className={'exclusive-m-container'}>
                                                <div className={'exclusive-icon royalty'}/>
                                            </div>

                                        </div>
                                        <div  className={'exclusive-text'}>
                                            <div className={'title'}>ROYALTY FREE</div>
                                            <span className={'month-div'}>Keep all the royalties you earn.</span>
                                        </div>
                                        <div className={'exclusive-select'} style={{marginRight:'25px',marginLeft:'auto'}}>
                                            <div   className={'custom-check-container custom-checked'} >
                                                <Form.Check checked={true}  type="radio" name={'royalty_free'} value={'2ac1e267-27ea-4262-916c-b8ce6bc32637'} label={<div className={'title'}>$99</div>} />
                                            </div>
                                        </div>
                                        <div className={'exclusive-button'}>
                                            <Button disabled={!music.hasRoyaltyFree} className={'find-button'} onClick={() => this.addToCart(music, 'a1773a78-6a17-4b52-8abd-34da4620e80f', 'exclusive','royalty')} >{this.state.buttonsLoading['royalty']?<Spinner animation="border"/>:'ADD TO PRODUCT'}</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>

                    <h3 className="may-like">You may also like these songs</h3>
                    <MusicTable header={this.header} getSongs={this.getSongs} musicList={this.state.musicList}
                                loading={this.state.loading}/>
                </Container>
                <Footer/>
            </div>
        )
    }
}

export default connect(state => ({
        state
    }),
    dispatch => ({
        onAddtoCart: (cartData) => {
            dispatch({
                type: "ADD_TO_CART",
                payload: cartData
            })
        },
        onCartFetch: (cart) => {
            dispatch({
                type: "FETCH_CART",
                payload: cart
            })
        },
        onAddToFavs: (music) => {
            dispatch({
                type: "ADD_TO_FAVS",
                payload: music
            })
        },
        onRemoveFromFavs: (music) => {
            dispatch({
                type: "REMOVE_FROM_FAVS",
                payload: music
            })
        },
        Toast: (data) => {
            dispatch({
                type: "NOTIFICATE",
                payload: data
            })
        },

    }))(withRouter(Songs));
