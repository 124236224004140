import React, {Component,Suspense, lazy} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import HomePage from "./Components/Pages/HomePage/HomePage";
import Shop from "./Components/Pages/Shop/Shop";
import Masters from "./Components/Pages/Masters";
import NewSongs from "./Components/Pages/NewSongs";
import Legal from "./Components/Pages/Legal";
import Team from "./Components/Pages/Team";
import LicenseAgreement from "./Components/Pages/LicenseAgreement";
import Faq from "./Components/Pages/Faq";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import TrackOwner from "./Components/Pages/TrackOwner";
import Songs from "./Components/Pages/Songs/Songs";
import Profile from "./Components/Pages/Profile/Profile";
import Cart from "./Components/Pages/Cart/Cart";
import Preloader from "./Components/Fragments/Preloader";
import SubscriptionThanks from "./Components/Pages/SubscriptionThanks/SubscriptionThanks";
import ErrorPage from "./Components/Pages/ErrorPage/ErrorPage";
import ResetPassword from "./Components/Fragments/ResetPassword";
import HowItWorks from "./Components/Pages/HowItWorks/HowItWorks";
import ContentPartner from "./Components/Pages/ContentPartner/ContentPartner";
import Checkout from "./Components/Pages/Checkout/Checkout";
const AutoLogin = lazy(() => import('./Components/Fragments/AutoLogin'));
const NotFoundPage = lazy(() => import('./Components/Pages/ErrorPage/NotFoundPage'));
const VendorSubscription = lazy(() => import('./Components/Pages/VendorSubscription/VendorSubscription'));
const ThanksPage = lazy(() => import('./Components/Pages/ThanksPage'));
const Verification = lazy(() => import('./Components/Fragments/Verification'));
class Routes extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/HowItWorksArtist" component={HowItWorks}/>
                    <Route exact path="/BecomeAContentPartner" component={ContentPartner}/>
                    <Route exact path="/shop" component={Shop}/>
                    <Route exact path="/stems" component={Masters}/>
                    <Route exact path="/newsongs" component={NewSongs}/>
                    <Route exact path="/Legal" component={Legal}/>
                    <Route exact path="/LicenseAgreement" component={LicenseAgreement}/>
                    <Route exact path="/Team" component={Team}/>
                    <Route exact path="/FAQ" component={Faq}/>
                    <Route exact path="/ContactUs" component={ContactUs}/>
                    <Route exact path="/checkout" component={Checkout}/>
                    <Route exact path="/TrackOwner/:id" component={TrackOwner}/>
                    <Route exact path="/TrackOwners/:name/Songs/:id/:songname/"   component={Songs}/>
                    <Route exact path="/Cart" >{(!this.props.state.auth.vendor_id && this.props.state.auth.user_id) || (!this.props.state.auth.vendor_id && !this.props.state.auth.user_id)?<Cart/>:<Redirect to={'/'}/>}</Route>
                    <Route exact path="/Profile/:part?" >{this.props.state.auth.user?<Profile/>:<Redirect to={'/'}/>}</Route>
                    <Route exact path="/Error" component={ErrorPage}/>

                    <Route exact path="/ResetPassword/:token" component={ResetPassword}/>

                    <Suspense fallback={<Preloader/>}>
                        <Switch>
                            <Route exact path="/Shop/Thanks/:token?/:payed?" component={ThanksPage}/>
                            <Route exact path="/autologin" component={AutoLogin}/>
                            <Route exact path="/VendorSubscription" >{this.props.state.auth.vendor_id && !this.props.state.auth.user.hasActiveSubscription?<VendorSubscription/>:<Redirect to={'/'}/>}</Route>
                            <Route exact path="/Subscription/Thanks" component={SubscriptionThanks}/>
                            <Route exact path="/User/Verify" component={Verification}/>
                            <Route exact path="**" component={NotFoundPage}/>
                        </Switch>
                    </Suspense>

                </Switch>

            </BrowserRouter>
        )
    }
}

export default connect( state => ({
        state
    }),
    dispatch => ({
    }))(Routes)
