import React, {Component,Suspense} from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup, Modal,
    Row,
    Spinner,
} from "react-bootstrap";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import {
    faCheck,
    faTrashAlt, faUpload,
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import interceptImg from "../../../images/intercept.gif";
import landrImg from "../../../images/Landr.png";
import chordImg from "../../../images/chordify.png";
import bitImg from "../../../images/Profile/bit_logo_transparent.png";
import {Link, withRouter} from "react-router-dom";
import api from "../../../api";
import {connect} from "react-redux";
import {Editor} from '@tinymce/tinymce-react';
import ProfilePlaceholder from "../../../images/placeholder-music.png"
import Preloader from "../../Fragments/Preloader";
import MySales from "./Fragments/MySales";
import ResetPassword from "./Fragments/ResetPassword";
import ProviderConfirmation from "./Fragments/ProviderConfirmation";
import WaitingConfirmation from "./Fragments/WaitingConfirmation";
import './Profile.sass';
import ProfileFavorites from "./Fragments/ProfileFavorites";
import ProfileLicensed from "./Fragments/ProfileLicensed";
const SongUploadModal = React.lazy(() => import('./Fragments/SongUpload/SongUploadModal'));

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            errors: {},
            pageLoading: true,
            uploadImg: false,
            imgUploadError: null,
            loading: true,
            vendorLoading: false,
        }
        this.myProfile = React.createRef();
        this.licensedSongs = React.createRef();
        this.myFavorites = React.createRef();
        this.mySongs = React.createRef();
        this.mySales = React.createRef();
        this.resetPass = React.createRef();
        this.artistSerivces = React.createRef();
        this.player = React.createRef();


    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Profile - Rocket Songs";
        axios.request({
            url: `${api.User.Get.url}${this.props.state.auth.user_id}`,
            method: api.User.Get.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.access_token
            }
        }).then(response => response?.data).then(response => {
            response.vendorImageLink= response.vendorImageLink?response.vendorImageLink:null
            this.props.onUpdateProfile(response)
            if(!response.hasActiveSubscription && response.type==='Provider'){
                this.props.history.push('/VendorSubscription')
            }
            this.setState({
                userData: response,
                pageLoading: false,
                loading: false,
            })
        }).catch((err)=>{
            console.log(err)
        })


    }

    handleChange = (e) => {
        this.setState({
            userData: {
                ...this.state.userData,
                [e.target.name]: e.target.value,
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.part && this.props.match.params.part !== 'Verify') {
            if (this[this.props.match.params.part].current)
                this[this.props.match.params.part].current.scrollIntoView({behavior: 'smooth'});
            this.props.history.replace('/Profile')
        }
    }

    updateUserInfo = event => {
        this.setState({
            loading: true,
        })

        const form = event.currentTarget;
        event.preventDefault();
        const data = JSON.stringify(this.state.userData);
        if (form.checkValidity() === true) {
            axios.request({
                url: `${api.User.Set.url}${this.props.state.auth.user_id}`,
                method: api.User.Set.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                data: data
            }).then(response => {
                if (response.status === 200) {
                    if(this.state.uploadImg){
                        this.vendorUploadHandler()
                    }else{
                        this.setState({
                            loading: false
                        })
                    }

                    this.props.onUpdateProfile(this.state.userData)
                    this.setState({
                        profMess: {status: true, message: 'Your information has been updated'},
                    })
                }
            }).catch(error => {
                this.setState({profMess: {status: false, message: 'Somethings wrong'}, loading: false})
            })

        } else {
            this.setState({passMess: {status: false, message: 'Somethings wrong'}, loading: false})
        }
    }


    crop = (url) => {
        return new Promise(resolve => {
            const inputImage = new Image();
            inputImage.onload = () => {
                let inputWidth = inputImage.naturalWidth;
                let inputHeight = inputImage.naturalHeight;
                let outputWidth = inputWidth;
                let outputHeight = inputHeight;
                if ((inputWidth > 255) || (inputHeight > 255)) {
                    let ratio = Math.min(255 / inputWidth, 255 / inputHeight);
                    outputWidth = inputWidth * ratio;
                    outputHeight = inputHeight * ratio;
                }
                const outputImage = document.createElement('canvas');

                outputImage.width = outputWidth;
                outputImage.height = outputHeight;
                const ctx = outputImage.getContext('2d');
                ctx.drawImage(inputImage, 0,0,outputWidth, outputHeight);
                resolve(outputImage);
            };
            inputImage.src = url;
        })

    }
    vendorUploadHandler = () => {
        this.setState({
            loading:true
        })
        const dataURI = this.state.uploadImg;
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const imgObject = new Blob([ab], {type: mimeString});
        const formData = new FormData();
        formData.append('VendorId', this.props.state.auth.user.owner.vendorId)
        formData.append('Image', imgObject);
        axios.request({
            url: api.User.UploadImg.url,
            method: api.User.UploadImg.method,
            headers: {
                'Authorization': this.props.state.auth.access_token
            },
            data: formData,
        }).then(response => {
            if (response.status === 200) {
                document.location.reload();
            }
        }).catch(error => {
            this.setState({
                imgUploadError: 'Somethings went wrong',
            })
        })

    }







    render() {

        const ifPerf = this.props.state.auth.user.type === 'Provider' || this.props.state.auth.user.type === 'Applicant';
        let cityKey;
        let countryKey;
        if (ifPerf) {
            cityKey = 'vendorCity';
            countryKey = 'vendorCountry';
        } else {
            cityKey = 'city';
            countryKey = 'country';
        }
        return (
            <div className={'profile-page'+((this.props.state.auth.user.isApplicant && !this.props.state.auth.user.applicantSongs.length)||this.props.state.auth.user.applicantSongs.length ?' applicant-page':'')}>
                <Preloader display={this.state.pageLoading ? 'block' : 'none'}/>

                <Header ref={this.header} title={(this.props.state.auth.user.isApplicant && !this.props.state.auth.user.applicantSongs.length)||this.props.state.auth.user.applicantSongs.length?"Have Songs? Become a Content Partner":"Profile"}/>
                {this.props.state.auth.user.isApplicant && !this.props.state.auth.user.applicantSongs.length ?
                    <ProviderConfirmation/> : this.props.state.auth.user.applicantSongs.length ?
                        <WaitingConfirmation applicantSongs={this.props.state.auth.user.applicantSongs}/> : <div>
                            <div className={'profile-header-menus'}>
                                <Container >
                                    <Row>
                                        <Col lg={!ifPerf?{offset:1,span:2}:3} md={!ifPerf?{offset:1,span:2}:3} sm={12} xs={12}><Link onClick={(e) => {
                                            e.preventDefault();
                                            this.myProfile.current.scrollIntoView({behavior: 'smooth'})
                                        }} className={'profile-header-links'} to={'#'}>My Profile</Link></Col>

                                        {!ifPerf ? <Col lg={2} md={2} sm={12} xs={12}><Link onClick={(e) => {
                                                e.preventDefault();
                                                this.licensedSongs.current.scrollIntoView({behavior: 'smooth'})
                                            }} className={'profile-header-links'} to={'#'}>Licensed Songs</Link></Col> :
                                            <Col  lg={3} md={3} sm={12} xs={12}><Link onClick={(e) => {
                                                e.preventDefault();
                                                this.mySongs.current.scrollIntoView({behavior: 'smooth'})
                                            }} className={'profile-header-links'} to={'#'}>My Songs</Link></Col>
                                        }

                                        {!ifPerf ? <Col lg={2} md={2} sm={12} xs={12}><Link onClick={(e) => {
                                                e.preventDefault();
                                                this.myFavorites.current.scrollIntoView({behavior: 'smooth'})
                                            }} className={'profile-header-links'} to={'#'}>My Favorites</Link></Col>
                                            :
                                            <Col  lg={3} md={3} sm={12} xs={12}><Link onClick={(e) => {
                                                e.preventDefault();
                                                this.mySales.current.scrollIntoView({behavior: 'smooth'})
                                            }} className={'profile-header-links'} to={'#'}>My Sales</Link></Col>}

                                        <Col lg={!ifPerf?2:3} md={!ifPerf?2:3} sm={12} xs={12}><Link onClick={(e) => {
                                            e.preventDefault();
                                            this.resetPass.current.scrollIntoView({behavior: 'smooth'})
                                        }} className={'profile-header-links'} to={'#'}>Reset Password</Link></Col>
                                        {!ifPerf ? <Col lg={2} md={2} sm={12} xs={12}><Link onClick={(e) => {
                                            e.preventDefault();
                                            this.artistSerivces.current.scrollIntoView({behavior: 'smooth'})
                                        }} className={'profile-header-links'} to={'#'}>Artist
                                            Services</Link></Col> : null}
                                    </Row>

                                </Container>
                            </div>
                            <Container ref={this.myProfile} style={{padding:'50px 15px'}}>
                                <Form onSubmit={this.updateUserInfo}>
                                    <h2 className={'my-prof'}>My Profile</h2>
                                    <Row className={'my-prof-row'}>
                                        <Col lg={ifPerf ? 6 : 12} className={'no-padding-right'}>
                                            <Row>
                                                <Col style={{paddingRight: ifPerf ? '15px' : 0}} lg={ifPerf ? 6 : 9}
                                                     md={ifPerf ? 6 : 9} sm={12} xs={12}>
                                                    <Row>
                                                        <Col style={{paddingRight: ifPerf ? 0 : '15px'}}
                                                             lg={ifPerf ? 12 : 4}
                                                             md={ifPerf ? 12 : 4} sm={12} xs={12}>
                                                            <Form.Group className={'profile-input-labels'}
                                                                        controlId="firstName">
                                                                <Form.Label>First Name*</Form.Label>

                                                                <Form.Control value={this.state.userData.firstName}
                                                                              onChange={this.handleChange}
                                                                              name={'firstName'}
                                                                              />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{paddingRight: ifPerf ? 0 : '15px'}}
                                                             lg={ifPerf ? 12 : 4}
                                                             md={ifPerf ? 12 : 4} sm={12} xs={12}>
                                                            <Form.Group className={'profile-input-labels'}
                                                                        controlId="lastName">
                                                                <Form.Label>Last Name*</Form.Label>
                                                                <Form.Control value={this.state.userData.lastName}
                                                                              onChange={this.handleChange}
                                                                              name={'lastName'}
                                                                              />
                                                            </Form.Group>
                                                        </Col>
                                                        {ifPerf ?
                                                            <Col style={{paddingRight: ifPerf ? 0 : '15px'}} lg={12}
                                                                 md={12} sm={12}
                                                                 xs={12}>
                                                                <Form.Group className={'profile-input-labels'}
                                                                            controlId="vendorName">
                                                                    <Form.Label>Publisher / Songwriter
                                                                        Name*</Form.Label>
                                                                    <Form.Control value={this.state.userData.vendorName}
                                                                                  onChange={this.handleChange}
                                                                                  name={'vendorName'}
                                                                                  />
                                                                </Form.Group>
                                                            </Col> :
                                                            <Col lg={ifPerf ? 12 : 4} md={ifPerf ? 12 : 4} sm={12}
                                                                 xs={12}>
                                                                <Form.Group className={'profile-input-labels'}
                                                                            controlId="artistName">
                                                                    <Form.Label>Artist Name*</Form.Label>
                                                                    <Form.Control value={this.state.userData.artistName}
                                                                                  onChange={this.handleChange}
                                                                                  name={'artistName'}
                                                                                  />
                                                                </Form.Group>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                {ifPerf ?
                                                    <Col lg={6} md={6} sm={12} xs={12}>

                                                        <Form.File style={{display: 'none'}} id="Profile-pic">
                                                            <Form.Control type={'file'}
                                                                          onChange={(e) => {
                                                                              if (e.target.files[0] && !e.target.files[0].name.match(/\.(jpg|jpeg)$/)) {
                                                                                  this.setState({
                                                                                      errors: {
                                                                                          ...this.state.errors,
                                                                                          imgUploadError: 'Please Select only jpg,jpeg file',
                                                                                      }
                                                                                  })
                                                                              } else if ( e.target.files[0] && e.target.files[0]) {
                                                                                  this.crop(window.URL.createObjectURL(e.target.files[0]), 1).then(canvas => {
                                                                                      let dataURl = canvas.toDataURL()
                                                                                      this.setState({
                                                                                          errors: {
                                                                                              ...this.state.errors,
                                                                                              imgUploadError: null
                                                                                          },
                                                                                          uploadImg: dataURl,
                                                                                      })
                                                                                  })


                                                                              }
                                                                          }}/>
                                                            <Form.File.Input/>
                                                        </Form.File>

                                                        <span className={'upload-pic-container'}>
                                            <span className={'upload-button-container'}>
                                                {this.state.loading ?
                                                    <Spinner animation="border"/> : this.state.uploadImg.length ? <div>
                                                            <Button style={{
                                                                marginLeft: '-50px',
                                                                backgroundColor: '#fff',
                                                                color: '#b9c136'
                                                            }} onClick={this.vendorUploadHandler}
                                                                    className={'centered-upload-button'}
                                                                    variant="info"><FontAwesomeIcon
                                                                icon={faCheck}/></Button>
                                                            <Button style={{
                                                                marginLeft: '11px',
                                                                backgroundColor: '#fff',
                                                                color: 'red'
                                                            }} onClick={() => {
                                                                this.setState({
                                                                    uploadImg: false,
                                                                })
                                                            }} className={'centered-upload-button'}
                                                                    variant="info"><FontAwesomeIcon
                                                                icon={faTrashAlt}/></Button></div> :
                                                        <div className={'centered-upload-button-container'}><p style={{textAlign:'center'}}>Upload
                                                            300x300 Jpg File</p>
                                                            <p className={'error-message'}>{this.state.errors.imgUploadError}</p>
                                                            <Button
                                                                onClick={() => document.getElementById('Profile-pic').click()}
                                                                variant="info"><FontAwesomeIcon
                                                                icon={faUpload}/></Button>
                                                        </div>}
                                            </span>
                                            <img alt=""
                                                 src={this.state.uploadImg ? this.state.uploadImg : this.props.state.auth.user.vendorImageLink ? this.state.userData.vendorImageLink+'?'+performance.now() : ProfilePlaceholder}
                                                 id={'previewimg'}
                                                 className={'my-profile-image'}/>
                                        </span>
                                                    </Col> : null}
                                                <Col lg={ifPerf ? 12 : 3} md={ifPerf ? 12 : 3} sm={12} xs={12}>
                                                    <Form.Group className={'profile-input-labels'} controlId="emsail">
                                                        <Form.Label>Email*</Form.Label>
                                                        <Form.Control value={this.state.userData.email}
                                                                      onChange={this.handleChange}
                                                                      name={'email'}
                                                                      readOnly/>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={ifPerf ? 6 : 3} md={6} sm={6} xs={12}>
                                                    <Form.Group className={'profile-input-labels'} controlId="city">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control value={this.state.userData[cityKey]}
                                                                      onChange={this.handleChange}
                                                                      name={cityKey} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={ifPerf ? 6 : 3} md={6} sm={6} xs={12}>
                                                    <Form.Group className={'profile-input-labels'} controlId="country">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control value={this.state.userData[countryKey]}
                                                                      onChange={this.handleChange} name={countryKey}
                                                                      />
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={ifPerf ? 12 : 6} md={12} sm={12} xs={12}>
                                                    <InputGroup style={!ifPerf ?{marginTop: '27px'}:{margin:'20px 0'}}>
                                                        <InputGroup.Append style={{height:'39px',width: '100%'}}>
                                                            <button  className={'profile-update-btn'} type={'submit'}
                                                                    disabled={this.state.loading}>{this.state.loading ?
                                                                <Spinner
                                                                    animation="border"/> : 'UPDATE ACCOUNT INFO'}</button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {ifPerf ?
                                            <Col lg={6}>
                                                <Editor
                                                    value={this.state.userData.vendorBio && this.state.userData.vendorBio !== '' ? this.state.userData.vendorBio : null}
                                                    onEditorChange={(value) => {
                                                        this.setState({
                                                            userData: {
                                                                ...this.state.userData,
                                                                vendorBio: value
                                                            }
                                                        })
                                                    }}
                                                    apiKey='nw78l97emf6bnhtjf87qpiuh5ep10cq34kgmuccmjvzqs524'
                                                    name={'lyrics'}
                                                    init={{
                                                        height: 480,
                                                        menubar: false,
                                                        placeholder: "Bio",
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                    }}
                                                />
                                            </Col> : null}
                                    </Row>

                                </Form>
                            </Container>

                            {!ifPerf ?<div ref={this.licensedSongs}> <ProfileLicensed/></div> : null}
                            {!ifPerf ?
                                <Container ref={this.myFavorites} className={'profile-sec prof-section-container favorites-container'}>
                                    <h2 className={'my-prof'}>My Favorites</h2>
                                  <ProfileFavorites header={this.header}/>
                                </Container> : null}
                            {ifPerf ?
                                <div ref={this.mySongs}>
                                <Suspense fallback={<Preloader/>}>
                                    <SongUploadModal />
                                    <div ref={this.mySales}>
                                        <MySales loading={this.state.loading} saleData={this.state.user_sales}/>
                                    </div>
                                </Suspense></div> : null}
                            <section ref={this.resetPass} className={'profile-sec-section password-reset-section'}>
                                <ResetPassword userID={this.props.state.auth.user_id}
                                               token={this.props.state.auth.access_token}/>
                            </section>
                            {!ifPerf ?

                                <section ref={this.artistSerivces} className={'profile-services'}>
                                    <Modal
                                        show={this.state.learnMore}
                                        onHide={()=>this.setState({learnMore:false})}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                Bandsintown
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ul>
                                                <li>
                                                    sign up on Bandsintown for Artists by <a className={'rs-link'} rel="noopener noreferrer" href={'https://tracking.cirrusinsight.com/ea9fd50d-decb-4536-baaf-92f4f0ce08ed/m-bnds-us-az1gmr'} target={'_blank'}> clicking here</a>
                                                </li>
                                                <li>
                                                    go to Campaigns » Create Campaign to set up your first email marketing campaign and target music lovers by location and artist preference
                                                </li>
                                                <li>
                                                    at the Order step, benefit from a $50 discount by using the promo code ROCKS54691
                                                </li>
                                                <li>
                                                    contact <a className={'rs-link'} href={'mailto:artistsupport@bandsintown.com'}>artistsupport@bandsintown.com</a>   if you need assistance
                                                </li>
                                            </ul>
                                            <div className={'text-center'}>
                                                <a className={'rs-link h3 mt-1 font-weight-bold'} rel="noopener noreferrer" href={'https://tracking.cirrusinsight.com/ea9fd50d-decb-4536-baaf-92f4f0ce08ed/m-bnds-us-az1gmr'} target={'_blank'}>Continue on to BandsInTown</a>
                                            </div>

                                        </Modal.Body>
                                    </Modal>
                                    <Container>
                                        <Row>
                                            <Col lg={12}><h2 className={'my-prof'}>Artist Services</h2></Col>
                                            <Col lg={12}> <span className={'my-prof-subtitle'}> Choose the artist support service that will help your music career.</span></Col>
                                            <Col lg={4} style={{marginTop:15}}>
                                                <Row className={'prof-sec-padding profile-sec-section justify-content-center'}>
                                                    <Col className={'align-self-baseline text-center'} lg={12}><img alt="" src={bitImg}/></Col>
                                                    <Col lg={12} style={{minHeight:'220px',maxWidth:'85%'}}>
                                                        <p>By using Bands In town promoter tool, you can notify MILLIONS of Bandsintown trackers about your upcoming concerts, music releases, festival appearances, venue calendars, and more!</p>

                                                    </Col>
                                                    <Col className={'align-self-end text-center'} lg={12}><Button onClick={()=>this.setState({learnMore:true})} className={'find-button'}>Learn more</Button></Col>
                                                </Row>
                                            </Col>
                                            <Col lg={4} style={{marginTop:15}}>
                                                <Row className={'prof-sec-padding profile-sec-section justify-content-center'}>
                                                    <Col className={'align-self-baseline text-center'} lg={12}><img alt=""
                                                                                                     src={landrImg}/></Col>
                                                    <Col lg={12} style={{minHeight:'220px',maxWidth:'85%'}}><p>Landr masters your music in minutes giving you a
                                                        higher-quality sound for
                                                        your recording. This instant mastering service perfects the sound of
                                                        your songs,
                                                        giving
                                                        them a more professional quality.</p></Col>
                                                    <Col className={'align-self-end text-center'} lg={12}><Button onClick={()=>window.open('https://join.landr.com/rocketsongs/', '_blank')} className={'find-button'}>Master your
                                                        songs</Button></Col>
                                                </Row>
                                            </Col>
                                            <Col lg={4} style={{marginTop:15}}>
                                                <Row className={'prof-sec-padding profile-sec-section justify-content-center'}>
                                                    <Col className={'align-self-baseline text-center'} lg={12}><img alt=""
                                                                                                     src={chordImg}/></Col>
                                                    <Col lg={12} style={{minHeight:'220px',maxWidth:'85%'}}><p>
                                                        Chordify provides the chords to any Rocket Songs track, and more. Play
                                                        along with
                                                        your
                                                        instrument using Chordify's intuitive playback.</p></Col>
                                                    <Col className={'align-self-end text-center'} lg={12}><Button onClick={()=>window.open('https://chordify.net/channels/rocket-songs', '_blank')} className={'find-button'}>Get
                                                        chords</Button></Col>
                                                </Row>
                                            </Col>
                                            <Col lg={{span:4,offset:4}} style={{marginTop:15}}>
                                                <Row className={'prof-sec-padding profile-sec-section justify-content-center'}>
                                                    <Col className={'align-self-baseline text-center'} lg={12}><img alt="" src={interceptImg}/></Col>
                                                    <Col lg={12} style={{minHeight:'220px',maxWidth:'85%'}}><p>In today's music world you need to get your music out there
                                                        and make some
                                                        noise.
                                                        You need InterceptMusic, our distribution and marketing partner.
                                                        Specializing in
                                                        independent artists,
                                                        you’ll get expert consulting, plus they’ll get your music out to 160
                                                        countries and
                                                        promote it globally
                                                        in social media, all in one stop</p></Col>
                                                    <Col className={'align-self-end text-center'} lg={12}><Button onClick={()=>window.open('https://go.interceptmusic.com/rocketsongs/', '_blank')} className={'find-button'}>Distribute
                                                        and
                                                        market</Button></Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </Container>
                                </section> : null}
                        </div>}
                <Footer/>
            </div>
        )
    }
}

export default connect(state => ({
        state
    }),
    dispatch => ({
        onUpdateProfile: (userData) => {
            dispatch({
                type: "UPDATE_PROFILE",
                payload: userData
            })
        },
        onAddToFavs: (music) => {
            dispatch({
                type: "ADD_TO_FAVS",
                payload: music
            })
        },

        onRemoveFromFavs: (music) => {
            dispatch({
                type: "REMOVE_FROM_FAVS",
                payload: music
            })
        },

        onLogout: () => {
            dispatch({
                type: "LOGOUT"
            })
        },
    }))(withRouter(Profile));
