import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import logo from "../../images/logo.png"
import '../../styles/sass/footer.sass'
import {Link} from "react-router-dom";
import moment from "moment";
class Footer extends Component {
    render() {
        return (
            <>
            <footer>
                <Container className={'footer-container'}>
                    <Row>
                        <Col lg={3} sm={12} className="logo-container">
                            <div>
                                <img src={logo} alt={"rocketsongs"}/>
                            </div>
                        </Col>
                        <Col lg={3} sm={12} className="menu-column">
                            <span className={'footer-menu-header'}>
                                Rocket Songs
                            </span>
                            <p>
                                <Link to="/BecomeAContentPartner">Got Songs? Become A Content Partner</Link>
                            </p>
                            <p>
                                <Link to="/Team">Our Team</Link>
                            </p>
                        </Col>
                        <Col  lg={3} sm={12} className="menu-column">
                           <span className={'footer-menu-header'}>
                                Legal
                           </span>
                            <p>
                                <Link to="/LicenseAgreement">Rocket Songs License Agreement</Link>
                            </p>
                            <p>
                                <Link to="/Legal">Terms of Service</Link>
                            </p>
                        </Col>
                        <Col lg={3} sm={12} className="menu-column">
                           <span className={'footer-menu-header'}>
                                Support
                           </span>
                            <p>
                                <Link to="/FAQ" >FAQ</Link>
                            </p>
                            <p>
                                <Link to="/ContactUs">Contact Us</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>


            </footer>
            <section className={'footer-bottom'}>
                {moment().format('YYYY')} Rocket Songs Inc. All Rights Reserved
            </section>
                </>

        )
    }
}

export default Footer;
