
import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Header from "../Fragments/Header";
import Footer from "../Fragments/Footer";
import JohnImg from "../../images/john-retro.jpg"
import JohnnathanImg from "../../images/jonathan-retro.jpg"
import EzraImg from "../../images/ezra-retro.png"
import MartinImg from "../../images/martin-retro.jpg"

class Team extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Team - Rocket Songs"
    }

    render() {
        return (
            <div className={"our-team-page"}>
                <Header title={"Team"}/>
            <section className={"team-container"}>
                <hr style={{top:'-56px'}} className={'white-wave'}/>
                <Container >
                    <Row>
                        <Col lg={12} md={12}>

                            <p>
                                At Rocket Songs, we live and breathe new music all day every day. Not just any new music
                                – but great new original songs from some of the best songwriters, producers, and
                                publishers out there. Songs that when we hear them, we immediately say “that could be a
                                hit”.
                            </p>
                            <p>
                                Meet the team that’s dedicated to bringing these songs to you.
                            </p>

                            <img alt=""className="img-responsive team-profile-img"
                                 src={JohnnathanImg}/>
                                <div className={"center-name"}>
                                    <h2 className="highlight">
                                        Jonathan Stone
                                    </h2>
                                    <h3 className="highlight font-18">
                                        Co-Founder / President &amp; Creative Director
                                    </h3>
                                </div>
                                <p>
                                    Jonathan is a highly regarded music-publishing executive, and the chief creative
                                    curator of all the songs you hear on Rocket Songs – he’s got the “ears”.
                                </p>
                                <p>
                                    Jonathan began his career at ATV Music, becoming the Manager of Creative Services
                                    for the Nashville office, before moving on to become Director of Creative Services
                                    for MCA Music, working with such famed songwriters as Glen Ballard, Robbie Nevil,
                                    Mark Mueller, and Brock Walsh.
                                </p>
                                <p>
                                    Jonathan's success at MCA caught the eye of the legendary Quincy Jones (yeah that
                                    guy), who made Jonathan Vice President of Qwest Music Publishing/Quincy Jones
                                    Productions, overseeing all aspects of music publishing for Quincy, including
                                    A&amp;R for various productions including Michael Jackson, Patty Austin and James
                                    Ingram.
                                </p>
                                <p>
                                    Jonathan went on to run Windswept Pacific Music Publishing, founded by legendary
                                    music publishers Chuck Kaye and Joel Sill, in partnership with Fujipacific Music of
                                    Japan, the largest music publisher in Asia.
                                </p>
                                <p>
                                    As GM and then President of Windswept, Jonathan guided the company to become one of
                                    the largest independent music publishers in the world, publishing the works of Bruno
                                    Mars, Kings of Leon, Beyonce, The Spice Girls, Corinne Bailey Rae, Snow Patrol, Pete
                                    Townsend, Alice Cooper, Chris Farren, Mike Elizondo, and way to many more to name.
                                </p>
                                <p>
                                    Windswept was sold to Bug Music for $175 Million, and Jonathan again joined forces
                                    with Fujipacific to form Radar Music, finding, signing and developing new writing
                                    talent in all genres of music. Radar struck gold with the signing of
                                    songwriter/producer Michael 'Omega' Fonseca, who co-wrote “Centuries” for Fall Out
                                    Boy.
                                </p>

                                <p>
                                    Jonathan has served on the board of various organizations, including the Board of
                                    Directors for the Academy of Country Music.
                                </p>

                                <img alt=""className="img-responsive team-profile-img"
                                     src={JohnImg}/>
                                    <div className={"center-name"}>
                                        <h2 className="highlight">
                                            John Cesario
                                        </h2>
                                        <h3 className="highlight font-18">
                                            Co-Founder / CEO
                                        </h3>
                                    </div>
                                    <p>
                                        Starting with his early career as a songwriter when he would cold call music
                                        publishers and then show up at their offices and play his songs, he became a
                                        staff songwriter at Arista Music Publishing and Windswept Pacific Music
                                        Publishing, working with hit artists such as Fleetwood Mac, Eddie Money, Dave
                                        Mason, and Eric Martin.
                                    </p>
                                    <p>
                                        After a few hits, a platinum record, and a couple of awards, he began to pursue
                                        the business side of the creative world, and over the last 20 years, he’s worked
                                        in executive management and strategic business development roles with various
                                        media and Internet companies. John’s business expertise in multi-platform media
                                        development &amp; delivery in the music and entertainment industries has played
                                        a critical role in developing and guiding Rocket Songs.
                                    </p>
                                    <p>
                                        Before co-founding Rocket Songs, he co-founded and launched: Noise Pop
                                        Industries, an event and lifestyle company supporting independent music and
                                        artists; GoodStorm, an e-commerce technology company, sold to Zazzle (a Google
                                        company) in 2007; and Mission AdWorks, a social advertising marketplace that
                                        connects logical groupings of website publishers with marketers, now part of
                                        Broad Street Interactive.
                                    </p>
                                    <p>
                                        Prior, John was: General Manager of Skyline Public Works, a company that
                                        provided funding and incubation for social venture enterprises; Executive
                                        Manager of Production for Internet broadcasting pioneer iBeam Broadcasting; and
                                        Vice President of EDR Media, a company that developed programming for delivery
                                        via traditional and Internet broadcasting.
                                    </p>

                                    <img alt=""className="img-responsive team-profile-img"
                                         src={EzraImg}/>
                                        <div className={"center-name"}>
                                            <h2 className="highlight">
                                                Ezra Greene
                                            </h2>
                                            <h3 className="highlight font-18">
                                                Chief Marketing Officer
                                            </h3>
                                        </div>
                                        <p>
                                            Award-winning and patent-owning Ezra Greene has managed and launched some of
                                            the biggest brands and marketing campaigns in the digital industry during
                                            his pretty good career.
                                        </p>
                                        <p>
                                            Most recently, Ezra built and grew the highly regarded, Home Brew Agency,
                                            specializing in social and digital marketing, targeted advertising and
                                            strategy. At Home Brew, Ezra may or may not have been called, "a fountain of
                                            good ideas," "king of the RFP" and/or "the greatest mind in a sea of great
                                            minds." We're still seeking confirmation on at least one of those.
                                        </p>
                                        <p>
                                            Prior to Home Brew, Ezra headed Content for Spike TV. In a nutshell, what he
                                            did was take a UGC site and transform it into a digital content destination
                                            site full of original written and digital content, including James Gunn's PG
                                            Porn and the enduring Food Dude series.
                                        </p>
                                        <p>
                                            He joined Spike following a memorable year digitally marketing movies for
                                            Fox's specialty label, Fox Atomic Films. There he not only was part of a
                                            team which tried to fundamentally change the way movies were marketed, but
                                            also oversaw ground-breaking campaigns for "The Hills Have Eyes II" and for
                                            "28 Weeks Later". The latter being a much better movie, in Ezra's opinion.
                                        </p>
                                        <p>
                                            Before that, Ezra spent eight years at Warner Bros., where he oversaw a few
                                            relatively well-known brands: Scooby-Doo, Batman and a small
                                            literary-cum-theatrical one about a young orphaned Wizard which (or witch)
                                            also rhymes with Larry Botter. For that IP, it was launched on a global
                                            level and quickly became the 2nd biggest aggregated online entertainment
                                            community.
                                        </p>
                                        <p>
                                            Ezra's history also includes re-launching Variety's website from a
                                            dysfunctional and awful purple one to one that actually represented the
                                            trade magazine's brand and quality.
                                        </p>
                                        <p>
                                            In addition to the above, Ezra has been a part of two (now three!)
                                            start-ups. One of them was successful, the other not.
                                        </p>
                                        <p>
                                            Ezra likes film and architecture, and thrives and wanes with the successes
                                            of the Boston Red Sox, Philadelphia Flyers and Philadelphia Eagles. He reads
                                            comic books, has a dog named Arthur and appreciates fine wine and cuisine.
                                        </p>

                                        <img alt="" className="img-responsive team-profile-img"
                                             src={MartinImg}/>
                                            <div className={"center-name"}>
                                                <h2 className="highlight">
                                                    Martín Coll
                                                </h2>
                                                <h3 className="highlight font-18">
                                                    Technical Advisor
                                                </h3>
                                            </div>
                                            <p>
                                                Despite being a die hard nerd who spends most of his days trying to
                                                learn that fancy new technology that just came out, Martín displays a
                                                broad business perspective that puts him in the right spot for the
                                                start-up world.
                                            </p>
                                            <p>
                                                In addition to working at Rocket Songs, Martin is pursuing his Master's
                                                Degree in Computer Engineering, and is also a teaching assistant on
                                                Algorithms and Data Structures. He greatly enjoys teaching and is
                                                currently mentoring high school kids to become developers as part of a
                                                government program.
                                            </p>
                                            <p>
                                                Martín has previously worked as an intern at Microsoft (three years in a
                                                row!), and has been a part of different companies and start-ups both in
                                                Argentina and the United States.
                                            </p>
                                            <p>
                                                Martín loves traveling, photography, meeting new people at tech events
                                                and meet-ups, and spending more than he should going out for dinner.
                                            </p>

                        </Col>
                    </Row>
                </Container>
            </section>
                    <Footer/>
            </div>
        )
    }
}

export default Team;
