import React, {Component} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import api from "../../../../api";
import axios from "axios";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state={
            userData:{},
            pass:{},
            passMess:{},
            loading:false,
        }
    }
    handleChangePass =(e)=>{
        this.setState({pass:{
                ...this.state.pass,
                [e.target.name]:e.target.value,
            }})
    }
    updatePass=event=>{
        this.setState({
            loading:true,
        })
        const form = event.currentTarget;
        event.preventDefault();
        const data = JSON.stringify({
            UserID:this.props.userID,
            CurrentPassword:this.state.pass.CurrentPassword,
            NewPassword:{
                Password:this.state.pass.Password,
                PasswordConfirm:this.state.pass.PasswordConfirm,
            }
        });

        if (form.checkValidity() === true && this.state.pass.Password === this.state.pass.PasswordConfirm &&this.state.pass.Password.length>=4) {
            axios.request( {
                url:`${api.User.Set.url}${this.props.userID}/password?`,
                method: api.User.Set.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.token
                },
                data: data
            }).then(response => {
                if (response.status===200) {
                    this.setState({passMess: {status: true, message: 'Your password has been changed'},loading:false})
                }
            }).catch(error=>{
                this.setState({passMess: {status: false, message: 'Your current password is wrong'},loading:false})
            })

        }else{
            this.setState({passMess: {status: false, message: 'Your password should be at least 4 characters long!'},loading:false})
        }
    }
    render() {
        return (
            <Container ref={this.resetPass} className={'prof-section-container'}>
                <h2 className={'my-prof'}>Reset Password</h2>
                <Form onSubmit={this.updatePass}>
                    <Row className={'my-prof-row'}>
                        <Col lg={4} md={12} sm={12} xs={12}>
                            <Form.Group className={'profile-input-labels'}  controlId="CurrentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type={'password'} value={this.state.pass.CurrentPassword} onChange={this.handleChangePass} name={'CurrentPassword'}  required />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group className={'profile-input-labels'}  controlId="email">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type={'password'} value={this.state.pass.Password} onChange={this.handleChangePass} name={'Password'}  required />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12}>
                            <Form.Group className={'profile-input-labels'}  controlId="PasswordConfirm">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type={'password'} value={this.state.pass.PasswordConfirm} onChange={this.handleChangePass} name={'PasswordConfirm'}  required />
                            </Form.Group>
                        </Col>
                        <Col style={{opacity:this.state.passMess.message?1:0,color:this.state.passMess.status?'green':'red'}} lg={8} md={12} sm={12} xs={12}>
                            <p>{this.state.passMess.message}</p>
                        </Col>
                        <Col lg={4} md={12} sm={12} xs={12}>
                            <button type="submit" className={'profile-update-btn'} disabled={this.state.pass.Password !== this.state.pass.PasswordConfirm||this.state.loading} >{this.state.loading?<Spinner animation="border" />:'UPDATE PASSWORD'}</button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }
}

export default ResetPassword;
