export default function auth(state = {}, action){
    if(action.type === 'LOGIN'){
        return{
            ...state,
            access_token: 'Bearer ' + action.payload.response.results.access_token,
            user_id: action.payload.response.results.user_id,
            vendor_id: action.payload.response.results.vendor_id,
            user:{
                ...action.payload.resp,
            }
        }
    }
    if(action.type === 'UPDATE_PROFILE'){
        return{
            ...state,
            user:{
                ...state.user,
                ...action.payload,
            }
        }
    }

    if(action.type === 'LOGOUT'){
        return {}
    }

    return state;
}
