import React from 'react';
import ReactDOM from 'react-dom';
import './styles/sass/index.sass';
import * as serviceWorker from './serviceWorker';
import Routes from "./Routes";
import configureStore from './store/configureStore'
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import axios from "axios";

const {persistor, store} = configureStore();
axios.interceptors.response.use(
    response => response,
    error => {
        let {status,data} = error.response;
        let message = data?.Message;
        if(status===401){
            store.dispatch({
                type:'LOGOUT'
            })
            setTimeout(()=>{
                window.location.href = '/';
            },300)

        }else if(status===500) {
            window.location.href = `/Error${message?'?message='+message:''}`
        }else{
            return Promise.reject(error);
        }
    }
)
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
                <Routes/>
          </PersistGate>
      </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
