import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "../Fragments/Footer";
import Header from "../Fragments/Header";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

class Legal extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Rocket Songs Music Licensing Site - Terms Of Service"
    }
    render() {
        return (
            <div className={"legal-page"}>
                <MetaTags>
                    <title>Terms of Service</title>
                    <meta name="description" content="Rocket songs is the best Music licensing organization in the USA. Rocket songs provide a license to post, share, perform, record, sell, distribute and remix song." />
                </MetaTags>
                <Header title={"Terms of Service"}/>
                <section className="licenses-content legal-content">
                    <hr style={{top:'-122px'}} className={'white-wave'}/>
                    <Container>
                        <Row>
                            <Col lg={12} md={12}>
                                <p><strong>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE ("Agreement") CAREFULLY
                                    BEFORE USING THIS WEBSITE</strong></p>

                                <p>All users of this site agree that access to and use of this site are subject to the
                                    following legally binding terms and conditions and other applicable law.
                                    Whether you are merely a visitor, browsing through this website or a registered
                                    member
                                    who is enjoying all the features of this website, you agree to be bound
                                    by all the terms and conditions of this Agreement. If you do not agree to these
                                    terms
                                    and conditions, please do not use this site.</p>

                                <h3 className="title">Welcome to RocketSongs.com</h3>

                                <p>RocketSongs.com ("Rocket Songs") provides the http://RocketSongs.com website ("Site")
                                    for
                                    its artists, members, friends and other permitted users. We want to
                                    enable your ability to use our Site and to get the most out of it, including
                                    listening
                                    to music, viewing videos, uploading content, exchanging ideas and interacting
                                    with other users and members. You understand and acknowledge that all of the
                                    functions
                                    specified below shall not be available to all users of the Site
                                    (e.g.: "listeners" are currently unable to upload content).</p>

                                <p>We've worked hard to help give you the information here and elsewhere on our Site so
                                    that
                                    you may be able to fully experience the Site. Everyone
                                    (that includes us) hates reading through these Terms and Conditions of use, but our
                                    very
                                    expensive lawyers insist on it. So bear with us and please
                                    take some time to read through these Terms and Conditions. We hope you understand
                                    that
                                    these and our other policies and activities help us to provide
                                    you with the best online audio-visual community experience that we can conceive.</p>

                                <h3 className="title">Copyright and Content Ownership</h3>

                                <p>The entire content included in this Site, including but not limited to text, graphics
                                    or
                                    code is copyrighted as a collective work under the United States
                                    and other copyright laws, and is the property of Radar Management Group, LLC. DBA
                                    Rocket
                                    Songs, Copyright 2015 ALL RIGHTS RESERVED. </p>

                                <p>Rocket Songs wants to you to use this Site to it's fullest potential however because
                                    we
                                    have invested a lot of time, money and energy into making this
                                    Site fully functional, you will need to understand and agree to a few
                                    considerations:</p>

                                <br/>


                                <ol>
                                    <li>The Site may include works that are licensed to Rocket Songs. You may display
                                        and, subject to any expressly stated restrictions or limitations
                                        relating to specific material, download or print portions of the material from
                                        the different areas of the Site solely for your own non-commercial
                                        use, or, where applicable, to place an order with Rocket Songs. You further
                                        agree not to change or delete any proprietary notices from materials
                                        downloaded from the Site.
                                    </li>
                                    <br/>

                                    <li>Though we own the text, graphics, trademarks of the Site, we don't claim to
                                        own any content that you upload onto the Site. On the other hand, as
                                        soon as you upload any content onto the Rocket Songs Site, you represent and
                                        warrant that you either (i) own the content that you are uploading
                                        or (ii) you have the right or license to upload the content. You also
                                        warrant and represent that by uploading any content onto the Site, you are
                                        not
                                        violating the privacy, publicity, copyrights, intellectual property, or any
                                        other rights of any person or entity. Remember: you are solely responsible
                                        for the content that you upload onto the Site.
                                    </li>
                                    <br/>
                                    <li>The moment you upload content onto the Site, you automatically agree to
                                        grant Rocket Songs the limited right, license and permission to
                                        reproduce, stream, and publicly perform that content on and through the
                                        Site or via any of Rocket Songs third party licensees and affiliates.
                                        You
                                        also grant us the right to modify any and all content that you upload
                                        onto the Site so that we may satisfy the technical requirements (such as
                                        digitally compressing music files) of the Site. Remember: unless
                                        otherwise specified, Rocket Songs will not be required to pay you
                                        anything for this
                                        limited use of the uploaded content.
                                    </li>
                                    <br/>
                                    <li>Any other use, including but not limited to the modification,
                                        reproduction, distribution, display or transmission of the text,
                                        graphics, code of this S
                                        ite or its content is strictly prohibited, unless authorized by
                                        Rocket Songs or the owner of such content.
                                    </li>
                                </ol>


                                <h3 className="title">Removal of Content</h3>
                                <p>Rocket Songs reserves the right to remove, delete, or disable access to any content
                                    uploaded onto the Site or any of the features of this Site at it's own
                                    discretion without any notice. You understand that RocketSongs will not be liable
                                    for removing content or Materials, or disabling access to any content
                                    or any features of this Site.</p>

                                <h3 className="title">Copyright Infringement Claims</h3>

                                <p>Rocket Songs believes in the importance and sanctity of your and everyone else's
                                    intellectual property rights. Therefore Rocket Songs will, in it's
                                    sole discretion, take whatever steps it feels necessary to protect the intellectual
                                    property rights of others, including removing or blocking specific
                                    content on the Site. </p>
                                <br/>
                                <p>If you believe that your content has been improperly uploaded on the site, or
                                    that any specific content on the site violates yours or anyone else's
                                    intellectual property rights, then contact us via email at: <Link
                                        to="mailto:copyright@rocketsongs.com">copyright@rocketsongs.com</Link></p>
                                <br/>
                                <p>And include the following information:</p>
                                <br/>
                                <ol>
                                    <li>Description/name of the infringing content.</li>
                                    <br/>
                                    <li>Description of the type of infringement.</li>
                                    <br/>
                                    <li>Your Name, Address, email address, phone number and a
                                        description (with proof) of your ownership of the content
                                        and/or your right to act on
                                        behalf of the true owner of the content.
                                    </li>
                                    <br/>
                                    <li>Any additional information that will help us assess
                                        whether in fact the content in question is a violation
                                        of any third party's intellectual property
                                        rights.
                                    </li>
                                    <br/>
                                    <li>A statement by you made under penalty of perjury,
                                        that the above information in your notice is true
                                        and that you are the copyright owner or are
                                        authorized to act on behalf of the copyright owner's
                                        behalf.
                                    </li>

                                </ol>

                                <h3 className="title">Trademarks</h3>
                                <p>All trademarks, service marks, logos and trade names of Rocket Songs used
                                    in the Site or on any other affiliated site are trademarks or registered
                                    trademarks of Radar Management Group, LLC or third parties who have
                                    authorized the use of such marks on our Site.</p>

                                <h3 className="title">Age Limitation</h3>
                                <p>In order to use this Site you must be at least 14 years old and to become
                                    a member you must provide information such as a username, password and
                                    e-mail
                                    address. Because all information disclosed through our Site could become
                                    public information, you should exercise caution when sharing any of your
                                    personal information. You are solely responsible for and all use of your
                                    account. By the way, a word of advice: you should be careful with
                                    sharing
                                    private information ALL THE TIME on ANY WEBSITE, not just with Rocket
                                    Songs.</p>

                                <h3 className="title">Use of Site</h3>

                                <p>Rocket Songs wants you to enjoy the many functions and services on this
                                    Site such as uploading content, and interacting or sharing information
                                    with
                                    the other members. However, there are certain rules that you must abide
                                    by to ensure that everyone has the same positive experience from using
                                    this Site:</p>

                                <br/>
                                <ol>
                                    <li>Harassment in any manner or form on the Site, including via
                                        e-mail, chat, or by use of obscene or abusive language, is
                                        strictly forbidden.
                                        Impersonation of others, including other members or visitors on
                                        the Site, is prohibited.
                                    </li>
                                    <br/>
                                    <li>You may not upload to, distribute, or otherwise publish
                                        through the Site any content which is libelous, defamatory,
                                        obscene, threatening, invasive
                                        of privacy or publicity rights, abusive, illegal, or
                                        otherwise objectionable which may constitute or encourage a
                                        criminal offense, violate the rights
                                        of any party or which may otherwise give rise to liability
                                        or violate any law.
                                    </li>
                                    <br/>
                                    <li>The Rocket Songs site is for personal use only and may
                                        not be used for any commercial purposes except those
                                        that are specifically permitted by
                                        Rocket Songs. You may not upload commercial content (ie:
                                        NO ADVERTISING, SPAMMING, SPIMMING, PHISHING, BULK
                                        MAILINGS, JUNK
                                        MAIL OF ANY SORT - NO EXCEPTIONS) on the Site or use the
                                        Site to solicit others to join or become members of any
                                        other commercial online
                                        service or other organization.
                                    </li>
                                    <br/>
                                    <li>Though you can create a hyperlink to the Rocket
                                        Songs site from a third party webpage, the link must
                                        not portray Rocket Songs or its affiliates in
                                        an offense, derogatory, false or otherwise
                                        misleading manner.
                                    </li>
                                </ol>

                                <h3 className="title">Participation Disclaimer</h3>
                                <p>Rocket Songs does not assume any responsibility to review all
                                    communications and materials posted to or created by users accessing
                                    the Site, and are
                                    not in any manner responsible for the content of these
                                    communications and materials. You acknowledge that by providing you
                                    with the ability to view and
                                    distribute user-generated content on the Site, Rocket Songs is
                                    merely acting as a passive conduit for such distribution and is not
                                    undertaking any
                                    obligation or liability relating to any contents or activities on
                                    the Site. However, Rocket Songs reserves the right to block or
                                    remove communications
                                    or materials that it determines to be (a) abusive, defamatory, or
                                    obscene, (b) fraudulent, deceptive, or misleading, (c) in violation
                                    of a copyright,
                                    trademark or; other intellectual property right of another or (d)
                                    offensive or otherwise unacceptable to Rocket Songs in its sole
                                    discretion.</p>
                                <h3 className="title">Warranty Disclaimer</h3>
                                <p>This Site and the materials and other items on or available through
                                    this Site are provided "as is" and without warranties of any kind,
                                    whether express or
                                    implied. To the fullest extent permissible pursuant to applicable
                                    law, Rocket Songs disclaims all warranties, express or implied,
                                    including, but not limited to,
                                    implied warranties of merchantability and fitness for a particular
                                    purpose and non-infringement. Though we have spent a lot of time
                                    working on this Site,
                                    Rocket Songs does not represent or warrant that the functions
                                    contained in the Site will be uninterrupted or error-free, that the
                                    defects will be corrected,
                                    or that this Site or the server that makes the Site available are
                                    free of viruses or other harmful components (but chill: we are
                                    always working on bettering
                                    the Site and will continuously try to fix any defects on the Site).
                                    Rocket Songs does not make any warrantees or representations
                                    regarding the use of the
                                    materials in this Site in terms of their correctness, accuracy,
                                    adequacy, usefulness, timeliness, reliability or otherwise. Some
                                    states do not permit
                                    limitations or exclusions on warranties, so the above limitations
                                    may not apply to you.</p>

                                <h3 className="title">Limitation of Liability</h3>
                                <p>Rocket Songs shall not be liable for any special or consequential
                                    damages that result from the use of, or the inability to use, the
                                    materials on this Site
                                    or the performance of the products, even if Rocket Songs has been
                                    advised of the possibility of such damages. Rocket Songs is not
                                    responsible for any
                                    technical malfunctions of any network, computer hardware, operating
                                    system, software application, online system or any internet
                                    bandwidth complications,
                                    including any damage to any user's computer relating to or resulting
                                    from the participation, uploading or downloading of any materials or
                                    content in connection
                                    with the services provided by Rocket Songs. Applicable law may not
                                    allow the limitation of exclusion of liability or incidental or
                                    consequential damages,
                                    so the above limitation or exclusion may not apply to you.</p>

                                <h3 className="title">Term and Termination</h3>
                                <p>These terms and conditions are applicable to you upon your accessing
                                    the Site and/or completing the registration or shopping process..
                                    Rocket Songs will
                                    remove any content that it believes in its sole judgment, to violate
                                    this Agreement or any of its policies. The provisions relating to
                                    Copyrights, Trademark,
                                    Disclaimer, Limitation of Liability, Indemnification and
                                    Miscellaneous, shall survive any termination. Rocket Songs may
                                    terminate your membership
                                    and block your use of this Site for any reason, including but not
                                    limited to a violation of your promises, warranties, terms and
                                    conditions of this
                                    Agreement.</p>

                                <h3 className="title">Notice</h3>
                                <p>Rocket Songs may deliver notices to you relating to the use of this
                                    Site by means of e-mail, a general notice posted on our Site, or by
                                    other reliable
                                    method to the address you have provided to Rocket Songs. From time
                                    to time, Rocket Songs may modify the terms and conditions relating
                                    to your use of
                                    this Site. If Rocket Songs does so, it will notify you via email and
                                    you will automatically be bound by those changes to this
                                    Agreement.</p>

                                <h3 className="title">Indemnification</h3>
                                <p>You agree to indemnify, defend, and hold harmless Rocket Songs, its
                                    officers, directors, employees, agents, licensors and suppliers,
                                    from and against
                                    all losses, expenses, damages and costs, including reasonable
                                    attorneys' fees, resulting from any violation of these terms and
                                    conditions or any activity
                                    related to your account (including negligent or wrongful conduct) by
                                    you or any other person accessing the Site using your account.</p>

                                <h3 className="title">Third-Party Links</h3>
                                <p>In an attempt to provide an enhanced user experience and value to our
                                    members and other visitors, Rocket Songs may link to sites operated
                                    by third parties.
                                    However, even if the third party is affiliated with Rocket Songs,
                                    Rocket Songs has no control over these linked sites, all of which
                                    have separate privacy
                                    and data collection practices, independent of Rocket Songs. These
                                    linked sites are only for your convenience and therefore you access
                                    them at your own risk.
                                    Nonetheless, Rocket Songs seeks to protect the integrity of its web
                                    site and the links placed upon it and therefore requests any
                                    feedback on its own site as
                                    well as sites to which we link (including if a specific link does
                                    not work).</p>

                                <h3 className="title">Privacy Policy</h3>
                                <p>Your use of this Site is also subject to Rocket Songs' Privacy
                                    Policy, which you may review by <Link to="#"
                                        data-bind="click:showPrivacy"><strong> CLICKING
                                        HERE</strong></Link></p>

                                <h3 className="title">Miscellaneous</h3>
                                <p>Your use of this Site shall be governed in all respects by the laws
                                    of the State of Delaware U.S.A., without regard to choice of law
                                    provisions,
                                    and not by the U.N. Convention on Contracts for the International
                                    Sale of Goods (if otherwise applicable). You agree that jurisdiction
                                    over and
                                    venue in any legal proceeding directly or indirectly arising out of
                                    or relating to this Site shall be in the state or federal courts
                                    located in New Jersey.
                                    Any cause of action or claim you may have with respect to the Site
                                    must be commenced within one (1) year after the claim or cause of
                                    action arises.
                                    Rocket Songs' failure to insist upon or enforce strict performance
                                    of any provision of these terms and conditions shall not be
                                    construed as a waiver
                                    of any provision or right. Neither the course of conduct between the
                                    parties nor trade practice shall act to modify any of these terms
                                    and conditions.
                                    Rocket Songs may assign its rights and duties under this Agreement
                                    to any party at any time without notice to you. Either Rocket Songs
                                    or you may demand
                                    that any dispute between RocketSongs and you must be settled by
                                    arbitration utilizing the dispute resolute procedures of the
                                    American Arbitration
                                    Association in New York, NY USA, providing that the foregoing shall
                                    not prevent Rocket Songs from seeing injunctive relief in a court of
                                    competent jurisdiction.</p>

                                <br/>
                                <p>
                                    <strong>BY VISITING OR USING THE ROCKETSONGS SITE, YOU HEREBY
                                        WARRANT THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL
                                        OF THE PROVISIONS CONTAINED IN THIS AGREEMENT.</strong>

                                </p>


                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer/>
            </div>
        )
    }
}

export default Legal;
