import React, {Component} from "react";
import {Collapse, Badge, Col, Row, Spinner, Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import 'react-h5-audio-player/src/styles.scss'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons'
import {Link, withRouter} from "react-router-dom";
import api from "../../../api";
import {connect} from "react-redux";
import axios from "axios";
import './MusicTable.sass'

const masterlicense = {
    '295': 'baf94f4b-5baa-4ff8-8e51-dd07f26056ba',
    '495': '5e868377-865f-4f70-ae31-f6d51fa79d01',
    '695': '82d084b5-acb5-4ec9-a77b-318723350c1b',
    '895': '6cec9e1d-b740-441c-95e0-9906931dfd9e',
}
const exclusiveLicense = {
    '2ac1e267-27ea-4262-916c-b8ce6bc32637':{
        name:'3-Month Exclusive',
        price:60,
        isExclusive:1
    },
    '3f73a9e4-8954-4d8f-9376-8ce18779a9fd':{
        name:'6-Month Exclusive',
        price:100,
        isExclusive:1
    },
    'efa4e66a-b6f0-427b-adbf-fdab2fa53539':{
        name:'1-Year Exclusive',
        price:150,
        isExclusive:1
    },
}
class MusicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: 0,
            playMusic: {},
            songCollapse: {},
        };
        this.player = React.createRef();
    }
    addToCart = (song, license, licenseData) => {
        if(Object.keys(exclusiveLicense).includes(license)){
            licenseData = exclusiveLicense[license]
        }
        const cartData = {
            UserId: this.props.state.auth.user_id,
            items: [
                {
                    ProductId: song.songId,
                    LicenseId: license,
                },
            ]
        };
        if(!this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId).length && Object.keys(exclusiveLicense).includes(license)) {
          return   this.props.Toast({
                type:'primary',
                message:'At first, you should choose one of your products.'
            })
            }
        if (this.props.state.auth.user_id) {
            if(!this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId).length && Object.keys(exclusiveLicense).includes(license)) {
                this.props.Toast({
                    type:'primary',
                    message:'At first, you should choose one of your products.'
                })
            }else{
                if((this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId).length && !Object.keys(exclusiveLicense).includes(license))||(this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId && item.license.name.includes('Exclusive')).length && Object.keys(exclusiveLicense).includes(license))){
                    this.props.Toast({
                        type:'primary',
                        message:'You already have this product in your cart'
                    })
                }else{
                    axios.request({
                        url: api.Cart.Add.url,
                        method: api.Cart.Add.method,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': this.props.state.auth.access_token
                        },
                        data: JSON.stringify(cartData),
                    }).then(response => response.data).then(response => {
                        this.props.onAddtoCart(response.cartItems)
                        this.props.Toast({
                            type:'success',
                            message:'Successfully added to cart'
                        })
                    }).catch(err => {
                        this.props.Toast({
                            type:'error',
                            message:err.response.data.message?err.response.data.message:'Something went wrong'
                        })
                    })
                }
            }
        } else {
            const cartItem = {
                product: song,
                ProductId: song.songId,
                LicenseId: license,
                license: {
                    name: licenseData.name,
                    isExclusive: licenseData.isExclusive,
                    licenseId:license
                },
                price: parseInt(licenseData.price)
            }
            this.props.onAddtoCart(cartItem)
            this.props.Toast({
                type:'success',
                message:'Successfully added to cart'
            })
        }
    }
    addorRemoveFavorites = (music) => {
        if (this.props.state.auth.user_id) {
            const data = JSON.stringify({songId: music.songId})
            if (this.props.state.favorites.length && this.props.state.favorites.filter(song => song.songId === music.songId).length) {
                axios.request({
                    url: `${api.User.RemoveFavorite.url}${this.props.state.auth.user_id}/favorites/${music.songId}`,
                    method: api.User.RemoveFavorite.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.props.onRemoveFromFavs(music)
                    }
                })
            } else {
                axios.request({
                    url: `${api['User']['AddFavorite'].url}${this.props.state.auth.user_id}/favorites`,
                    method: api['User']['AddFavorite'].method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    },
                    data: data
                }).then(response => {
                    if (response.status === 200) {
                        this.props.onAddToFavs(music)
                    }
                })
            }
        } else {
            this.props.header.current.navOpen();
        }
    }

    render() {
        const ifPerf = this.props.state.auth.access_token && (this.props.state.auth.user.type === 'Provider' || this.props.state.auth.user.type === 'Applicant');
        return (
            <div className={'songs-table '+(!this.props.Containered?'container':'')}>
                {this.props.musicList.results && this.props.musicList.results.length ? this.props.musicList.results.map((music, index) =>
                    <div key={index} className={'song-col-row-container'}>
                        <Card className={'single-song-card'}>
                            <Card.Body>
                                <div className={'single-song-list'}>
                                    <div onClick={() => this.setState({
                                        songCollapse: {
                                            [index]: !this.state.songCollapse[index]
                                        }
                                    })}
                                         className={'carret-col ' + (this.state.songCollapse[index] ? 'collapse-open' : '')}>
                                        <FontAwesomeIcon icon={faCaretDown}/>
                                    </div>
                                    <div className={'play-button-col'} id={music.songId} onClick={(e) => {
                                        if(e.currentTarget.classList.contains('current-playing')){
                                            e.currentTarget.classList.remove('current-playing');
                                            this.props.Stop();
                                            return;
                                        }else if (document.getElementsByClassName('current-playing')[0]) {
                                            document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                        }
                                            e.currentTarget.classList.add('current-playing')
                                            this.props.Play(music)

                                    }}/>
                                    <div className={'title-col'}>
                                        <div><Link
                                                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                                                className={'song-title'}
                                                to={`/TrackOwners/${encodeURIComponent(music.owner.name)}/Songs/${music.songId}/${encodeURIComponent(music.title.replace('.','_'))}`}>{music.title}</Link>{music.isRecent ?
                                                <Badge className={'new-song-badge'}
                                                       variant="primary">NEW</Badge> : null}</div>
                                            <div><span className={'song-genres'}>{music.genres}</span></div>

                                    </div>
                                    <div className={'owner-row'}>
                                        <Row>
                                            <Col lg={12}>
                                            <span className={'table-header-name'}>
                                                <Link
                                                className={'song-track-owner'}
                                                to={`/TrackOwner/${music.owner.vendorId}`}>{music.owner.name}</Link></span>

                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={'track-type-col standard-col ' + (music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted ? 'has' : '')}>
                                        <div className={'song-comp-m-container'}>
                                            <div className={'song-comp-i'}/>
                                        </div>
                                        {music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted ? 'SONG + TRACK' : 'SONG ONLY'}
                                    </div>
                                    <div className={'stem-col standard-col ' + (music.master ? 'has' : '')}>
                                        <div className={'stems-mask-container'}>
                                            <div className={'stems-icon'}/>
                                        </div>
                                        STEMS
                                    </div>
                                    <div onClick={()=>this.props.history.push(`/TrackOwners/${encodeURIComponent(music.owner.name)}/Songs/${music.songId}/${encodeURIComponent(music.title.replace('.','_'))}`)} className={'details-col standard-col'}>
                                        <div className={'details-icon'}/>
                                        Details
                                    </div>
                                    <div className={'favorites-col standard-col '+(ifPerf&&'disabled-button ') + (this.props.state.auth.user && this.props.state.favorites.filter(fav => fav.songId === music.songId).length ? ' myfav' : '')}
                                        onClick={(e) => !ifPerf &&this.addorRemoveFavorites(music, e)}>
                                        <div className={'favorite-mask-container'}>
                                            <div className={'favorite-icon'}/>
                                        </div>
                                        FAVORITES
                                    </div>
                                    <div className={'shop-col standard-col filtered-dropdown'}>
                                        <Dropdown>
                                            <Dropdown.Toggle disabled={ifPerf}>
                                                <div className={'buy-mask-container'}>
                                                    <div className={'buy-icon'}/>
                                                </div>
                                                BUY
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu disabled={true}>
                                                <Dropdown.Item onClick={() =>this.addToCart(music, music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'281689e0-b84e-4320-8b1e-17a9d367600c':'43a75d7c-841e-49d7-892f-a23aaccd31ad', {
                                                    name: 'STANDARD LICENSE',
                                                    price:music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted?'60': music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'50':'30'
                                                })} className={'d-song-item'} >
                                                    <div className={'d-song-mask-container'}>
                                                        <div className={'d-song-icon'}/>
                                                    </div>
                                                    {music.mp3InstrumentalClip && !music.isAllInstrumentalsDeleted? 'SONG + TRACK' : 'SONG '}
                                                </Dropdown.Item>
                                                {music.master&&<Dropdown.Item onClick={() => this.addToCart(music, masterlicense[music.master], {
                                                    name: 'MASTER USE LICENSING',
                                                    price: music.master
                                                })} className={'d-stems-item'}>
                                                    <div className={'d-stems-mask-container'}>
                                                        <div className={'d-stems-icon'}/>
                                                    </div>
                                                    STEMS</Dropdown.Item>}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Collapse in={this.state.songCollapse[index]}>
                            <Row className={'collapse-row'}>
                                <Col lg={music.master? {span:5,offset:1}:{span:4,offset:4}} md={music.master?{span:5,offset:1}:{span:4,offset:4}} sm={music.master?{span:6}:12} xs={music.master?{span:6}:12} >
                                    <div className={'title'}>SONG{ music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted&&' + TRACK'} ${music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted?'60':music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted?'50':'30'} </div>
                                    <span className={'month-div'}>Ideal if you plan to record your voice over an Instrumental Track:<br/> Here’s what you get:</span>
                                    <Row className={'checked-rows'}>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Song with vocals
                                        </Col>
                                        <Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Copy of lyrics
                                        </Col>
                                        {music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted&&<><Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Track - instrumental version of song (MP3)
                                        </Col>
                                        {music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted && !music.isWAVDeleted&&<Col lg={12}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Track - instrumental version of song (WAV)
                                        </Col>}</>}


                                    </Row>
                                </Col>
                                {music.master &&<Col lg={5} md={5} sm={6} xs={6}>
                                    <div className={'title'}>STEMS {music.master?'$'+music.master:'Unavailable'} </div>
                                    <span className={'month-div'}>Ideal if you plan to record on a DAW:<br/> Here’s what you get:</span>
                                    <Row className={'checked-rows'}>
                                        <Col lg={6}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Song with vocals
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            The digital multi-track master STEM files (Pro Tools, Logic, etc)
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Copy of lyrics
                                        </Col>
                                        {(music.hasWavInstrumental ||music.mp3InstrumentalClip) && !music.isAllInstrumentalsDeleted&&<Col lg={6}>
                                            <div className={'collapse-check-icon-container'}>
                                                <div className={'collapse-check-icon'}/>
                                            </div>
                                            Track - instrumental version of song ({music.mp3InstrumentalClip&& !music.isAllInstrumentalsDeleted &&'MP3'}{music.hasWavInstrumental&& !music.isWAVDeleted&&'/WAV'})
                                        </Col>}
                                    </Row>
                                </Col>}
                            </Row>
                        </Collapse>
                    </div>
                ) : null}
                {this.props.loading || ((this.props.musicList.pagination && (this.props.musicList.pagination.nextStart < this.props.musicList.pagination.totalCount)) && this.props.getSongs) ?
                    <Row className={'more-row'}><button onClick={()=>this.props.getSongs()}
                                                        className={'load-more-btn'}>{this.props.loading ?
                        <Spinner animation="border"/> : 'SEE MORE'}</button></Row> : null}
            </div>
        )
    }
}

export default connect(state => ({
        state
    }),
    dispatch => ({
        onAddtoCart: (cartData) => {
            dispatch({
                type: "ADD_TO_CART",
                payload: cartData
            })
        },
        onAddToFavs: (music) => {
            dispatch({
                type: "ADD_TO_FAVS",
                payload: music
            })
        },
        onRemoveFromFavs: (music) => {
            dispatch({
                type: "REMOVE_FROM_FAVS",
                payload: music
            })
        },
        Play: (music) => {
            dispatch({
                type: "PLAY",
                payload: music
            })
        },
        Stop: () => {
            dispatch({
                type: "STOP",
            })
        },
        Toast: (data) => {
            dispatch({
                type: "NOTIFICATE",
                payload: data
            })
        },
    }))(withRouter(MusicTable));
