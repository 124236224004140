import React, {Component} from "react";
import Shop from "./Shop/Shop";

class NewSongs extends Component {
    componentDidMount() {
        document.title = "New Songs - Rocket Songs"
    }
    render() {
        return (
          <Shop pageName={'NS'} pageParams={'ShowOnlyNewSongs=true'} pageTitle={'Shop for a new song to make your own. The latest additions to Rocket Songs.'} title={'New Songs'} subtitle={'CHECK OUT THE LATEST SONGS ADDED TO OUR CATALOG'}/>
        )
    }
}

export default NewSongs;
