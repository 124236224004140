import React, {Component} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import axios from "axios";
import api from "../../../../api";
import {connect} from "react-redux";

class MySales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saleData:false
        }
    }
    componentDidMount() {
        this.setState({
            loading:true,
        })
        axios.request({
            url: `${api.Product.GetSales.url}${this.props.state.auth.vendor_id}`,
            method: api.Product.GetSales.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.access_token
            }
        }).then(response => response.data).then(saleData =>{
            this.setState({
                saleData: saleData,
                loading:false
            })
        }).catch(err=>{
            this.setState({
                saleData: [],
                loading:false
            })
        })
    }

    render() {
        return (
            <Container className={'prof-section-container'}>
                <Row>
                    <Col style={{padding: 0}} lg={7}><h2 className={'my-prof'}>My Sales </h2></Col>
                    <Col lg={5} style={{alignSelf: 'center',padding:0}}>
                        <Row className={'royalities-earnings'} style={{backgroundColor: '#fe5629'}}>
                            <Col lg={1} md={1} sm={1} xs={1}><FontAwesomeIcon icon={faDollarSign}/></Col>
                            <Col lg={6} md={8} sm={7} xs={7}>Royalties Earned To Date</Col>
                            <Col lg={4} md={2} sm={3} xs={3}>${this.state.saleData?this.state.saleData.balance:null}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={'my-songs-table-header'}>
                    <Col lg={3} md={3} sm={3} xs={3}><span className={'table-header-name'}>Track Title</span></Col>
                    <Col lg={2} md={2} sm={2} xs={2}><span className={'table-header-name'}>Quantity Sold</span></Col>
                    <Col lg={2} md={2} sm={2} xs={2}><span className={'table-header-name'}>Revenues</span></Col>
                    <Col lg={2} md={2} sm={2} xs={2}><span className={'table-header-name'}>Transaction Fees</span></Col>
                    <Col lg={3} md={3} sm={3} xs={3}><span className={'table-header-name royalities-title'}>Royalties</span></Col>
                </Row>
                {this.state.saleData&&this.state.saleData.sales&&this.state.saleData.sales.length?this.state.saleData.sales.map((sale,key)=>
                    <Row key={key} className={'my-songs-table-header'} >
                        <Col lg={3} md={3} sm={3} xs={3} style={{alignSelf: 'center'}} className={'eclipsed'}><Link to={'#'} ><span className={'my-songs-title my-prof-song-title '}>{sale.name}</span></Link></Col>
                        <Col lg={2} md={2} sm={2} xs={1} style={{alignSelf: 'center'}}><span
                            className={'my-songs-title my-prof-song-title'}>{sale.quantity}</span></Col>
                        <Col lg={2} md={2} sm={2} xs={3} style={{alignSelf: 'center'}}><span
                            className={'my-songs-title my-prof-song-title'}>${sale.revenue}</span></Col>
                        <Col lg={2} md={2} sm={2} xs={2} style={{alignSelf: 'center'}}><span
                            className={'my-songs-title my-prof-song-title'}>${sale.fee}</span></Col>
                        <Col lg={3} md={3} sm={3} xs={3} style={{
                            alignSelf: 'center',
                            background: 'rgb(185, 193, 54)',
                            color: '#ffffff',
                            padding: '10px 15px',
                        }}><span>${sale.royalty}</span></Col>
                    </Row>
                ):this.state.loading?<Spinner className={'my-songs-preloader'} animation={'border'}/>:null}

                        <Row className={'totals-container'}>
                            <Col lg={{offset:3,span:2}} md={{offset:3,span:2}} sm={{offset:3,span:2}} xs={{offset:3,span:1}}>{this.state.saleData?this.state.saleData.totalSold:null}</Col>
                            <Col lg={2} md={2} sm={2} xs={3} style={{padding:'15px 0'}}>${this.state.saleData?this.state.saleData.orderItemTotal:null}</Col>
                            <Col lg={2} md={2} sm={2} xs={2}>${this.state.saleData?this.state.saleData.totalFees:null}</Col>
                            <Col lg={3} md={3} sm={3} xs={3}>${this.state.saleData?this.state.saleData.totalRoyalty:null}</Col>

                        </Row>
            </Container>
        )
    }
}

export default connect(state => ({
        state
    }))(MySales);
