import React, {Component} from "react";

class OpinionsCard extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }
    render() {

        return (
            <div className={'opinion-card'}>
                <div className={'opinion-title'}>
                    <div className={'opinion-username'}>{this.props.userName}</div>
                </div>
                <div className={'opinion-text'}>{this.props.text}</div>
                <div className={'opinion-date'}>{this.props.date}</div>
            </div>
        )
    }
}

export default OpinionsCard;
