export default function cart(state = {}, action){

    if(action.type === 'ADD_TO_CART'){
        if(state.Items){
            state.Items.push(action.payload)
        }else if(state.cartItems){
            state.cartItems = action.payload
        }else{
            state.Items = []
            state.Items.push(action.payload)
        }
        return{
            ...state
        }
    }
    if(action.type === 'ADD_SELECTED_ITEMS'){
        return{
            ...state,
            selectedItems:action.payload
        }
    }

    if(action.type === 'REMOVE_FROM_CART'){
        state.Items = state.Items.filter(item=>!(item.ProductId===action.payload.ProductId && item.LicenseId===action.payload.LicenseId))
        return{
            ...state
        }
    }
    if(action.type === 'REMOVE_COUPON'){
       delete state['coupon']
        return{
            ...state
        }
    }
    if(action.type === 'FETCH_CART'){
        return action.payload
    }
    if(action.type === 'LOGOUT'){
        return {
            Items:[]
        }
    }

    return state;
}
