import React, {Component} from "react";
import {Button, Col, Container, Dropdown, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faDownload,
    faPlay,
    faSearch,
    faSync,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import axios from "axios";
import api from "../../../../api";
import {connect} from "react-redux";
import moment from "moment";
import {withRouter} from 'react-router-dom'
import sadSmile from "../../../../images/sadSmile.png";
import excArrow from "../../../../images/excarrow.png";
const exclusiveLicense = {
    '2ac1e267-27ea-4262-916c-b8ce6bc32637':{
        name:'3-Month Exclusive',
        price:60
    },
    '3f73a9e4-8954-4d8f-9376-8ce18779a9fd':{
        name:'6-Month Exclusive',
        price:100
    },
    'efa4e66a-b6f0-427b-adbf-fdab2fa53539':{
        name:'1-Year Exclusive',
        price:150
    },
}
class ProfileLicensed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lyricsModal:false,
            song: false,
            SuccessfullyAdded:false,
            exclusiveModal:false,
            licensedSongs:{
                pagination:{},
                results:[]
            },
            disabledLoading:{}

        }
    }
    componentDidMount() {
        this.getLicensedSongs()
    }
    getLicensedSongs=()=>{
        this.setState({loading:true})
        axios.request( {
            url:`${api.User.Get.url}${this.props.state.auth.user_id}/licensedSongs?start=${this.state.licensedSongs.pagination.nextStart?this.state.licensedSongs.pagination.nextStart:0}`,
            method: api.User.Get.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.access_token
            },
        }).then(response=>response.data).then(response=> {
            this.setState({
                licensedSongs:{
                    ...response,
                    results:this.state.licensedSongs.results.concat(response.results)
                },
                loading:false
            })
        })
    }
    handleGetSongUri = (uri,productId)=>   axios.request( {
        url:`${api.Product.GetDownload.url}${this.props.state.auth.user.userId}/downloadlink?path=${uri}&productId=${productId}`,
        method: api.Product.GetDownload.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.props.state.auth.access_token
        },
    })
    downloadURI = (uri,song) => {
        this.handleGetSongUri(uri,song.productId).then(resp=>{
            if(resp.status===200){
                const el = document.createElement('a');
                el.href = resp.data;
                el.setAttribute('download', true);
                el.style = {position: 'absolute', left: '-9999px'};
                document.body.appendChild(el);
                el.click();
                el.remove();
            }

        })


    }
    addToCart = (song, license) => {


        const cartData = {
            UserId: this.props.state.auth.user_id,
            items: [
                {
                    ProductId: song.songId,
                    LicenseId: license,
                },
            ]
        };

        if(this.props.state.cart.cartItems.filter(item=>item.product.songId===song.songId && (item.license.licenseId===license || (Object.keys(exclusiveLicense).includes(license) && Object.keys(exclusiveLicense).includes(item.license.licenseId))) ).length){
            this.props.Toast({
                type:'primary',
                message:'You already have this product in your cart'
            })
        }else{
            if(license==='a1773a78-6a17-4b52-8abd-34da4620e80f'){
                this.setState({
                    disabledLoading:{
                        ...this.state.disabledLoading,
                        [song.songId]:'loading'
                    }


                })
            }
            axios.request({
                url: api.Cart.Add.url,
                method: api.Cart.Add.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                data: JSON.stringify(cartData),
            }).then(response => response.data).then(response => {
                this.props.onAddtoCart(response.cartItems)
                this.setState({
                    SuccessfullyAdded: license!=='a1773a78-6a17-4b52-8abd-34da4620e80f'
                })
                if(license==='a1773a78-6a17-4b52-8abd-34da4620e80f'){
                    this.setState({
                        disabledLoading:{
                            ...this.state.disabledLoading,
                            [song.songId]:true
                        }
                    })
                }
                this.props.Toast({
                    type:'success',
                    message:'Successfully added to cart'
                })
            }).catch(err => {
                this.props.Toast({
                    type:'primary',
                    message:'At first, you should choose one of your products.'
                })
                this.setState({
                    borderedSongs:true
                })
            })
        }
    }
    checkExclusive = (song) => {
        axios.request({
            url: `${api.Song.url}${song.songId}/hasExclusiveAvailable`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control':'no-cache'
            },
            method: api.Song.method,
        }).then(response => response.data).then(response => {
            this.setState({
                isExclusiveAvailable: response,
                exclusiveModal: true,
                song: song
            })
        })
    }
    handleCreateText = (lyrics,title) => {
        const element = document.createElement("a");
        const file = new Blob([lyrics], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = title+"_lyrics.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    handlePlaySong =async (e,song)=>{
        if(e.currentTarget.classList.contains('current-playing')){
            e.currentTarget.classList.remove('current-playing');
            this.props.Stop();
            return;
        }else if (document.getElementsByClassName('current-playing')[0]) {
            document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
        }
        e.currentTarget.classList.add('current-playing')
        song.vocals =  await this.handleGetSongUri(song.vocals,song.productId).then(resp=>resp.data)
        song.mp3InstrumentalClip =  song.mp3Instrumental ?await this.handleGetSongUri(song.mp3Instrumental,song.productId).then(resp=>resp.data):null
        this.props.Play(song)

    }
    render() {
        return (
            <section ref={this.licensedSongs} className={'profile-sec-section'}>
                <Container className={'prof-section-container'}>
                    <Modal className={'exclusive-modal-body'} show={this.state.exclusiveModal}
                           onHide={() => this.setState({
                               exclusiveModal: false,
                               SuccessfullyAdded: false,
                               song: false
                           })}>

                        <Modal.Body>
                            <div onClick={() => this.setState({
                                exclusiveModal: false,
                                song: false,
                                SuccessfullyAdded: false,
                            })} className={'close-button'}><FontAwesomeIcon icon={faTimes}/></div>
                            {this.state.SuccessfullyAdded ?
                                <div className={'thanks-container'}>
                                    <Row className={'purchase-message'}>
                                        <FontAwesomeIcon className={'purchase-check-icon'}
                                                         icon={faCheckCircle}/>
                                        <Col style={{padding: '0 70px'}} lg={12} md={12} sm={12} xs={12}>
                                            <span>Your purchase has been added to your cart.</span>
                                        </Col>
                                    </Row>
                                    <button
                                        onClick={() => this.props.history.push('/Cart')}
                                        className={'btn link-to-cart'}> CLICK HERE <br/>
                                        TO CHECKOUT
                                    </button>
                                </div>
                                : <div className={'exclusive-modal-content'}>
                                    <Row style={{marginTop: '20px'}}>
                                        <Col className={'title'} lg={12} md={12} sm={12} xs={12}>
                                            {this.state.song ? this.state.song.title : null}
                                        </Col>
                                        <Col className={'subtitle'} lg={12} md={12} sm={12} xs={12}>
                                            {this.state.song ? this.state.song.owner.name : null}
                                        </Col>
                                    </Row>
                                    <Row className={'available-message'}>
                                        {!this.state.isExclusiveAvailable && this.state.song.hasExclusive && !this.state.song.isExpired ?
                                            <Col style={{padding: '0 70px'}} lg={12} md={12} sm={12} xs={12}>
                                                <span style={{fontSize: '15px', color: '#292b2c'}}>Your Exclusivity expires on:</span><br/>
                                                {moment(this.state.song.exclusiveEndDate).format('MMM DD, YYYY')}
                                            </Col>
                                            : this.state.isExclusiveAvailable && this.state.song.isExpired ?
                                                <Col style={{padding: '0 70px'}} lg={12} md={12} sm={12} xs={12}>
                                                    <span style={{fontSize: '15px', color: '#292b2c'}}>Your Exclusivity expired on:</span><br/>
                                                    {moment(this.state.song.exclusiveEndDate).format('MMM DD, YYYY')}
                                                </Col> : this.state.isExclusiveAvailable ?
                                                    <Col style={{padding: '0 70px'}} lg={12} md={12} sm={12} xs={12}>
                                                        Exclusivity is available
                                                        for this Song!
                                                    </Col> :
                                                    <Col style={{padding: '0 70px'}} lg={12} md={12}
                                                         sm={12} xs={12}>
                                                        <div className={'sorry-container'}>Sorry <img
                                                            alt={''}
                                                            className={'smaile-img'}
                                                            src={sadSmile}/>
                                                        </div>
                                                        Exclusivity is not available
                                                        for this Song
                                                    </Col>}
                                    </Row>
                                    {this.state.isExclusiveAvailable || this.state.song.hasExclusive || this.state.song.isExpired ?
                                        <div>
                                            <p>{this.state.song.isExpired ? 'Renew it now!' : this.state.song.hasExclusive ? 'Renew it now before it expires!' : 'Choose an option:'}</p>
                                            <Row className={'exclusive-option-type'}>
                                                <Col className={'type-title'} lg={5} md={12} sm={12} xs={12}>
                                                    3-Month Exclusive
                                                </Col>
                                                <Col className={'no-padding type-button'} lg={7} md={12} sm={12} xs={12}>
                                                    <Button
                                                        onClick={() => this.addToCart(this.state.song, '2ac1e267-27ea-4262-916c-b8ce6bc32637')}
                                                        className={'add-cart'}>{this.state.song.isExpired || this.state.song.hasExclusive ? 'RENEW' : 'ADD TO CART'}</Button>
                                                    <img alt="" className={'exclusive-modal-arrow'} src={excArrow}/>
                                                    <div className={'price-container'}>$60</div>
                                                </Col>
                                            </Row>
                                            <Row className={'exclusive-option-type'}>
                                                <Col className={'type-title'} lg={5} md={12} sm={12} xs={12}>
                                                    6-Month Exclusive
                                                </Col>
                                                <Col className={'no-padding type-button'} lg={7} md={12} sm={12} xs={12}>
                                                    <Button
                                                        onClick={() => this.addToCart(this.state.song, '3f73a9e4-8954-4d8f-9376-8ce18779a9fd')}
                                                        className={'add-cart'}>{this.state.song.isExpired || this.state.song.hasExclusive ? 'RENEW' : 'ADD TO CART'}</Button>
                                                    <img alt="" className={'exclusive-modal-arrow'}
                                                         src={excArrow}/>
                                                    <div className={'price-container'}>$100</div>
                                                </Col>
                                            </Row>
                                            <Row className={'exclusive-option-type'}>
                                                <Col className={'type-title'} lg={5} md={12} sm={12} xs={12}>
                                                    1-Year Exclusive
                                                </Col>
                                                <Col className={'no-padding type-button'} lg={7} md={12} sm={12} xs={12}>
                                                    <Button
                                                        onClick={() => this.addToCart(this.state.song, 'efa4e66a-b6f0-427b-adbf-fdab2fa53539')}
                                                        className={'add-cart'}>{this.state.song.isExpired || this.state.song.hasExclusive ? 'RENEW' : 'ADD TO CART'}</Button>
                                                    <img alt="" className={'exclusive-modal-arrow'}
                                                         src={excArrow}/>
                                                    <div className={'price-container'}>$150</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className={'guidline-container'}>
                                                    Click here to view our <Link
                                                    to={'/License/Exclusive'}>Exclusive
                                                    License Guidelines</Link>
                                                </Col>
                                            </Row></div> : <button
                                            style={{marginTop: '40px', marginBottom: '10px'}}
                                            onClick={() => this.setState({
                                                exclusiveModal: false,
                                                song: false,
                                                SuccessfullyAdded: false
                                            })}
                                            className={'btn link-to-cart'}>CLOSE</button>}
                                </div>}
                        </Modal.Body>

                    </Modal>
                    <h2 className={'my-prof'}>Licensed Songs</h2>
                    {this.state.licensedSongs.results && this.state.licensedSongs.results.length ? <Row className={'my-songs-table-header no-padding'}>
                        <Col className={'no-padding-left'} lg={3} md={2} sm={5} xs={5}><span
                            className={'table-header-name'}>Track Title</span></Col>
                        <Col lg={3} md={2} className={'owner-row'}><span
                            className={'table-header-name'}>Track Owner</span></Col>
                        <Col className={'center-text'} lg={1} md={1} sm={1} xs={1}><span
                            className={'table-header-name'}>Play</span></Col>
                        <Col className={'center-text'} lg={2} md={2} sm={2} xs={3}><span
                            className={'table-header-name'}>Downloads</span></Col>
                        <Col className={'center-text'} lg={1} md={1} sm={1} xs={1}><span
                            className={'table-header-name text-center'}>Royalty Free</span></Col>
                        <Col className={'center-text'} lg={2} md={3} sm={2} xs={2}><span
                            className={'table-header-name'}>Exclusive</span></Col>
                    </Row>:null}
                    {this.state.licensedSongs.results && this.state.licensedSongs.results.length ? this.state.licensedSongs.results.map((song, index) => {
                        return <Row className={'licensed-song-row no-padding'}
                                    key={index}>
                            <Col className={'no-padding-left'} lg={3} md={2} sm={5} xs={5}>
                                <Row>
                                    <Col className={'no-padding'} lg={12}><Link className={'song-title'}
                                                                                to={`/TrackOwners/${song.owner ? encodeURIComponent(song.owner.name) : null}/Songs/${song.songId}/${song.title ? encodeURIComponent(song.title.replace('.','_')) : null}?owner=true`}>{song.title}</Link></Col>
                                    <Col className={'no-padding'} lg={12}><span
                                        className={'song-genres'}>{song.genres}</span></Col>
                                </Row>
                            </Col>
                            <Col lg={3} md={2} className={'owner-row'}><span
                                className={'table-header-name'}>
                                    <Link className={'song-track-owner'}
                                          to={`/TrackOwner/${song.owner.vendorId}`}>{song.owner.name}</Link></span>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <div style={{height: '48px'}} id={song.songId} className={'shop-button'}
                                     onClick={(e) => this.handlePlaySong(e,song)}><FontAwesomeIcon icon={faPlay}/></div>
                            </Col>
                            <Col lg={2} md={3} sm={2} xs={3}>
                                <Dropdown className={'downloads-dropdown-button'}>
                                    <Dropdown.Toggle>
                                        <span className={'dropdown-text'}>DOWNLOADS</span>
                                        <span className={'dropdown-icon'}><FontAwesomeIcon
                                            icon={faDownload}/></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {song.vocals ? <Dropdown.Item
                                            onClick={() => this.downloadURI(song.vocals,song)}>Vocals</Dropdown.Item> : null}
                                        {song.mp3Instrumental ?
                                            <Dropdown.Item
                                                onClick={() => this.downloadURI(song.mp3Instrumental,song)}>MP3
                                                Instrumental</Dropdown.Item> : null}
                                        {song.wavInstrumental ?
                                            <Dropdown.Item
                                                onClick={() => this.downloadURI(song.wavInstrumental,song)}>Wav
                                                Instrumental</Dropdown.Item> : null}
                                        {song.master ?
                                            <Dropdown.Item
                                                onClick={() => this.downloadURI(song.master,song)}>Master
                                                Track</Dropdown.Item> : null}
                                        <Dropdown.Item onClick={() =>this.handleCreateText(song.lyrics.text,song.title)}>Lyrics</Dropdown.Item>
                                        <Dropdown.Item
                                            target={'_blank'}
                                            href={`https://chordify.net/?external_id=${song.songId}&url=${encodeURIComponent(song.vocals)}&stream_url=${encodeURIComponent(song.mp3Instrumental ? song.mp3Instrumental : song.vocals)}&rs_preview=true&utm_source=rocketsongs&utm_campaign=Rocket%20Songs`}>Chords</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} className={'text-center d-flex justify-content-center'}>
                                {song.hasRoyaltyFree && song.royaltyFreeIsAvailable ?   <Button type="primary" disabled={this.state.disabledLoading[song.songId]} loading={this.state.disabledLoading[song.songId]==='loading'} onClick={()=>this.addToCart(song, 'a1773a78-6a17-4b52-8abd-34da4620e80f')}  style={{height:'48px'}} >Buy</Button>: <input type={"checkbox"} disabled={!song.hasRoyaltyFree || (song.hasRoyaltyFree && !song.royaltyFreeIsAvailable)} checked={song.hasRoyaltyFree && !song.royaltyFreeIsAvailable} />}
                            </Col>
                            <Col lg={2} md={3} sm={2} xs={2}>
                                {song.hasExclusive && !song.isExpired ?
                                    <div onClick={() => this.checkExclusive(song)}
                                         className={'exclusive-available-check'}>
                                        <div style={{background: '#0ab048', fontSize: '14px'}}
                                             className={'checker'}>
                                            Exp On
                                            {'  ' + moment(song.exclusiveEndDate).format('MM/DD/YYYY')}</div>
                                        <div style={{background: '#0ab048'}} className={'finder'}>
                                            <FontAwesomeIcon icon={faSync}/>
                                        </div>
                                    </div>
                                    : song.hasExclusive && song.isExpired ?
                                        <div onClick={() => this.checkExclusive(song)}
                                             className={'exclusive-available-check'}>
                                            <div style={{background: '#999', fontSize: '14px'}}
                                                 className={'checker'}>
                                                Expired On
                                                {'  ' + moment(song.exclusiveEndDate).format('MM/DD/YYYY')}</div>
                                            <div style={{background: '#999'}} className={'finder'}>
                                                <FontAwesomeIcon icon={faSync}/>
                                            </div>
                                        </div>
                                        :
                                        <div onClick={() => this.checkExclusive(song)}
                                             className={'exclusive-available-check'}>
                                            <div className={'checker'}>Check Availability</div>
                                            <div className={'finder'}><FontAwesomeIcon icon={faSearch}/>
                                            </div>
                                        </div>}

                            </Col>


                        </Row>
                    }) :!this.state.loading ? <Row className={'secondary-desc-text no-padding'}>
                        <Col className={'no-padding'}>
                            <p> You haven't licensed any songs yet.</p>
                        </Col>
                    </Row>:null}
                    {this.state.loading || (this.state.licensedSongs.pagination && this.state.licensedSongs.pagination.nextStart) ?
                        <Row className={'more-row'}><button onClick={this.getLicensedSongs}
                                                            className={'load-more-btn'}>{this.state.loading ?
                            <Spinner animation="border"/> : 'SEE MORE'}</button></Row> : null}
                </Container>
            </section>

        )
    }
}

export default connect(state => ({
    state
}),dispatch => ({
    onAddtoCart: (cartData) => {
        dispatch({
            type: "ADD_TO_CART",
            payload: cartData
        })
    },
    Play: (music) => {
        dispatch({
            type: "PLAY",
            payload: music
        })
    },
    Stop: () => {
        dispatch({
            type: "STOP",
        })
    },
    Toast: (data) => {
        dispatch({
            type: "NOTIFICATE",
            payload: data
        })
    },
}))(withRouter(ProfileLicensed));
