import React, {Component} from "react";

class Preloader extends Component {
    constructor(props) {
        super(props);
        this.state={
            modalVisible:false
        }
    }


    render() {
        return (
            <div className="preloader" style={{display:this.props.display}}>
                <div className="status" style={{display:this.props.display}}>
                    <div className="spinner" style={{display:this.props.display}}>
                        <div className="cube1"/>
                        <div className="cube2"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preloader;