import React, {Component} from "react";
import AudioPlayer, {RHAP_UI} from "react-h5-audio-player";
import {Dropdown} from "react-bootstrap";
import  {withRouter,Link} from "react-router-dom";
import {connect} from "react-redux";
import api from "../../../api";
import axios from "axios";
import './Player.sass';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const masterlicense = {
    '295': 'baf94f4b-5baa-4ff8-8e51-dd07f26056ba',
    '495': '5e868377-865f-4f70-ae31-f6d51fa79d01',
    '695': '82d084b5-acb5-4ec9-a77b-318723350c1b',
    '895': '6cec9e1d-b740-441c-95e0-9906931dfd9e',
}
const exclusiveLicense = {
    '2ac1e267-27ea-4262-916c-b8ce6bc32637':{
        name:'3-Month Exclusive',
        isExclusive:1,
        price:60
    },
    '3f73a9e4-8954-4d8f-9376-8ce18779a9fd':{
        name:'6-Month Exclusive',
        isExclusive:1,
        price:100
    },
    'efa4e66a-b6f0-427b-adbf-fdab2fa53539':{
        name:'1-Year Exclusive',
        isExclusive:1,
        price:150
    },
}
class Player extends Component {
    constructor(props) {
        super(props);
        this.state= {
            playingState:true,
            musicType:false
        }
        this.player=React.createRef();
    }
    componentWillUnmount() {
        this.props.Stop()
    }
    componentWillMount() {
        this.props.Stop();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.state.player.playMusic.title && !this.props.state.player.playMusic.title){
            this.player.current.audio.current.src = undefined;
        }
    }

    addToCart = (song, license, licenseData) => {
        if(Object.keys(exclusiveLicense).includes(license)){
            licenseData = exclusiveLicense[license]
        }
        const cartData = {
            UserId: this.props.state.auth.user_id,
            items: [
                {
                    ProductId: song.songId,
                    LicenseId: license,
                },
            ]
        };
        if (this.props.state.auth.user_id) {
            if (!this.props.state.cart.cartItems.filter(item => item.product.songId === song.songId).length && Object.keys(exclusiveLicense).includes(license)) {
                this.props.Toast({
                    type: 'primary',
                    message: 'At first, you should choose one of your products.'
                })
            } else {
                if ((this.props.state.cart.cartItems.filter(item => item.product.songId === song.songId).length && !Object.keys(exclusiveLicense).includes(license)) || (this.props.state.cart.cartItems.filter(item => item.product.songId === song.songId && item.license.name.includes('Exclusive')).length && Object.keys(exclusiveLicense).includes(license))) {
                    this.props.Toast({
                        type: 'primary',
                        message: 'You already have this product in your cart'
                    })
                } else {
                    axios.request({
                        url: api.Cart.Add.url,
                        method: api.Cart.Add.method,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': this.props.state.auth.access_token
                        },
                        data: JSON.stringify(cartData),
                    }).then(response => response.data).then(response => {
                        this.props.onAddtoCart(response.cartItems)
                        this.props.Toast({
                            type: 'success',
                            message: 'Successfully added to cart'
                        })
                    }).catch(err => {
                        this.props.history.push(this.props.location?.pathname)
                        this.componentDidMount()
                    })
                }
            }
        } else {
            const cartItem = {
                product: song,
                ProductId: song.songId,
                LicenseId: license,
                license: {
                    name: licenseData.name,
                    isExclusive: licenseData.isExclusive,
                    licenseId: license
                },
                price: parseInt(licenseData.price)
            }
            this.props.onAddtoCart(cartItem)
            this.props.Toast({
                type: 'success',
                message: 'Successfully added to cart'
            })
        }
    }
    addorRemoveFavorites=(music)=>{
        if(this.props.state.auth.user_id){
            const data= JSON.stringify({songId:music.songId})
            if(this.props.state.favorites.length && this.props.state.favorites.filter(song=>song.songId===music.songId).length){
                axios.request( {
                    url:`${api.User.RemoveFavorite.url}${this.props.state.auth.user_id}/favorites/${music.songId}`,
                    method: api.User.RemoveFavorite.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(response => {
                    if (response.status===200) {
                        this.props.onRemoveFromFavs(music)
                    }
                })
            }else{
                axios.request( {
                    url:`${api.User.AddFavorite.url}${this.props.state.auth.user_id}/favorites`,
                    method: api.User.AddFavorite.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    },
                    data:data
                }).then(response => {
                    if (response.status===200) {
                        this.props.onAddToFavs(music)
                    }
                })
            }
        }
        /*
        else{
            this.props.header.current.navOpen();
        }
        */
    }


    render() {
        const ifPerf = this.props.state.auth.access_token && (this.props.state.auth.user.type === 'Provider' || this.props.state.auth.user.type === 'Applicant');
        const music = this.props.state.player.playMusic;
        return (
            <div className={'PlayerContainer '+(music.vocals?'showplayer':'')}>
                <span className={'music-close'} onClick={(e)=>{
                    this.player.current.audio.current.pause();
                    if( document.getElementsByClassName('current-playing')[0]){
                        document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                    }
                    this.player.current.audio.current.src = undefined;
                    this.setState({
                        playingState:false
                    })
                    this.props.Stop();
                }}><FontAwesomeIcon icon={faTimes}/></span>
                <AudioPlayer onPause={()=>this.setState({playingState:false})}
                             onPlay={()=>this.setState({playingState:true})}
                             ref={this.player} autoPlay={true}
                             layout={'horizontal'}
                             onEnded={()=>{
                                 this.setState({
                                     playingState:false
                                 })
                             }}
                             src={music.vocals?music.vocals:undefined}
                             customProgressBarSection={[
                                 <div className={'track-info-container'}>
                                     <Link onClick={()=>window.scrollTo({ top: 0, behavior: 'smooth' })} className={'title'} to={`/TrackOwners/${music.owner?encodeURIComponent(music.owner.name):null}/Songs/${music.songId}/${music.title?encodeURIComponent(music.title.replace('.','_')):null}`}>{music.title}</Link><br/>
                                     <span>{music.genres}</span>
                                 </div>,
                                 <div className={'standard-col first-order'}>
                                     <div className={'player-icon-container'}>
                                         <div onClick={(e)=>{
                                             if( this.player.current&&this.player.current.audio.current.src!==music.vocals) {
                                                 this.player.current.audio.current.src = music.vocals;
                                                 this.setState({
                                                     musicType:false,
                                                     playingState:true
                                                 })
                                             }else if(this.player.current){
                                                 this.player.current.togglePlay(e)
                                                 this.setState({
                                                     playingState:!this.state.playingState
                                                 })
                                             }
                                         }} className={'song-m-container '+(!this.state.musicType?'current-type-play':'')}>
                                             <div className={'song-icon'}/>
                                         </div>
                                         Song
                                     </div>

                                     {music.mp3InstrumentalClip&&<div className={'player-icon-container'}>
                                         <div onClick={(e)=>{
                                             if( this.player.current&&this.player.current.audio.current.src!==music.mp3InstrumentalClip) {
                                                 this.player.current.audio.current.src = music.mp3InstrumentalClip;
                                                 this.setState({
                                                     musicType:true,
                                                     playingState:true
                                                 })
                                             }else if(this.player.current){
                                                 this.player.current.togglePlay(e)
                                                 this.setState({
                                                     playingState:!this.state.playingState
                                                 })
                                             }
                                         }} className={'track-m-container '+(this.state.musicType?'current-type-play':'')}>
                                             <div className={'track-icon'}/>
                                         </div>
                                         TRACK
                                     </div>}
                                 </div>,
                                 <div className={'controls-container'}>
                                     <div onClick={()=>{
                                         this.player.current.setJumpTime(-10000)
                                     }} className={'rollback-m-container'}>
                                         <div className={'rollback-icon'}/>
                                     </div>
                                     <div className={'play-pause-m-container'}>
                                         <div className={'play-p-icon '+(!this.state.playingState?'paused':'')} onClick={(e)=>{
                                            this.setState({
                                                playingState:!this.state.playingState
                                            });
                                            this.player.current.togglePlay(e);}}/>
                                     </div>
                                     <div onClick={()=>{
                                         this.player.current.setJumpTime(10000)
                                     }} className={'reversed rollback-m-container'}>
                                         <div className={'rollback-icon'}/>
                                     </div>
                                 </div>,
                                 RHAP_UI.CURRENT_LEFT_TIME,
                                 RHAP_UI.PROGRESS_BAR,
                                 RHAP_UI.DURATION,
                                 RHAP_UI.VOLUME_CONTROLS,
                                 <div className={'standard-col second-order'}>
                                     <div onClick={(e) => !ifPerf &&this.addorRemoveFavorites(music, e)} className={'icon-container '+(ifPerf?'disabled-button ':'') + (this.props.state.auth.user && Array.isArray( this.props.state.favorites) && this.props.state.favorites.filter(fav => fav.songId === music.songId).length ? ' myfav' : '')}>
                                         <div className={'favorite-mask-container'}>
                                             <div className={'favorite-icon'}/>
                                         </div>
                                         FAVORITES
                                     </div>
                                     <Dropdown  className={'shop-col standard-col filtered-dropdown'}>
                                         <Dropdown.Toggle style={{height: '31px'}} className={'icon-container'} disabled={ifPerf}>
                                             <div className={'buy-mask-container'}>
                                                 <div className={'buy-icon'}/>
                                             </div>
                                             <div>BUY</div>
                                         </Dropdown.Toggle>
                                         <Dropdown.Menu disabled={true}>
                                             <Dropdown.Item onClick={() =>this.addToCart(music, music.mp3InstrumentalClip?'281689e0-b84e-4320-8b1e-17a9d367600c':'43a75d7c-841e-49d7-892f-a23aaccd31ad', {
                                                 name: 'STANDARD LICENSE',
                                                 price: music.hasWavInstrumental&& !music.isAllInstrumentalsDeleted?'60':music.mp3InstrumentalClip?'50':'30'
                                             })} className={'d-song-item'}>
                                                 <div className={'d-song-mask-container'}>
                                                     <div className={'d-song-icon'}/>
                                                 </div>
                                                 SONG
                                             </Dropdown.Item>
                                             {music.master&&<Dropdown.Item onClick={() => this.addToCart(music, masterlicense[music.master], {
                                                 name: 'MASTER USE LICENSING',
                                                 price: music.master
                                             })}  className={'d-stems-item'}>
                                                 <div className={'d-stems-mask-container'}>
                                                     <div className={'d-stems-icon'}/>
                                                 </div>
                                                 STEMS</Dropdown.Item>}
                                         </Dropdown.Menu>
                                     </Dropdown>
                                 </div>,

                             ]}
                             customControlsSection={[

                             ]}/>

            </div>
        )
    }
}

export default connect( state => ({
        state
    }),
    dispatch => ({
        onAddtoCart: (cartData) => {
            dispatch({
                type: "ADD_TO_CART",
                payload: cartData
            })
        },
        onAddToFavs: (music) => {
            dispatch({
                type: "ADD_TO_FAVS",
                payload: music
            })
        },
        onRemoveFromFavs: (music) => {
            dispatch({
                type: "REMOVE_FROM_FAVS",
                payload: music
            })
        },
        Stop: () => {
            dispatch({
                type: "STOP",
            })
        },
        Toast: (data) => {
            dispatch({
                type: "NOTIFICATE",
                payload: data
            })
        },
    }))(withRouter(Player));
