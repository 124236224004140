import React, {Component} from "react";
import {Button, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import api from "../../../../api";
import {connect} from "react-redux";
import axios from "axios";
import {BlobServiceClient} from "@azure/storage-blob";

class ProviderConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checked: 0,
            errors: {},
            Songs: {
                0: {},
                1: {},
                2: {},
            },
            warnCheckbox:false,
            confForm: {},
        }
    }

    handleSubmitSongs = (e) => {
        e.preventDefault();
        if(!(this.state.checked % 2)){
            this.setState({
                warnCheckbox:true
            })
            return;
        }
        const applicantForm = new FormData();
        applicantForm.append(`ApplicantId`, this.props.state.auth.user.userId)
        let validation = true;
        let errors = {};
        [...Array(3)].forEach((k, i) => {
            let Song = this.state.Songs[i];
            if (Song.Title.length > 30) {
                errors[i] = "Title can't have more than 30 chars";
                validation = false;
            } else {
                if (!Song.Song) {
                    errors[i] = 'Music file is required';
                    validation = false;
                } else {
                    applicantForm.append(`Songs[${i}].Title`, Song.Title)
                }
            }

        })
        this.setState({
            errors: {
                ...errors
            }
        })
        if (validation) {
            this.setState({loading: true})
            axios.request({
                url: api.Applicant.Request.url,
                headers: {
                    'Authorization': this.props.state.auth.access_token
                },
                method: api.Applicant.Request.method,
                data: applicantForm,
            }).then(response => {
                if (response.status === 200)
                    this.uploadFiles(this.state.Songs,response.data)

            })
        }
    }


    uploadFiles =  async (files,response) => {
        try {
            const promises = [];
            let confirmData = {
                ApplicantSongs:[]
            };
            let name = this.props.state.auth.user.owner?.name?this.props.state.auth.user.owner?.name:this.props.state.auth.user.name
            Object.keys(files).forEach(fileKey=>{
                confirmData.ApplicantSongs.push(response[fileKey].applicantSongId)
                let blobServiceClient = new BlobServiceClient(response[fileKey].resource.url);
                let containerClient = blobServiceClient.getContainerClient(response[fileKey].resource.location);
                const blockBlobClient = containerClient.getBlockBlobClient(response[fileKey].resource.fileName);
                promises.push(blockBlobClient.uploadBrowserData(files[fileKey].Song,{
                    blockSize: 4 * 1024 * 1024, // 4MB block size
                    concurrency: 10,
                    blobHTTPHeaders:{
                        blobContentType:files[fileKey].Song?.type,
                        blobContentDisposition: 'attachment; filename='+(name+'_'+files[fileKey].Song?.name).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(',','').replace(' ','-'),
                    },
                }));
            })
            await Promise.all(promises);
            axios.request({
                url:api.Applicant.Confirm.url,
                method:api.Applicant.Confirm.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                data:confirmData
            }).then(()=>{
                this.setState({
                    pageLoading: true,
                })
                axios.request({
                    url: `${api.User.Get.url}${this.props.state.auth.user_id}`,
                    method: api.User.Get.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(response => response.data).then(response => {
                    this.props.onUpdateProfile(response)
                    this.setState({
                        userData: response,
                        pageLoading: false,
                    })
                })
            })
        } catch (error) {
            console.log(error)
            this.setState({
                loading:false
            })
        }
    }
    applicantFormHandler = (e, multiIndex) => {
        const key = e.currentTarget.name;
        const value = e.currentTarget.value;
        this.setState({
            ...this.state,
            Songs: {
                ...this.state.Songs,
                [multiIndex]: {
                    ...(this.state.Songs && this.state.Songs[multiIndex] ? this.state.Songs[multiIndex] : {}),
                    [key]: value
                },
            }
        })
    }

    render() {
        return (
            <Container className={'prof-section-container'} style={{paddingBottom:'100px'}}>
                <p style={{margin: 0}} className={'center-text'}>If you have great original songs you'd like to get cut, then submit three of them below.</p>
                <p style={{margin: 0}} className={'center-text'}>Please allow two weeks for a review and response from our A&R Team.</p>
                <p style={{margin: 0}} className={'center-text'}>Once approved, we'll get your songs in front of tens of thousands of artists who are looking for original songs to record.</p>
                <p className={'center-text'}>Jam On!</p>
                <Form onSubmit={this.handleSubmitSongs}>
                    {[...Array(3)].map((value, index) => <Row key={index}>
                        <Col lg={{span: 4, offset: 3}} md={{span: 6, offset: 1}} sm={{span: 6, offset: 1}} xs={7}>
                            <Form.Group className={'profile-input-labels'}
                                        controlId="TrackA">
                                <Form.Control placeholder={'Song title'}
                                              onChange={(e) => this.applicantFormHandler(e, index)}
                                              value={this.state.Songs[index].Title}
                                              required={true}
                                              name={'Title'}
                                              />
                            </Form.Group>
                            <p className={'error-message'}>{this.state.errors[index]}</p>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={5}>
                            <Form.File style={{display: 'none'}} id={"track" + index}>
                                <Form.Control name={'Song'} type={'file'}  accept="audio/mp3"
                                              onChange={(e) => {
                                                  if (e.target.files[0] && !e.target.files[0].name.match(/\.(mp3)$/)) {
                                                      this.setState({
                                                          errors: {
                                                              ...this.state.errors,
                                                              [index]: 'Please Select only mp3 file'
                                                          }
                                                      })
                                                  } else {
                                                      delete this.state.errors[index]
                                                      this.setState({
                                                          errors: {
                                                              ...this.state.errors,
                                                          },
                                                          Songs: {
                                                              ...this.state.Songs,
                                                              [index]: {
                                                                  ...this.state.Songs[index],
                                                                  Title:e.target.files[0].name.split('.mp3')[0],
                                                                  Song: e.target.files[0],
                                                              },
                                                          },
                                                      })
                                                  }
                                              }}/>
                                <Form.File.Input/>
                            </Form.File>
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={<Tooltip id={`tooltip-bottom`}>only MP3</Tooltip>}>
                            <Button className={'fullsizedOrangeBtn smallOrangebtn'}
                                    onClick={() => document.getElementById('track' + index).click()}>SELECT</Button>
                            </OverlayTrigger>

                        </Col>
                    </Row>)}
                    <Row>
                        <Col lg={{span: 6, offset: 3}} md={{span: 10, offset: 2}} sm={{span: 10, offset: 1}} xs={12}>
                            <Form.Group className={'checkbox-right-to-submit'} controlId="formBasicCheckbox">
                                <Form.Check className={this.state.warnCheckbox&&'warn-checkbox'} onChange={(e) => this.setState({checked: this.state.checked + 1})}
                                            type="checkbox" label="I have the rights to submit these songs."/>
                            </Form.Group>
                        </Col>
                        <Col style={{textAlign: 'center'}} lg={{span: 6, offset: 3}} md={{span: 10, offset: 1}}
                             sm={{span: 10, offset: 1}} xs={12}>
                            <Button style={{float: 'none', width: '100%', marginTop:'30px'}} type={'submit'}
                                    className={'modal-save-button'}>{this.state.loading ? <div className={'spinner-with-loading'}><Spinner animation="border"/><span>Please Wait...</span></div> : 'SUBMIT TRACKS'}</Button>
                        </Col>

                    </Row>
                </Form>

            </Container>
        )
    }
}

export default connect(state => ({
        state
    }),
    dispatch => ({
        onUpdateProfile: (userData) => {
            dispatch({
                type: "UPDATE_PROFILE",
                payload: userData
            })
        }
    }))(ProviderConfirmation);
