import React, {Component} from "react";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import {Col, Container, Row} from "react-bootstrap";
import './ErrorPage.sass';

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        let message = null;
        if(props.history.location.search.includes('?message=')){
            message = decodeURIComponent(props.history.location.search.split('message=')[1])
        }
        this.state = {
            message
        }

    }

    componentDidMount() {
        document.title = "500 Error"
    }

    render() {
        return (<div style={{display:'flex',flexDirection:'column',height:'100%'}}>
                <Header />
                <div className={'error-page pattern-bg'} style={{flex:'1 1 auto',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                    <section style={{margin:0}} className={'contact-section error-page'}>
                        <Container>
                            <Row>
                                <Col lg={12} md={12}>

                                    <h5 className="title">Something Went wrong</h5>
                                    {this.state.message?  <h4 className="subtitle" style={{marginBottom:40}}>{this.state.message} </h4>:null}

                                </Col>
                                    </Row>
                        </Container>
                    </section>
                </div>


                <Footer/>
            </div>
        )
    }
}

export default ErrorPage;
