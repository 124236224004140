import React, { Component } from "react";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import logo2 from "../../images/logo2.png"
import api from "../../api";
import axios from "axios";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false
        }
    }

    submitContact = (event) => {
        this.setState({ contactLoading: true })
        const form = event.currentTarget;
        event.preventDefault();
        const data = JSON.stringify({
            Name: form['Name'].value,
            Email: form['Email'].value,
            Message: form['Message'].value,
        })
        axios.request({
            url: api.Contact.url,
            method: api.Contact.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        }).then(resp => {
            if (resp.status === 200) {
                document.getElementById("contact-us-form").reset();
                this.setState({ modalVisible: true, contactLoading: false })
            }
        })
    }

    render() {
        return (
            <section className={'contact-us-container'}>
                <hr style={{top:'-62px'}} className={'white-wave'}/>
                <div className={'background background-1'} />
                <div className={'background background-2'} />
                <Container>
                    <Row className={'contact-us-row'}>
                        <Col className={'centerable'} lg={6} xs={12}>
                            <div className={'contact-ureset-buttons-text'}>
                                <div className={'rocket-logo'}>
                                    <img alt={'Rocket_songs'} src={logo2} />
                                </div>
                                <div className={'home-title'}>
                                    Need to get in touch
                                    with Rocket Songs?
                                </div>

                            </div>

                        </Col>
                        <Col className={'centerable'} lg={6} xs={12}>
                            <div className={'contact-us-card '}>
                                <Form id={'contact-us-form'} onSubmit={this.submitContact}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group controlId="Name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control name={'Name'} type="text" placeholder="NAME" required />
                                            </Form.Group>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group controlId="Email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control name={'Email'} type="email" placeholder="EMAIL"
                                                    required />
                                            </Form.Group>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group controlId="Message">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control name={'Message'} rows="7" as="textarea"
                                                    type="textarea" placeholder="MESSAGE" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <button className={'simple-orange-button'} type="submit">
                                                {this.state.contactLoading ? <Spinner animation="border" /> : 'Contact Us'}
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>

                    </Row>
                    <Modal className={'contact-us'} show={this.state.modalVisible} onHide={() => this.setState({ modalVisible: false })}>
                        <Modal.Body className={'contact-modal-body'}>
                            <Row>
                                <Col lg={{ span: 2, offset: 10 }} xs={{ span: 3, offset: 9 }}>
                                    <button onClick={() => this.setState({ modalVisible: false })} className={'btn modal-close'}>x</button>
                                </Col>
                                <Col className={'center-text'} lg={12}>
                                    <img src={logo2} alt={''} />
                                </Col>
                                <Col className={'center-text'} lg={12}>
                                    <h1>Thanks for your submission!</h1>
                                </Col>
                                <Col lg={12}>
                                    <h4>We'll get back to you as soon as possible</h4>
                                </Col>
                                <Col lg={12}>
                                    <span>
                                        -The Rocket Songs Team
            </span>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Container>
                <hr style={{ bottom: '-18px' }} className={'black-wave'} />
            </section>

        )
    }
}

export default ContactForm;
