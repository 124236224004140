import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Fragments/Footer";
import Header from "../Fragments/Header";
import {Link} from "react-router-dom";

class LicenseAgreement extends Component {
    componentDidMount() {
        document.title = "The Rocket Songs License Agreements"
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={"license-agreement-page"}>
                <Header title={"The	Rocket	Songs	License	Agreement"} />
                <section className="licenses-content legal-content" style={{position:'relative'}}>
                    <hr style={{top:'-114px'}} className={'white-wave'}/>
                    <Container>
                        <Row>
                            <Col lg={12} md={12}>

                                <p className="highlight"><strong>We want to make this part really easy for you, so in a
                                    nutshell, here's what you can do with the original songs and tracks from Rocket
                                    Songs. You can:</strong></p>

                                <ul>
                                    <li>Record and promote your rendition of our songs through your own website, or
                                        Twitter, Facebook, and YouTube - <span className="text-weight">basically share it any way you want.</span>
                                    </li>
                                    <br/>
                                    <li>Release and sell, stream, and share your rendition of the songs anywhere from
                                        iTunes to Spotify to YouTube, your website, or a digital distribution platform
                                        of your choice - <span className="text-weight">basically sell it any way you want.</span>
                                    </li>
                                </ul>
                                <p>Just follow the rules described below so you don’t tee-off the copyright owners.</p>

                                <p className="highlight"><strong>Now, in order to keep the songwriters and publishers of
                                    the songs happy, and to keep our attorney from feeling guilty about the crazy amount
                                    of money he charges us, we need to include the legal interpretation of the License
                                    Agreement, so here it is:</strong></p>

                                <p>This is <strong>The Rocket Songs License Agreement</strong>, which details the rights
                                    you have to the original songs you licensed and received from RocketSongs.com. It’s
                                    a binding legal agreement between the Licensee and Radar Management Group LLC, the
                                    company that owns and does business as Rocket Songs. When you agree to these terms
                                    and conditions
                                    before checkout, the Licensee is agreeing to the entire License Agreement.</p>

                                <p>This <strong>Rocket Songs License Agreement</strong> is made between the purchaser,
                                    hereafter referred to as the "LICENSEE" (“You”), and, Radar Management Group LLC,
                                    DBA Rocket Songs, hereafter referred to as the "LICENSOR"(“Us), in regards to the
                                    music files hereafter referred to as the "TRACKS".</p>

                                <p>All parties are legally bound by the terms and conditions contained herein.</p>
                                <br/>
                                <h3 className="title">PURPOSE</h3>

                                <p>Radar Management Group LLC, DBA Rocket Songs (“Licensor”) is authorized to grant
                                    certain rights in and to the sound recording ("Master") and the underlying musical
                                    composition, collectively known as "Tracks". The Licensee (“You”) seeks to license
                                    the Songs & Tracks that are located on the RocketSongs.com website.</p>
                                <br/>
                                <h3 className="title">GUARANTEE</h3>

                                <p>Rocket Songs guarantees that it controls the rights to represent the original song
                                    recordings and the musical compositions in each of the TRACKS, and has and will hold
                                    throughout the TERRITORY the listed rights to exploit the TRACKS as contemplated
                                    herein.</p>

                                <p>LICENSEE shall indemnify and hold the LICENSOR harmless from any and all claims,
                                    liabilities and costs, losses, damages or expenses (including attorney's fees)
                                    arising out of any breach or failure of any covenants or warranties made by the
                                    LICENSEE herein.</p>
                                <br/>
                                <h3 className="title">REPRESENTED TRACKS LICENSE GRANTED</h3>

                                <p>The mechanical, synchronization, and performance rights granted to the LICENSEE
                                    within the AGREEMENT include:</p>

                                <p>(1) The right to record, re-record, and exploit your version of any of our songs for
                                    promotional purposes, such as through Twitter, Facebook, and YouTube.</p>

                                <p>(2) The right to record, re-record, and exploit your version of any of our songs to
                                    release and sell through a Digital Distribution platform of your choice.</p>

                                <p><strong>Any further uses of a commercial or non-commercial nature outside of the
                                    above permitted uses are subject to approval and licensing by the relevant copyright
                                    owners.</strong></p>
                                <br/>
                                <h3 className="title">DIGITAL AND PHYSICAL DISTRIBUTION LICENSE GRANTED</h3>

                                <p>You, the Licensee, are granted the right to Digitally and/or Physically Distribute
                                    (sell) your version of the licensed song, herein referred to as New Recorded Master,
                                    through any digital or physical distribution platform, including your website or
                                    third-party sales.</p>

                                <p>Rocket Songs acts as an administrator for the songs in our catalog, and therefore is
                                    responsible for collecting all royalties owned on behalf of the Writers, Publishers,
                                    Producers, and Content Partners of the songs.</p>

                                <p>You, the Licensee, shall account to and pay all royalties owned on behalf of the
                                    Writers, Publishers, and Producers and from any and all sales of the song(s) as
                                    follows:</p>
                                <ol>
                                    <li> Semi-annually, beginning January 1, within fifteen (15) days after the end of
                                        each six (6) month period.
                                    </li>
                                    <br/>
                                    <li> With your payment, provide an account of the revenue you received for each
                                        song, including revenue received from Direct and Third-Party Distribution.
                                    </li>
                                </ol>
                                <p>1. When you release (sell) your version of the licensed song, and
                                    <strong className="highlight"> use the mixed instrumental track or the STEM
                                        files</strong> provided to you by Rocket Songs in your version of the licensed
                                    song, through any type of digital or physical distribution platform or store you are
                                    required to:</p>

                                <ol type="a">
                                    <li> Prior to distribution, request the copyright information for the song from us
                                        at <Link to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link>.
                                    </li>
                                    <br/>
                                    <li> Credit the Songwriter(s), Publisher(s), and the original Producer of the song
                                        on your release by including this information when you upload your video or
                                        audio to your website, YouTube, Soundcloud, iTunes, Spotify, or any other
                                        streaming or distribution platform you may use.
                                    </li>
                                    <br/>
                                    <li> Pay 30% royalty of such income received to Rocket Songs for administration of
                                        the royalties to the Songwriters(s), Publisher(s), and the original Producer of
                                        the song.
                                    </li>
                                </ol>

                                <p>2. When you release (sell) your version of the licensed song, and <strong
                                    className="highlight">do not use the mixed instrumental track or the STEM
                                    files</strong> provided to you by Rocket Songs in your version of the licensed song,
                                    through any type of digital or physical distribution platform or store you are
                                    required to:</p>

                                <ol type="a">
                                    <li>Prior to distribution, request the copyright information for the song from us
                                        at <Link to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link>.
                                    </li>
                                    <br/>

                                    <li>Credit the Songwriter(s) and Publisher(s) of the song on your release by
                                        including this information when you upload your video or audio to your website,
                                        YouTube, Soundcloud, iTunes, Spotify, or any other streaming or distribution
                                        platform you may use.
                                    </li>
                                    <br/>

                                    <li>Pay 20% royalty of such income received to RocketSongs for administration of the
                                        royalties to the Songwriters(s), and Publisher(s) of the song.
                                    </li>
                                </ol>
                                <br/>
                                <h3 className="title">STREAMING LICENSE GRANTED</h3>

                                <p>You, the Licensee, are granted the right to stream your version of the licensed song,
                                    herein referred to as New Recorded Master, through any authorized streaming service
                                    platform.</p>

                                <p>In regards to income you receive, as the artist or label, from streaming royalties
                                    earned by your rendition of the song:</p>

                                <p>1. If you <strong className="highlight">use the mixed instrumental track or the STEM
                                    files</strong> you obtained from Rocket Songs for your rendition of the song, you
                                    are obligated to pay:</p>

                                <ol>
                                    <li type="a"> 20% royalty of such income received to Rocket Songs.</li>
                                </ol>

                                <p>2. In the event you are re–recording the song from scratch or you <strong
                                    className="highlight">do not use the mixed instrumental track or the STEM
                                    files</strong> offered through Rocket Songs for your rendition of the song, you are
                                    obligated to pay:</p>

                                <ol>
                                    <li type="a"> 10% royalty of such income received to Rocket Songs.</li>
                                </ol>

                                <p><strong>All Royalties due from sales and streams of your renditions are payable to
                                    Rocket Songs through PayPal at:</strong> <Link
                                    to="mailto:royalties@rocketsongs.com">royalties@rocketsongs.com</Link></p>
                                <p><strong>Radar Management Group LLC, DBA Rocket Songs</strong></p>

                                <p>If you have any questions, please contact us as at <Link
                                    to="mailto:info@rocketsongs.com">info@rocketsongs.com</Link></p>
                                <br/>
                                <h3 className="title">RIGHTS NOT INCLUDED IN THIS AGREEMENT</h3>

                                <p>The rights granted to the LICENSEE do not permit the LICENSEE to (1) claim any
                                    ownership or authorship of the music represented under this AGREEMENT; (2) re-write
                                    the lyrics or melody of the music represented under this AGREEMENT; (3) transfer,
                                    share or sub-lease this license agreement with any other party; (4) permit any other
                                    individual or third

                                    party the right to use the REPRESENTED TRACKS in place of the LICENSEE; (5) resell,
                                    trade, or exploit for profit the REPRESENTED TRACKS contained herein outright or as
                                    part of other music and/or audio-related collections, in part or in whole, to any
                                    other individual or party; (6) grant synchronization rights to the Licensed song, in
                                    part or in whole, to any other 3rd party without prior approval from Radar
                                    Management Group, DBA Rocket Songs, and the Content Partner of the Licensed song;
                                    (7) grant any 3rd party record label release of the Licensed song without prior
                                    approval from Radar management Group, DBA Rocket Songs, and the Content Partner of
                                    the Licensed song, when using the underlying musical composition, collectively known
                                    as the "Tracks".</p>

                                <p>The LICENSOR maintains all intellectual property rights with regard to the marketing
                                    and sales of all tracks and any infringement thereof is punishable by law.</p>
                                <br/>
                                <h3 className="title">Limited Use of Tracks and Materials</h3>

                                <p>Licensee shall not use, exploit, or in any way attempt to obtain any benefit there
                                    from the Songs & Tracks except in strict accordance with the terms of this
                                    Agreement.</p>
                                <br/>
                                <h3 className="title">Territory</h3>

                                <p>The territory of this contract is the world.</p>
                                <br/>
                                <h3 className="title">Reservation of Rights</h3>

                                <p>As between us, Radar Management Group LLC, DBA Rocket Songs owns and retains all such
                                    rights to the Content and Services not expressly granted to you, the Licensee, in
                                    this agreement.</p>
                                <br/>
                                <h3 className="title">COPYRIGHT NOTICES</h3>

                                <p>Licensee shall abide by all copyright notices, information, and restrictions
                                    applicable to any Songs & Tracks and the Materials or otherwise published by the
                                    Licensor.</p>
                                <br/>
                                <h3 className="title">PERFORMANCE RIGHTS</h3>

                                <p>The Licensee will agree to any additional payments to the Songwriters and Producers
                                    of the Songs & Tracks for any performance rights as negotiated according to terms of
                                    any performance rights society (ASCAP, BMI, SESAC, etc.) that might request such
                                    payment.</p>
                                <br/>
                                <h3 className="title">EXCLUSIVITY GRANTED</h3>

                                <p>With the purchase of the <strong>Rocket Songs Exclusive Use
                                    Option</strong> (“Exclusive Use Option”) for a song, the song will become
                                    unavailable for licensing on <Link
                                        to="www.rocketsongs.com">RocketSongs.com</Link> for the term length you select,
                                    and no other member or visitor to <Link
                                        to="www.rocketsongs.com">RocketSongs.com</Link> will be able to purchase a
                                    license for the song until the <strong>Exclusive Use Option</strong> terminates.</p>

                                <p>The exclusive period takes effect immediately upon purchase of the <strong>Exclusive
                                    Use Option</strong> (Effective Date).</p>

                                <p>Any member who purchases an <strong>Exclusive Use Option</strong> to a song can renew
                                    and extend the length of the exclusive period any time prior to its expiration.</p>

                                <p>The <strong>Exclusive Use Option</strong> will not prevent someone else who may have
                                    already purchased a <strong>License</strong> for the song, prior to the Effective
                                    Date of your <strong>Exclusive Use Option</strong> purchase, from releasing or
                                    sharing their version of the song. </p>

                                <br/>
                                <h3 className="title">ROYALTY FREE</h3>
                                <p>If you opt to go "Royalty Free”, you can keep all the royalties you make as the Artist on the song, and never have to pay any songwriter or publishing royalties to anyone. You’ll still need to credit the songwriters and publishers on your release, but any royalties you make you keep!</p>

                                <br/>
                                <p>The Royalty Fee option is currently available on a limited number of songs.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer/>
            </div>
        )
    }
}

export default LicenseAgreement;
