import React, {Component} from "react";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import logo from "../../images/logo.png"
import AuthBar from "./AuthBar";
import Dropdown from "./Dropdown";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import '../../styles/sass/header.sass';
import Player from "./Player/Player";
import ToastComp from "./ToastComp/ToastComp";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";

class Header extends Component {
    constructor(props) {
        super(props);
        this.navbar = React.createRef();
        this.header = React.createRef();
    }
    headerMoveDown=()=>{
        this.header.current.classList.toggle('dropdown-open');
    }
    navOpen = () => {
        this.navbar.current.handleClick();
    };
    goToRegistration = (type) => {
        this.navbar.current.goToRegistration(type);
    };
    render() {
        return (
            <section ref={this.header} className="hero-parallax2 ">
                <div  className="w-nav navbar">
                    <div className="w-container">
                        <Row>
                            <Col lg={4} md={3} sm={3} xs={3} style={{justifyContent:'center',display:'flex'}}>
                                <Link to="/" className="w-nav-brand brand-logo"><img alt="" src={logo} className="logo"/></Link><br/>
                            </Col>
                            <Col lg={{span: 7,order:1,offset:1}} md={{span: 9,order:1}} sm={{span: 9,order:1}} xs={{span: 9,order:1}} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <Breadcrumb className={'breadcrumb-container'}>
                                    <Breadcrumb.Item className={this.props.active==='HIW'?'active':null} href="/HowItWorksArtist">How it works</Breadcrumb.Item>
                                    <Breadcrumb.Item className={this.props.active==='FAS'?'active':null} href="/shop">Find a Song</Breadcrumb.Item>
                                    <Breadcrumb.Item className={this.props.active==='S'?'active':null} href="/stems">STEMS</Breadcrumb.Item>
                                    <Breadcrumb.Item className={this.props.active==='NS'?'active':null} href="/newsongs">New Songs</Breadcrumb.Item>
                                </Breadcrumb>
                                {this.props.state.auth.user_id?<div onClick={this.navOpen} className={'logged-in-icon-container login-register-button'}>
                                    <div className={'logged-in-i'}/>
                                </div>:<div onClick={this.navOpen} className={'login-register-button'}>
                                    <span id="login-legend">Login / Register</span>
                                </div>}
                                <Dropdown active={this.props.active} navOpen={this.navOpen} openFucntion={this.headerMoveDown}/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="hero-content hero-content-container v2">
                    <Container >
                        {!this.props.children?<Row>
                           <Col lg={12} md={12} sm={12} xs={12}>
                                <h1 className="title">{this.props.title}</h1>
                                <h2 className="header-subtitle">{this.props.subtitle}</h2>
                            </Col>
                        </Row>:this.props.children}
                    </Container>
                </div>
                <AuthBar ref={this.navbar} open={this.props.opennav?this.props.opennav:this.state} />
                <Player/>
                <SubscriptionModal/>
                <ToastComp/>
            </section>
        )
    }

}

export default connect(
    state => ({
        state
    }),
    dispatch => ({

    }), null, {forwardRef: true})(Header);
