import React, {Component} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";


class WaitingConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            musLink: {}
        }
        this.player = React.createRef();
    }

    render() {
        return (
            <Container className={'prof-section-container'}>
                <p className={'center-text'}>Thank you for your songs!</p>
                <p className={'center-text'}>As you know, all the songs, songwriters, publishers, and producers on our site are vetted and curated by our A&R Team.</p>
                <p className={'center-text'}>And that process is underway with your songs.</p>
                {this.props.applicantSongs.map(song=><Row key={song.applicantSongId} className={"justify-content-center"}>
                    <Col lg={6} md={8} sm={12} xs={12}>
                    <div  className={'song-col-row-container'}>

                        <Card className={'single-song-card'}>
                            <Card.Body>
                                <div className={'single-song-list'}>
                                    <div className={'play-button-col'} style={{flex:'0 1 auto',marginRight:'10px',border:'none',height:48}} id={song.songId} onClick={(e) => {
                                        if(e.currentTarget.classList.contains('current-playing')){
                                            e.currentTarget.classList.remove('current-playing');
                                            this.props.Stop();
                                            return;
                                        }else if (document.getElementsByClassName('current-playing')[0]) {
                                            document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                        }
                                        e.currentTarget.classList.add('current-playing')
                                        song.vocals =  song.trackLink
                                        this.props.Play(song)

                                    }}/>
                                    <div style={{border:'none',height:48,flex:'1 1 auto'}} className={'title-col'}>
                                        <div>{song.title}</div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                </Row>)}

            </Container>
        )
    }
}

export default connect( state => ({
        state
    }),
    dispatch => ({
        Play: (music) => {
            dispatch({
                type: "PLAY",
                payload: music
            })
        },
        Stop: () => {
            dispatch({
                type: "STOP",
            })
        },
    }))(WaitingConfirmation);
