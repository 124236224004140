import React, {Component} from "react";
import FergieImg from "../../../../images/fergie.jpg"
import CarrieImg from "../../../../images/Carrie.jpg"
import AeroImg from "../../../../images/aerosmith.jpg"
import BrunoImg from "../../../../images/bruno.jpg"
import KingOfImg from "../../../../images/kingsof.jpg"
import DoubleImg from "../../../../images/double.jpg"
import EminemImg from "../../../../images/eminem.jpg"
import AguileraImg from "../../../../images/aguilera.jpg"
import FranklinImg from "../../../../images/franklin.jpg"
import RascalImg from "../../../../images/rascal_flatts.jpg"
import RihannaImg from "../../../../images/rihanna.jpg"
import WinehouseImg from "../../../../images/winehouse.jpg"
import {Link} from "react-router-dom";

class Actors extends Component {
    constructor(props) {
        super(props);
        this.state={
            scrollWidth:0
        }
        this.actorsSlider = React.createRef()
    }
    componentDidMount() {
        if(this.actorsSlider.current){
            let scrollSize =this.actorsSlider.current.scrollWidth - document.body.clientWidth
            this.setState({
                scrollWidth:scrollSize
            })
            this.Animation(parseInt(this.actorsSlider.current.style.transform.replace(/^\D+/g, '').split('px')[0]))
        }
    }
    Animation =(length)=>{
        setTimeout(()=>{
            if(this.actorsSlider.current){
                let scrollSize =this.actorsSlider.current.scrollWidth - document.body.clientWidth
                let scrollWidth = length?0:scrollSize
                this.setState({
                    scrollWidth
                })
                this.Animation(parseInt(this.actorsSlider.current.style.transform.replace(/^\D+/g, '').split('px')[0]))
            }

        },10000)
    }


    render() {
        const Artists = [
            {
                image:AguileraImg,
                name:'Christina Aguilera',
                link: '/TrackOwner/325c8f4f-b20a-4578-ae35-a63500103547'
            },
            {
                image:FranklinImg,
                name:'Aretha Franklin',
                link: '/TrackOwner/53f548db-51ec-4e1c-970e-a7e20065dc4e'
            },
            {
                image:RascalImg,
                name:'Rascal Flatts',
                link: '/TrackOwner/70f36384-1685-4a1b-bc61-a31300e76488'
            },
            {
                image:RihannaImg,
                name:'Rihanna',
                link: '/TrackOwner/325c8f4f-b20a-4578-ae35-a63500103547'
            },
            {
                image:WinehouseImg,
                name:'Amy Winehouse',
                link: '/TrackOwner/53f548db-51ec-4e1c-970e-a7e20065dc4e'
            },
            {
                image:FergieImg,
                name:'Fergie',
                link: '/TrackOwner/0285870b-e868-4658-81e9-c6beda2271d0'
            },
            {
                image:CarrieImg,
                name:'Carrie Underwood',
                link: '/TrackOwner/9ba4bb20-84a0-4b2c-9d4b-a31300e7642c'
            },
            {
                image:AeroImg,
                name:'Aerosmith',
                link: '/TrackOwner/faee2330-8022-4397-9c99-a31300e765d5'
            },
            {
                image:BrunoImg,
                name:'Bruno Mars',
                link: '/TrackOwner/5c5ddb38-7f1e-4440-b400-7b80415a7529'
            },
            {
                image:KingOfImg,
                name:'Kings of Leon',
                link: '/TrackOwner/9ba4bb20-84a0-4b2c-9d4b-a31300e7642c'
            },
            {
                image:DoubleImg,
                name:'Duble Sidekick',
                link: '/TrackOwner/5c5ddb38-7f1e-4440-b400-7b80415a7529'
            },
            {
                image:EminemImg,
                name:'Eminem',
                link: '/TrackOwner/0285870b-e868-4658-81e9-c6beda2271d0'
            },



        ]
        return (
                <section className={'produced-for-section'}>
                    <hr style={{top:'-95px'}} className={'white-wave'}/>
                    <div className={'produced-title'}>
                        Our Producers and Songwriters
                        have written for:
                    </div>
                    <div ref={this.actorsSlider} style={{transform:`translateX(-${this.state.scrollWidth}px)`}} className={'written-for-arts'}>
                        {Artists.map((artist,key)=>  <Link to={artist.link} key={key} className={'single-artist'}>
                            <img alt={artist.name} src={artist.image}/>
                            <div>{artist.name}</div>
                        </Link>)}

                    </div>
                </section>

        )
    }
}

export default Actors;
