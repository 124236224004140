import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import api from "../../../api";
import axios from "axios";
import Preloader from "../../Fragments/Preloader";

class SubscriptionThanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading:true,
            error:false,
        }
    }
    componentDidMount() {
        const params = {};
        if(this.props.location.search){
            const urlParams = new URLSearchParams(this.props.location.search);
            for(const p of urlParams){
                if(params[p[0]]){
                    params[p[0]].push(p[1])
                }else{
                    params[p[0]] = p[1]
                }
            }
        }
        if(params.subscription_id){
            axios.request( {
                url:api.VendorSubscription.url+params.subscription_id,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                method: "PUT",
            }).then(response=>response.data).then(response=>{
                this.setState({
                    error:false,
                    pageLoading:false
                })
            }).catch(err=>{
                this.setState({
                    error:true,
                    pageLoading:false
                })
            })
        }else{
            this.setState({
                error:true,
                pageLoading:false
            })
        }
    }
    render() {
        return (<div>
                <Header title={!this.state.error?'Thank You For Subscribing!':'Error'}/>
                <Preloader display={this.state.pageLoading ? 'block' : 'none'}/>
                <Container style={{minHeight: '400px'}}>
                    {!this.state.error? <Row >
                        <Col className={'order-number'} lg={12}>You have successfully subscribed <br/>
                            <Link to={'/Profile'}>View My Profile</Link>
                        </Col>
                    </Row>:<Row>
                        <Col>
                            <Col className={'order-number'} lg={12}>
                                <Col className={'order-number'} lg={12}>
                                    <strong>Error something went wrong.</strong><br/>
                                    <Link to={'/VendorSubscription'}>Try Again</Link>
                                </Col>
                            </Col>
                        </Col>
                    </Row>}
                </Container>
                <Footer/>
            </div>
        )
    }
}

export default connect( state => ({
        state
    }),
    dispatch => ({

    }))(withRouter(SubscriptionThanks));
