export default function notification(state = {}, action){
    if(action.type === 'NOTIFICATE'){
        return {
            ...action.payload
        }
    }

    if(action.type === 'HIDE_NOTIFICATION') {
          return {

          }
    }

    if(action.type === 'LOGOUT'){
        return {}
    }
    return state;
}
