import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import axios from "axios";
import api from "../../api";
import Preloader from "./Preloader";
import Header from "./Header";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import Footer from "./Footer";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading:false,
            success:false,
            loading:false,
            loginMes:null
        }
    }
    componentDidMount() {
        this.props.onLogout();
        const urlParams = new URLSearchParams(this.props.location.search);
        let params = {}
        for(const p of urlParams){
            if(params[p[0]]){
                params[p[0]].push(p[1])
            }else{
                params[p[0]] = p[1]
            }
        }
        this.setState({
            ...params
        })

    }
    handleLoginSubmit = (event) => {
        let Password = event.target[0].value;
        let PasswordConfirm = event.target[1].value;
        let Token = this.props.match.params.token;
        let data = {Token, NewPassword:{Password, PasswordConfirm}}
        event.preventDefault();
        if (event.target.checkValidity() === true) {
            this.setState({loading: true})
            axios.request( {
                url: api.ResetPassword.url,
                method: api.ResetPassword.method,
                data: data
            }).then(response => response.data).then(response => {
                    this.setState({
                        success:true
                    })
            }).catch(err=>{
                if(err.response.data.Message){
                    this.setState({
                        loginMes: err.response.data.Message,
                        loading:false
                    })
                }

            })
        }

    }
    render() {
        return (
        <div>
            <Header title={'Password Reset'}/>
            <Preloader display={this.state.pageLoading ? 'block' : 'none'}/>
            <Container className={'sidebar-content'} style={{minHeight: '400px'}}>
                <Row style={{margin:'90px'}}>
                    <Col lg={{span:6,offset:3}} md={{span:8,offset:2}} sm={{span:10,offset:1}} xs={12}>
                        {this.state.success?<h1 style={{textAlign:'center'}} className={'title'}>Your password has been changed successfully!</h1>:<h1 style={{textAlign:'center'}} className={'title'}>Reset Your Password</h1>}
                    </Col>
                    {!this.state.success&&<Col lg={{span:6,offset:3}} md={{span:8,offset:2}} sm={{span:10,offset:1}} xs={12}>
                        <Form onSubmit={this.handleLoginSubmit}>
                            <Form.Group controlId="email">
                                <Form.Control value={this.state.username} type="password" name={'password'}
                                              placeholder="New password"/>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Control type="password" name={'PasswordConfirm'}
                                              placeholder="Confirm password"/>
                            </Form.Group>
                            <Alert variant={'danger'}
                                   style={{display: this.state.loginMes ? 'block' : 'none'}}>{this.state.loginMes}</Alert>
                            <Button type="submit" className={"btn login"}
                                    disabled={this.state.loading}>{this.state.loading ?
                                <Spinner animation="border"/> : 'RESET PASSWORD'}</Button>
                        </Form>
                    </Col>}
                </Row>

            </Container>
            <Footer/>
        </div>
    )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onLogin: (token) => {
            dispatch({
                type: "LOGIN",
                payload: token
            })
        },
        onLogout: () => {
            dispatch({
                type: "LOGOUT"
            })
        },
        onCartFetch: (cart) => {
            dispatch({
                type: "FETCH_CART",
                payload: cart
            })
        },
    }))(withRouter(ResetPassword));
