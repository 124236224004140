import React, {Component} from "react";
import Header from "../../Fragments/Header";
import Footer from "../../Fragments/Footer";
import {withRouter} from "react-router-dom";
import Preloader from "../../Fragments/Preloader";
import './HowItWorks.sass'
import {Col, Container, Row} from "react-bootstrap";
import MetaTags from 'react-meta-tags';
import albom1 from '../../../images/albom1.jpg'
import albom2 from '../../../images/albom2.jpg'
import albom3 from '../../../images/albom3.jpg'
import albom4 from '../../../images/albom4.png'
import albom5 from '../../../images/albom5.png'
import albom6 from '../../../images/albom6.png'
import albom7 from '../../../images/albom7.png'




class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
        this.header = React.createRef()
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div className={'hiw-page'}>
                <MetaTags>
                    <title>How It Works - Rocket Songs</title>
                    <meta name="description" content="Rocket songs provide a license for download songs lyrics. Get license from Rocket songs, then Search your favorite song lyrics and download it." />
                </MetaTags>
                <Preloader display={this.state.pageLoading?'block':'none'}/>
                <Header active={'HIW'} ref={this.header} >
                    <div className={'header-title-child'}>We Developed <span>Rocket Songs</span> so that any artist
                        can get great original songs and tracks</div>
                    <div className={'header-subtitle-child'}>from some of the top music industry and independent songwriters, publishers, and producers working in music.</div>
                </Header>
                <section className={'artist-section'}>
                    <hr style={{top:'-62px'}} className={'white-wave'}/>
                    <div className={'border-bubble'}/>
                    <Container>

                        <div className={'sec-title'}>
                            Are you an Artist?
                        </div>
                        <Row>
                            <Col className={'images-sec no-paddings'} lg={6}>
                                <div className={'image-sec-container'}>
                                    <div className={'guitarist image-back'}/>
                                    <div className={'photogit image-back'}/>
                                    <div className={'yellow-div'}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className={'art-right-text'}>
                                    We’ve got thousands of songs for you to choose from, all vetted and curated by our A&R Team. Create your own version and advance your music career.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{bottom:'-62px'}} className={'white-wave down'}/>
                </section>
                <section className={'find-song-section'}>
                    <div className={'song-icon-borders'}>
                        <div className={'song-icon-container'}>
                            <div className={'song-icon'}/>
                        </div>
                    </div>
                    <div className={'blue-title'}>
                        Any song on our site can be
                        customized to make it work for you.
                    </div>
                    <div className={'content-text'}>
                        You can use the instrumental bed we provide, add more instruments and vocals, or re-record the song however you want, in whatever key you want, in whatever genre you want.
                    </div>
                    <div className={'disks-container'}>
                        <div className={'single-disk'}>
                            <img alt={'song album'} className={'disk-image'} src={albom1}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} className={'disk-image'} src={albom2}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} style={{zIndex:'5'}} className={'disk-image'} src={albom3}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} style={{zIndex:'4'}} className={'disk-image'} src={albom4}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} style={{zIndex:'3'}} className={'disk-image'} src={albom5}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} style={{zIndex:'2'}} className={'disk-image'} src={albom6}/>
                        </div>
                        <div className={'single-disk'}>
                            <img alt={'song album'} style={{zIndex:'1'}} className={'disk-image'} src={albom7}/>
                        </div>

                    </div>
                    <div className={'content-text'}>
                        You can even make simple changes to the melody and the lyrics to make it fit your style. We don’t want to limit your creativity in creating your own version of any song in our catalog, so feel free to jam out.
                    </div>
                    <div className={'find-s-b-cont'}>
                        <button onClick={()=>this.props.history.push('/shop')} className={'simple-orange-button'}>
                            Find your song now
                        </button>
                    </div>

                </section>
                <section className={'with-song'}>
                    <hr style={{top:'-62px'}} className={'white-wave '}/>
                    <div className={'border-bubble left'}/>
                    <Container  >

                        <div className={'sec-title'}>
                            It’s super easy to know what’s<br/>
                            available with a song:
                        </div>
                        <Row>
                            <Col lg={6}>
                                <div className={'item-card'}>
                                    <div className={'item-icon-wt'}>
                                        <div className={'item-ic-container'}>
                                            <div className={'song-icon'}/>
                                        </div>
                                        <div className={'hidden-title'}>SONG:</div>
                                    </div>
                                    <div className={'item-text'}><span>SONG:</span> Get a file of the song with all the vocals, and a copy of the lyrics.</div>
                                </div>
                                <div className={'item-card second'}>
                                    <div className={'item-icon-wt'}>
                                        <div className={'item-ic-container'}>
                                            <div className={'stem-icon'}/>
                                        </div>
                                        <div className={'hidden-title'}>STEMS:</div>
                                    </div>
                                    <div className={'item-text'}><span>STEMS:</span> Get all the individual multi-track audio files of the song. If you want complete control over every instrument, get this!</div>
                                </div>
                            </Col>
                            <Col className={'second-ws'} lg={6}>
                                <div className={'item-card'}>
                                    <div className={'item-icon-wt'}>
                                        <div className={'item-ic-container'}>
                                            <div className={'song-track-icon'}/>
                                        </div>
                                        <div className={'hidden-title'}>SONG + TRACK:</div>
                                    </div>
                                    <div className={'item-text'}><span>SONG + TRACK:</span> Get a file of the song with all the vocals, a file of the mixed instrumental backing tracks without the vocals, and a copy of the lyrics. </div>
                                </div>
                                <div className={'item-card second'}>
                                    <div className={'item-icon-wt'}>
                                        <div className={'item-ic-container exclusive'}>
                                            <div className={'exclusive-icon'}/>
                                        </div>
                                        <div className={'hidden-title'}>EXCLUSIVE:</div>
                                    </div>
                                    <div className={'item-text'}><span>EXCLUSIVE:</span> Takes the song off our site so that no one else can license it from us for the entire length of the exclusive term. </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{bottom:'-62px'}} className={'white-wave down'}/>
                </section>
                <section className={'license-allowing'}>
                    <Container>
                        <Row>
                            <Col  style={{padding:0}} lg={6}>
                                <div className={'title-container hiddenresp'}>
                                    <div className={'orange-par-title'}>The Rocket Songs </div>
                                    <div className={'allow-title'}>License allows you</div>
                                </div>
                                <div className={'license-allow-img-container'}>
                                    <div className={'big-background'}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className={'title-container'}>
                                    <div className={'orange-par-title'}>The Rocket Songs </div>
                                    <div className={'allow-title'}>License allows you</div>
                                </div>
                                <div className={'allow-desc'}>to record, perform, share, stream, and sell your version of the song anywhere from iTunes to Youtube to Spotify</div>
                                <div className={'allow-desc-child'}>You would own the recording or video you create as the “Artist” of the song, and can collect revenue and royalties for your version of the song as the “Artist” of the song. </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className={'royalties-section'}>
                    <hr style={{top:'-62px'}} className={'white-wave gray-wave'}/>
                    <Container>
                        <Row>
                            <Col lg={{span:6,order:'1'}}  xs={{span:12,order:'2'}}>
                                <div className={'royalties-text-container'}>
                                    <div className={'title-container'}>
                                        <div className={'orange-par-title'}>Learn about</div>
                                        <div className={'allow-title'}>Royalties</div>
                                    </div>
                                    <div className={'allow-desc'}>You can get all the details on how royalties are shared and paid from streaming and sales by clicking on the License Agreement link located at the bottom of every page on our site, and scrolling to the section called "Digital And Physical Distribution License Granted” and "Streaming License Granted”.</div>
                                    <div className={'allow-desc-child'}>The copyright of the song remains with the songwriters and publishers of the song. When your release is ready to go, let us know and we’ll give you the copyright info.</div>
                                </div>
                            </Col>
                            <Col lg={{span:6,order:'2'}}  xs={{span:12,order:'1'}} style={{marginTop:'30px'}}>
                                <div className={'title-container hiddenresp'}>
                                    <div className={'orange-par-title'}>Learn about</div>
                                    <div className={'allow-title'}>Royalties</div>
                                </div>
                                <div className={'roy-image-container'}>
                                    <div className={'roy-image-background'}/>
                                    <div className={'roy-image'}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className={'wait-container'}>
                    <div className={'border-bubble'}/>
                    <hr style={{top:'-62px'}} className={'white-wave'}/>
                    <Container >
                        <div className={'waiting-card'}>
                            <div className={'waiting-layout'}/>
                            <div className={'icon-container'}>
                                <div className={'contain-icon'}/>
                            </div>
                            <div className={'wait-text'}>
                                So what are you waiting for?
                            </div>
                            <button onClick={()=>this.props.history.push('/shop')}  className={'simple-orange-button'}>FIND YOUR SONG NOW</button>
                        </div>
                        <div className={'wait-title'}>Are you a songwriter, publisher or a producer?</div>
                        <div className={'wait-subtitle'}>Make your songs available<br/>
                            on our platform! </div>
                        <div className={'wait-button'}>
                            <button onClick={()=>this.props.history.push('/BecomeAContentPartner')} className={'simple-orange-button'}>Become a content partner</button>
                        </div>
                    </Container>
                </section>
                <Footer/>
            </div>
        )
    }
}

export default withRouter(HowItWorks);
