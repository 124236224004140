import moment from "moment";

export default function affiliate(state = {
    key:null,
    email:null,
    date:null
}, action){
    if(action.type === 'CREATE_AFFILIATE'){
        return {
            ...action.payload
        }
    }

    if(state.date && moment(state.date).diff(moment().subtract(30, 'days'))<0){
        return {

        }
    }
    return state;
}
