import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom"
import musicIsReadyPic from "../../../../images/we_got_stems.jpg"
import popBack from "../../../../images/pop.jpg"
import rapBack from "../../../../images/rap.jpg"
import americanaBack from "../../../../images/americana.jpg"
import hipBack from "../../../../images/hip_hop.jpg"
import rhyBack from "../../../../images/rhyBack.jpg"
import musicianBack from "../../../../images/country.jpg"
import danceBack from "../../../../images/emotional.jpg"
import guitarSS from "../../../../images/alternative.jpg"
import rockBack from "../../../../images/rock.jpg"
import ballGame from "../../../../images/ballgame.jpg"



class SecondSection extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }


    render() {

        return (
            <section className={'second-section-parent'}>
                <div className={'background background-1'}/>
                <div className={'background background-2'}/>
                <div className={'background background-3'}/>
                <Container className={'second-section'}>
                    <Row>
                        <Col className={'first-image'} style={{marginBottom:'80px'}}  lg={{span:6,order:"1"}}  xs={{span:12,order:"1"}}>
                            <div className={'definition-title hiddenresp'}>
                                It’s a whole new
                                ballgame!
                            </div>
                            <div className={'image-bub-container'}>
                                <div style={{backgroundImage:`url(${ballGame})`}} className={'image'} />
                                <div className={'bubble'}/>
                                <div className={'bubble-border'}/>
                            </div>
                        </Col>
                        <Col  className={'centerable first-text'}  lg={{span:6,order:"2"}}  xs={{span:12,order:"2"}}>
                            <div className={'text-definition'}>
                                <div className={'definition-title'}>
                                    It’s a whole new
                                    ballgame!
                                </div>
                                <div className={'definition-content'}>
                                    Having original songs is key to a successful music career. For the first time ever, you can get songs of every genre and tempo to make your own.  Whether you need just the song or the track, we’ve got it for you!
                                </div>
                                <div className={'definition-button'}>
                                    <button onClick={()=>this.props.history.push('/HowItWorksArtist')} className={'simple-orange-button'}>
                                        HOW IT WORKS
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col   className={'centerable second-text'}  lg={{span:6,order:"3"}} xs={{span:12,order:"4"}}>
                            <div className={'text-definition'}>
                                <div className={'definition-title'}>
                                    We’ve got STEMS!
                                </div>
                                <div className={'definition-content'}>
                                    Get the original multi-track master files from the producers of the song to plug right in to your home or studio DAW.
                                </div>
                                <div className={'definition-button'}>
                                    <button onClick={()=>this.props.history.push('/stems')} className={'simple-orange-button'}>
                                        Shop STEMS Now
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col className={'second-image'}     lg={{span:6,order:"4"}} xs={{span:12,order:"3"}}>
                            <div className={'definition-title hiddenresp'}>
                                We’ve got STEMS!
                            </div>
                            <div className={'image-bub-container'}>
                                <div style={{backgroundImage:`url(${musicIsReadyPic})`}} className={'image'} />
                                <div className={'bubble'}/>
                                <div className={'bubble-border'}/>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Row className={'genres-container'}>
                                <div className={'pickup-title hiddenresp'}>Pick your genre.<br/>
                                    Find your song.
                                </div>
                                <Col lg={4} md={4} xs={12}>
                                    <div style={{height:140,backgroundImage:'linear-gradient(130deg, #e85182 0%, #ee973c)'}} className={'single-genre not-clickable'}/>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Pop')} style={{height:282,backgroundImage:`url(${popBack})`}} className={'single-genre'}>
                                        <div>POP</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Rap')} style={{height:175,backgroundImage:`url(${rapBack})`}} className={'single-genre'}>
                                        <div>RAP</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Americana')} style={{height:163,backgroundImage:`url(${americanaBack})`}} className={'single-genre'}>
                                        <div>AMERICANA</div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4}xs={12}>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Hip Hop')}  style={{height:333,backgroundImage:`url(${hipBack})`}} className={'single-genre'}>
                                        <div>HIP HOP</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=R&B')}  style={{height:216,backgroundImage:`url(${rhyBack})`}} className={'single-genre'}>
                                        <div>RHYTHM & BLUES</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Country')}  style={{height:240,backgroundImage:`url(${musicianBack})`,backgroundPosition:'unset'}} className={'single-genre'}>
                                        <div>COUNTRY</div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} xs={12}>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Rock')} style={{height:216,backgroundImage:`url(${rockBack})`}} className={'single-genre'}>
                                        <div>ROCK</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Dance')} style={{height:313,backgroundImage:`url(${danceBack})`}} className={'single-genre'}>
                                        <div>DANCE</div>
                                    </div>
                                    <div onClick={()=>this.props.history.push('/shop?start=0&genres=Alternative')} style={{height:261,backgroundImage:`url(${guitarSS})`}} className={'single-genre'}>
                                        <div>ALTERNATIVE</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={'pick-your centerable'} lg={6}>
                            <div className={'pick-your-container'}>
                                <div className={'pickup-title'}>Pick your genre.<br/>
                              Find your song.
                                </div>
                                <div>
                                    <button  onClick={()=>this.props.history.push('/shop')} className={'simple-orange-button'}>
                                        Search our Library
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <hr style={{bottom:'-60px'}} className={'white-wave down'}/>
            </section>
        )
    }
}

export default withRouter(SecondSection);
