import React, {Component} from "react";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import {Container, Col, Row, Button} from "react-bootstrap";

import {connect} from "react-redux";
import MetaTags from "react-meta-tags";
import './ContentPartner.sass';
import Preloader from "../../Fragments/Preloader";
import {withRouter} from "react-router-dom";
import moment from "moment";

class ContentPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            affiliate:null
        }
        this.header = React.createRef();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const params = {};
        if(this.props.location.search){
            const urlParams = new URLSearchParams(this.props.location.search);
            for(const p of urlParams){
                params[p[0]] = p[1]
            }
        }
        if(params.token && params.name){
            this.setState({
                affiliate:params.name
            })
            this.props.onRegisterAffiliate({
                key:params.token,
                name:params.name,
                date:moment()
            })
        }
    }

    navOpen = () => {
        this.header.current.goToRegistration('Provider')
    };
    render() {
        const hasAffiliate = this.props.state?.auth?.user?.affilateName || this.props.state.affiliate?.name;
        return (<div className={'content-partner-page'}>
                <Preloader display={this.state.pageLoading ? 'block' : 'none'}/>
                <MetaTags>
                    <title>Become a Content Partner And Get Your Songs Cut! - Rocket Songs</title>
                </MetaTags>
                <Header ref={this.header}>
                    <div className={'header-text-container'}>
                        <div className={'header-title-child'} >
                            {this.state.affiliate?<>
                            <div style={{color:'#f64e2f'}}>Welcome</div>
                           <div >{this.state.affiliate+ ' User!'}</div>
                            </>  :null}
                        </div>
                        <div className={'header-title-child'}>
                            <div>Become a Content Partner</div>
                            <div>And Get Your <span>Songs Cut!</span></div>
                        </div>
                    </div>
                </Header>
                <section className={'subtitle-section'}>
                    <hr style={{top: '-54px'}} className={'white-wave gray-wave'}/>
                    <Container>
                        More than 90% of the songs on our site get licensed by  recording artists.
                    </Container>
                    <hr style={{bottom: '-54px'}} className={'white-wave gray-wave down'}/>
                </section>
                <section className={'have-songs-section'}>
                    <div className={'border-bubble'}/>
                    <Container>

                        <div className={'sec-title'}>
                            Do you have Songs?
                        </div>
                        <Row>
                            <Col lg={6} className={'images-sec no-paddings'}>
                                <div className={'image-sec-container'}>
                                    <div className={'singers-studio image-back'}/>
                                    <div className={'yellow-div-left'}/>
                                    <div className={'yellow-div-right'}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className={'text-container'}>
                                    <div className={'text-data'}>
                                        Our Marketplace has tens of thousands of artists who are looking
                                        for <span>original</span> songs to record.
                                    </div>
                                    <div className={'text-data'}>
                                        <span>Generate revenue</span> through license fees, sales, streams and
                                        publishing royalties.
                                    </div>
                                    <div className={'text-data'}>
                                        Keep all your <span>copyright ownership!</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{bottom: '-62px'}} className={'white-wave down'}/>
                </section>
                <section className={'what-get-section'}>
                    <div className={'sec-title'}>
                        What you get?
                    </div>
                    <div className={'get-cards-container'}>
                        <div className={'card-item'}>
                            <div className={'percent-borders'}>
                                <div className={'percent-container'}>50%</div>
                            </div>
                            <div className={'card-text'}>50% share of all <span>licensing revenue</span> generated on
                                your songs.
                            </div>
                        </div>
                        <div className={'card-item'}>
                            <div className={'percent-borders'}>
                                <div className={'percent-container'}>100%</div>
                            </div>
                            <div className={'card-text'}>100% share of the songwriter and <span>publisher performance royalties</span> by
                                a rendition of your song.
                            </div>
                        </div>
                        <div className={'card-item'}>
                            <div className={'percent-borders'}>
                                <div className={'percent-container'}>20%</div>
                            </div>
                            <div className={'card-text'}>20% share of <span>revenue</span> from sales, <span>streams, and downloads</span> by
                                a rendition of your song that uses your instrumental track.
                            </div>
                        </div>
                        <div className={'card-item'}>
                            <div className={'percent-borders'}>
                                <div className={'percent-container'}>10%</div>
                            </div>
                            <div className={'card-text'}><span>10% share of the revenue</span> generated through <span>sales, streams, and downloads</span> by
                                a rendition of your song that doesn’t use your instrumental track.
                            </div>
                        </div>
                        <div className={'card-item'}>
                            <div className={'percent-borders'}>
                                <div className={'percent-container'}>24/7</div>
                            </div>
                            <div className={'card-text'}><span>24/7 access</span> to manage your account, upload songs,
                                and view your sales.
                            </div>
                        </div>
                    </div>
                    <hr style={{bottom: '-54px'}} className={'white-wave gray-wave down'}/>
                </section>
                <section className={'cont-part-section'}>
                    <div className={'border-bubble left'}/>
                    <Container>
                        <div className={'sec-title'}>
                            Content Partner Program
                        </div>
                        <Row className={'requirements-container align-items-center justify-content-center'}>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'orange-dot mb-4 mr-3'}/>
                                    <div>
                                        <div className={'req-title'}>50 SONGS OR FEWER</div>
                                        <div className={'req-subtitle green '+(hasAffiliate?'discounted-month':'')}>first <span className={hasAffiliate?'discounted-number':''}>3
                                            {hasAffiliate&&<span>6</span>}</span> months free</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'dot-placer mb-4 mr-3'}/>
                                    <div>
                                        <span className={'price-container'}>$ 4.95</span><span
                                        className={'sub-price-container'}>/ billed monthly</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'orange-dot mb-4 mr-3'}/>
                                    <div>
                                        <div className={'req-title'}>50 SONGS OR FEWER</div>
                                        <div className={'req-subtitle'}>$49.95 per year after</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'dot-placer mb-4 mr-3'}/>
                                    <div className={hasAffiliate?'discounted-price':''}>
                                        {hasAffiliate&&<span className={'new-price'}>$ 20.00</span>}
                                        <span className={'price-container'}>$ 30.00</span><span
                                        className={'sub-price-container'}>/ Billed Yearly</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className={'white-line'}/>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'orange-dot mb-4 mr-3'}/>
                                    <div>
                                        <div className={'req-title'}>UNLIMITED SONGS</div>
                                        <div className={'req-subtitle green '+(hasAffiliate?'discounted-month':'')}>first <span className={hasAffiliate?'discounted-number':''}>3
                                            {hasAffiliate&&<span>6</span>}</span> months free</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'dot-placer mb-4 mr-3'}/>
                                    <div>
                                        <span className={'price-container'}>$ 7.95</span><span
                                        className={'sub-price-container'}>/ billed monthly</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'orange-dot mb-4 mr-3'}/>
                                    <div>
                                        <div className={'req-title'}>UNLIMITED SONGS</div>
                                        <div className={'req-subtitle'}>$79.95 per year after</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className={'d-flex align-items-center justify-content-center'}>
                                <div className={'d-flex align-items-center justify-content-left col-container'}>
                                    <div className={'dot-placer mb-4 mr-3'}/>
                                    <div className={hasAffiliate?'discounted-price':''}>
                                        {hasAffiliate&&<span className={'new-price'}>$ 32.00</span>}
                                        <span className={'price-container'}>$ 55.00</span><span
                                        className={'sub-price-container'}>/ Billed Yearly</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{bottom: '-62px'}} className={'white-wave down'}/>
                </section>
                <section className={'waiting-section'}>
                    <div className={'waiting-title'}>So What are You Waiting For?</div>
                    <div className={'waiting-sub-title'}>If you have great original songs
                        you’d like to get cut, take the next step:
                    </div>
                    <Container>
                        <Row>
                            <Col lg={6} md={6}>
                                <div className={'black-section'}/>
                                <div className={'image-container'}/>
                            </Col>
                            <Col lg={6} md={6}>
                                <div className={'num-text-container mt-3'}>
                                    <div className={'num-text'}>
                                        <div className={'number-container'}>1</div>
                                        <div className={'text-container'}>
                                            <span>Sign up</span> and <span>submit 3 MP3’s</span> for our A&R team to
                                            review
                                        </div>
                                    </div>
                                </div>
                                <div className={'num-text-container mt-3'}>
                                    <div className={'num-text'}>
                                        <div className={'number-container'}>2</div>
                                        <div className={'text-container'}>Allow <span>5 days</span> for your
                                            submissions <span>to be reviewed</span></div>
                                    </div>
                                </div>
                                <div className={'num-text-container mt-3 mb-5'}>
                                    <div className={'num-text'}>
                                        <div className={'number-container'}>3</div>
                                        <div className={'text-container'}>Log into your partner account and <span>view / manage your sales</span>
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={this.navOpen} className={'fullsizedOrangeBtn smallOrangebtn mt-5'}>Join as a content
                                    partner</Button>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <Footer/>
            </div>
        )
    }
}

export default connect( state => ({state}), dispatch => ({
    onRegisterAffiliate: (token) => {
        dispatch({
            type: "CREATE_AFFILIATE",
            payload: token
        })
    },
}))(withRouter(ContentPartner));
