import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import api from "../../api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faDollarSign,
    faMusic,
    faShoppingCart,
    faSignOutAlt,
    faStar,
    faTimesCircle,
    faUser
} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import FacebookLogin from 'react-facebook-login';
import axios from "axios";

class AuthBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAuth: 1,
            showForgot: 0,
            showResetMessage: 0,
            showFacebookReg: 0,
            showRegister: 0,
            loginMes: false,
            regMes: false,
            userRegVen: false,
            userRegArt: false,
            resetMes: false,
            password: '',
            userRegType: '',
            showTypeSwitch: false,
            confirmPassword: ''
        };
        this.wrapperRef = React.createRef();
        this.hideBlock = React.createRef();

    }


    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
        script.async = true;
        document.body.appendChild(script);
    }

    handleClick() {
        this.wrapperRef.current.classList.toggle('is-nav-open');
        document.getElementById('document-html').classList.toggle('hidden-overflow')
        this.hideBlock.current.classList.toggle('show-block');
        this.setState({
            showAuth: 1,
            showForgot: 0,
            showRegister: 0,
            showResetMessage: 0,
            userRegType: ''
        })
    }
    onCaptchaChange = (value) => {

        this.setState({
            captchaValue: value,
        });
    };
    async goToRegistration(type) {
        if(this.props.state.auth.user_id){
            return;
        }
        this.handleClick();
        this.setState({
            showAuth: 0,
            showForgot: 0,
            showRegister: 1,
            showFacebookReg: 0,
            userRegType: type,
        })
    }

    passwordHandler = (e) => {
        this.setState({
            password: e.target.value,
        })
    }
    confirmPassHandler = (e) => {
        this.setState({
            confirmPassword: e.target.value,
        })
    }
    handleRegSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault(form);
        const formDat = new FormData(form);
        if(this.props.state.affiliate.key){
            formDat.append('AffilateToken', this.props.state.affiliate.key)
        }
        const captchaToken = await this.executeRecaptcha('registration');

        if (!captchaToken) {
            this.setState({ regMes: "reCAPTCHA verification failed." });
            return;
        }

        if (form.checkValidity() === true) {
            if (this.state.password === this.state.confirmPassword) {
                this.setState({loading: true})
                axios.request( {
                    url: `${api.Signup.url}`,
                    method: api.Signup.method,
                    data: {
                        AffilateToken:this.props.state.affiliate.key,
                        firstName:formDat.get('firstName'),
                        lastName:formDat.get('lastName'),
                        type:formDat.get('type'),
                        artistName:formDat.get('artistName'),
                        vendorName:formDat.get('vendorName'),
                        username:formDat.get('username'),
                        password:formDat.get('password'),
                        token:captchaToken
                    }
                }).then(response => response.data).then(response => {
                        if (response.Message) {
                            this.setState({
                                regMes: response.Message,
                                loading: false
                            })
                        } else {
                            let cartReqData;
                            if (this.props.state.cart.Items && this.props.state.cart.Items.length) {
                                cartReqData = {
                                    UserId: response.results.user_id,
                                    Items: [
                                        ...this.props.state.cart.Items.map(u=>({
                                            ProductId: u.ProductId,
                                            LicenseId: u.LicenseId,
                                        }))
                                    ]
                                }
                            }
                            Promise.all([
                                axios.request( {
                                    url: `${api.User.Get.url}${response.results.user_id}`,
                                    method: api.User.Get.method,
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'cache-control':'no-cache',
                                        'Authorization': 'Bearer ' + response.results.access_token
                                    }
                                }).then(resp => resp.data),
                                cartReqData && !response.vendor_id ?
                                    axios.request( {
                                        url: api.Cart.Add.url,
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + response.results.access_token
                                        },
                                        method: api.Cart.Add.method,
                                        data: JSON.stringify(cartReqData),
                                    }).then(response => response.data)
                                    : axios.request( {
                                    url: `${api.Cart.Get.url}${response.results.user_id}`,
                                    method: api.Cart.Get.method,
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + response.results.access_token
                                    },
                                }).then(resp => resp.data),
                            ]).then(responses => {
                                const resp = responses[0]
                                this.props.onLogin({resp, response})
                                this.props.onCartFetch(responses[1])
                                this.setState({loading: false,regMes:false});
                                if(resp.type==='Applicant'){
                                    setTimeout(()=> document.location.pathname = '/Profile',1000)
                                }

                            })
                        }
                    })
            } else {
                this.setState({
                    regMes: 'Your Passwords did not match.'
                })
            }
        } else {
            this.setState({
                regMes: 'Somethings Wrong'
            })
        }

    }
    handleLoginSubmit = async (event) => {
        this.setState({loading: true})
        const form = event.currentTarget;
        event.preventDefault();
        const formData = new FormData(form);
        const captchaToken = await this.executeRecaptcha('login');

        if (!captchaToken) {
            this.setState({ loginMes: "reCAPTCHA verification failed." });
            return;
        }
        if (form.checkValidity() === true) {
            axios.request( {
                url: api.Login.url,
                method: api.Login.method,
                data: {
                    token:captchaToken,
                    username:formData.get('username'),
                    password:formData.get('password'),

                }
            }).then(response => response.data).then(response => {
                if (response.Message) {
                    this.setState({
                        loginMes: response.Message,
                        loading: false
                    })
                } else {
                    let cartReqData;
                    if (this.props.state.cart.Items && this.props.state.cart.Items.length) {
                        cartReqData = {
                            UserId: response.results.user_id,
                            Items: [
                                ...this.props.state.cart.Items.map(u=>({
                                    ProductId: u.ProductId,
                                    LicenseId: u.LicenseId,
                                }))
                            ]
                        }
                    }
                    Promise.all([
                       axios.request( {
                            url:`${api.User.Get.url}${response.results.user_id}`,
                            method: api.User.Get.method,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + response.results.access_token
                            }
                        }).then(resp => resp.data),
                        cartReqData && !response.vendor_id ?
                           axios.request( {
                                url:api.Cart.Add.url,
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + response.results.access_token
                                },
                                method: api.Cart.Add.method,
                                data: JSON.stringify(cartReqData),
                            }).then(response => response.data)
                            : axios.request( {
                            url:`${api.Cart.Get.url}${response.results.user_id}`,
                            method: api.Cart.Get.method,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + response.results.access_token
                            }
                        }).then(resp => resp.data),
                        !response.vendor_id?axios.request( {
                            url:`${api.User.Get.url}${response.results.user_id}/favoriteSongs?start=0&pageSize=500`,
                            method: api.User.Get.method,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + response.results.access_token
                            },
                        }).then(response=>response.data):Promise.resolve(),
                    ]).then(responses => {
                        const resp = responses[0]
                        this.setState({loading: false,loginMes:false});
                        this.props.onLogin({resp, response})
                        this.props.onCartFetch(responses[1])
                        if(responses[2]){
                            this.props.onGetFavs(responses[2].results)
                        }
                        if(!resp.hasActiveSubscription && resp.type==='Provider'){
                            document.location.href ='/VendorSubscription'
                        }
                    })
                }
            })
        }

    }
    resetPasswordHandler = (event) => {
        this.setState({loading: true})
        const form = event.currentTarget;
        event.preventDefault();
        const formData = new FormData(form);
        if (form.checkValidity() === true) {
            axios.request( {
                url: api.Reset.url,
                method: api.Reset.method,
                data: formData
            }).then(response => {
                if (response.status===200) {
                    this.setState({
                        showAuth: 0,
                        showForgot: 0,
                        showRegister: 0,
                        showResetMessage: 1,
                        loading:false,
                    })
                }else{
                    this.setState({
                        showAuth: 0,
                        showForgot: 0,
                        showRegister: 0,
                        showResetMessage: 1,
                        loading:false,
                    })
                }
            })
        }
    }
    handleRegFacebook = (response) => {
        if( response.accessToken){
            this.setState({
                loading: true
            })
        const formData = new FormData();
        formData.append('access_token', response.accessToken)
        if (this.state.userRegType === 'Performer') {
            formData.append('artistName', this.state.userRegArt)
            formData.append('type', this.state.userRegType)
        }else if (this.state.userRegType === 'Provider') {
            formData.append('vendorName', this.state.userRegVen)
            formData.append('type', this.state.userRegType)
        }
        if(this.props.state.affiliate.key){
            formData.append('AffilateToken', this.props.state.affiliate.key)
        }
        axios.request( {
            url: api.FacebookAuth.url,
            method: api.FacebookAuth.method,
            data: formData
        }).then(response => response.data).then(response => {
            let cartReqData;
            if (this.props.state.cart.Items && this.props.state.cart.Items.length) {
                cartReqData = {
                    UserId: response.results.user_id,
                    Items: [
                        ...this.props.state.cart.Items
                    ]
                }
            }
            Promise.all([
                axios.request( {
                    url: `${api.User.Get.url}${response.results.user_id}`,
                    method: api.User.Get.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + response.results.access_token
                    }
                }).then(resp => resp.data),
                cartReqData && !response.vendor_id ?
                    axios.request( {
                        url: api.Cart.Add.url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + response.results.access_token
                        },
                        method: api.Cart.Add.method,
                        data: JSON.stringify(cartReqData),
                    }).then(response => response.data)
                    : axios.request( {
                    url:`${api.Cart.Get.url}${response.results.user_id}`,
                    method: api.Cart.Get.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + response.results.access_token
                    }
                }).then(resp => resp.data),
            ]).then(responses => {
                const resp = responses[0]
                this.setState({loading: false});
                this.props.onLogin({resp, response})
                this.props.onCartFetch(responses[1])
                if (resp.type === 'Applicant') {
                    document.location.pathname = '/Profile';
                }
            })
        })
        }
    }

    executeRecaptcha = async (action) => {
        return new Promise((resolve, reject) => {
            if (window.grecaptcha) {
                try {
                    window.grecaptcha.ready( async ()=>{
                        const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action});
                        this.setState({captchaValue: token});
                        resolve(token);
                    })
                } catch (error) {
                    this.setState({regMes: "reCAPTCHA verification failed."});
                    reject(null);
                }
            }
        })
    };
    render() {
        return (
            <div>
                {!this.props.state.auth.vendor_id && (this.props.state.cart.Items ||this.props.state.cart.cartItems) && !document.location?.pathname.includes('/Cart') ? <Link to={'/Cart'}>
                    <div className={'shopping-cart-fixed'}><span
                        className={'fixed-counter-count'}>{this.props.state.cart.Items?this.props.state.cart.Items.length:this.props.state.cart.cartItems.length}</span><FontAwesomeIcon
                        className={'fixed-cart-icon'} icon={faShoppingCart}/></div>
                </Link> : null}

                <div ref={this.hideBlock} onClick={this.handleClick.bind(this)} className={'hideblock'}/>
                <nav ref={this.wrapperRef} className={"sidebar sidebar-content"}
                     style={{width: this.props.state.auth.user_id ? '320px' : '400px'}}>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className={"col-centered close-container"}>
                            <button onClick={this.handleClick.bind(this)} className={'close'} type="button"><span
                            >×</span></button>
                        </Col>
                        {
                            this.props.state.auth.access_token ?
                                <div>
                                    <Row className={'logged-row'} style={{textAlign: 'center'}}>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <p style={{textAlign: 'center'}}>Welcome, {this.props.state.auth.user.firstName}!</p></Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className={'mb-3'}>
                                            <Link onClick={this.handleClick.bind(this)}
                                                  className={'sidebar-logged-buttons'} to={'/Profile/myProfile'}>
                                                <FontAwesomeIcon icon={faUser}/>Profile
                                            </Link>
                                        </Col>
                                        {this.props.state.auth.user.type!=='Applicant' && !this.props.state.auth.vendor_id ?
                                            <Col lg={12} md={12} sm={12} xs={12}  className={'mb-3'}>
                                                <Link style={{marginLeft:'16px'}} onClick={this.handleClick.bind(this)}
                                                      className={'sidebar-logged-buttons'} to={'/Profile/myFavorites'}>
                                                    <FontAwesomeIcon icon={faStar}/>Favorites
                                                </Link>
                                                {this.props.state.favorites.length ? <span className="circled">{this.props.state.favorites.length}</span> : null}
                                            </Col> : <Col lg={12} md={12} sm={12} xs={12}  className={'mb-3'}>
                                                <Link style={{marginLeft:'12px'}} onClick={this.handleClick.bind(this)}
                                                      className={'sidebar-logged-buttons'} to={'/Profile/mySongs'}>
                                                    <FontAwesomeIcon icon={faMusic}/>My songs
                                                </Link>
                                            </Col>}
                                        {this.props.state.auth.user.type!=='Applicant' &&!this.props.state.auth.vendor_id ?
                                            <Col lg={12} md={12} sm={12} xs={12} className={'mb-3'}>
                                                <Link style={{marginLeft:'-24px'}} onClick={this.handleClick.bind(this)}
                                                      className={'sidebar-logged-buttons'} to={'/Cart'}>
                                                    <FontAwesomeIcon icon={faShoppingCart}/>Cart
                                                </Link>
                                                {this.props.state.cart.cartItems && this.props.state.cart.cartItems.length ?
                                                    <span
                                                        className="circled">{this.props.state.cart.cartItems.length}</span> : null}
                                            </Col>
                                            : <Col lg={12} md={12} sm={12} xs={12} className={'mb-3'}>
                                                <Link onClick={this.handleClick.bind(this)}
                                                      className={'sidebar-logged-buttons'} to={'/Profile/mySales'}>
                                                    <FontAwesomeIcon icon={faDollarSign}/>My Sales
                                                </Link>
                                            </Col>}
                                    </Row>
                                    <Row className={'logout-row'}>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <a target={'_blank'} rel="noopener noreferrer" className={'sidebar-logged-socials twitter'}
                                               href={'https://twitter.com/rocket_songs'}>
                                                <FontAwesomeIcon icon={faTwitter}/>
                                            </a>

                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <a target={'_blank'} rel="noopener noreferrer" className={'sidebar-logged-socials facebook'}
                                               href={'https://www.facebook.com/rocketsongs'}>
                                                <FontAwesomeIcon icon={faFacebook}/>
                                            </a>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <a target={'_blank'} rel="noopener noreferrer" className={'sidebar-logged-socials youtube'}
                                               href={'https://www.youtube.com/user/RocketSongs'}>
                                                <FontAwesomeIcon icon={faYoutube}/>
                                            </a>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}
                                             style={{marginTop: '3.25rem',marginBottom:'1.5rem', width: '50%', marginLeft: '25%'}}>
                                            <Button onClick={() =>{this.handleClick();this.props.onLogout();}}
                                                    className={'sidebar-logout-button'}>
                                                <FontAwesomeIcon icon={faSignOutAlt}/>Logout
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                : this.state.showAuth ?
                                <div className={'auth-content-up'}>
                                    <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                         sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}
                                         className={"col-centered"}>
                                        <h1 className={"title"}>Login</h1>
                                        <p className={"subtitle"}>
                                            Log into your Rocket Songs account
                                        </p>
                                    </Col>
                                    <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                         sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}>

                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                            fields="name,email,picture"
                                            cssClass="hidden"
                                            callback={this.handleRegFacebook}/>
                                        <button onClick={() => document.getElementsByClassName('hidden')[0].click()}
                                                type="button"
                                                className={"btn  btn-facebook"}>LOGIN WITH FACEBOOK
                                        </button>

                                    </Col>
                                    <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                         sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}>
                                        <p className={"subtitle"}>Or log in with your e-mail</p>
                                    </Col>
                                    <Col className={"marging-bottom"} lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                         sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}>
                                        <Form onSubmit={this.handleLoginSubmit}>
                                            <Alert variant={'danger'}
                                                   style={{textAlign:'left',padding:'0.75rem 5px',display: this.state.loginMes ? 'block' : 'none'}}><FontAwesomeIcon style={{margin:'0 8px',color:'red'}} icon={faTimesCircle}/>{this.state.loginMes}</Alert>
                                            <Form.Group controlId="email">
                                                <Form.Control type="email" name={'username'}
                                                              placeholder="Email Address"/>
                                            </Form.Group>
                                            <Form.Group controlId="password">
                                                <Form.Control type="password" name={'password'}
                                                              placeholder="Password"/>
                                            </Form.Group>

                                            <button type="submit" className={"btn login"}
                                                    disabled={this.state.loading}>{this.state.loading ?
                                                <Spinner animation="border"/> : 'LOGIN'}</button>
                                        </Form>
                                        <Link onClick={() => this.setState({
                                            showAuth: 0,
                                            showForgot: 1,
                                            showRegister: 0,
                                            showFacebookReg: 0,
                                        })} to={'#'} className={"forgot"}>Help, I forgot my password!</Link>
                                    </Col>
                                    <Col className={"logout-row"} lg={{span: 10, offset: 1}}
                                         md={{span: 10, offset: 1}} sm={{span: 10, offset: 1}}
                                         xs={{span: 10, offset: 1}}>
                                        <div className={"subtitle"}>Don't have an account?</div>
                                        <Button onClick={() => this.setState({
                                            showAuth: 0,
                                            showForgot: 0,
                                            showRegister: 1,
                                            showFacebookReg: 0,
                                        })} className={"btn reg-email"}>REGISTER </Button>
                                    </Col>
                                </div> : this.state.showFacebookReg ?
                                    <Row className={'auth-content-up'}>
                                        <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                             sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}
                                             className={"col-centered"}>
                                            <h1 className={"title"}>Registration With Facebook</h1>
                                        </Col>
                                        <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                             sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}
                                             className={"col-centered"}>
                                            <Form.Group controlId="type">
                                                <Form.Control defaultValue={''} onChange={(e) => this.setState({
                                                    userRegType: e.target.value,
                                                })} className={'reg-type-select'} name={'type'} as="select" size="3"
                                                              required>
                                                    <option value={''} style={{display: "none"}}>Please select account type
                                                    </option>
                                                    <option value={'Performer'}>Artist</option>
                                                    <option value={'Provider'}>Songwriter/Publisher</option>
                                                </Form.Control>
                                            </Form.Group>
                                            {this.state.userRegType === '' ?
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Name" required />
                                                </Form.Group>
                                            :
                                            this.state.userRegType === 'Performer' ?
                                                <Form.Group controlId="artistname">
                                                    <Form.Control onChange={(e) => this.setState({
                                                        userRegArt: e.target.value,
                                                    })} name={'artistName'} type="text"
                                                                  placeholder="Artist Name" required/>
                                                </Form.Group>
                                                :
                                                <Form.Group controlId="VendorName">
                                                    <Form.Control onChange={(e) => this.setState({
                                                        userRegVen: e.target.value,
                                                    })} name={'vendorName'} type="text"
                                                                  placeholder="Songwriter/Publisher Name" required/>
                                                </Form.Group>
                                            }
                                            <button onClick={() => document.getElementsByClassName('hidden')[0].click()}
                                                    className={"btn btn-facebook"} disabled={!(this.state.userRegType && (this.state.userRegVen || this.state.userRegArt))}>REGISTER WITH FACEBOOK</button>
                                        </Col>
                                        <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                             sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}
                                             className={"col-centered"}>
                                            <FacebookLogin
                                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                                fields="name,email,picture"
                                                cssClass="hidden"
                                                callback={this.handleRegFacebook}/>

                                        </Col>
                                    </Row>
                                    : this.state.showForgot ?

                                        <div className={'auth-content-up'}>
                                            <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                                 sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}
                                                 className={"col-centered"}>
                                                <h1 className={"title"}>Forgot Password</h1>
                                                <p className={"subtitle"}>
                                                    Enter the email address you used to create your Rocket Songs account and we will send you an email with further instructions
                                                </p>
                                            </Col>
                                            <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                                 sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}>
                                                <Form onSubmit={this.resetPasswordHandler}>
                                                    <Form.Group controlId="email">
                                                        <Form.Control name={'username'} type="email"
                                                                      placeholder="Email Address" required/>
                                                    </Form.Group>
                                                    <Alert variant={'danger'}
                                                           style={{display: this.state.resetMes ? 'block' : 'none'}}>{this.state.resetMes}</Alert>

                                                    <button type="submit" className={"btn login"} disabled={this.state.loading}>{this.state.loading ?
                                                        <Spinner animation="border"/>:'RESET PASSWORD'}</button>
                                                </Form>
                                            </Col>
                                        </div>
                                        : this.state.showRegister ?
                                            <div className={'auth-content-up'}>

                                                <Col lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}}
                                                     sm={{span: 10, offset: 1}} xs={{span: 10, offset: 1}}>
                                                    <h1 className={"title"}>Create Account</h1>
                                                    <button onClick={() => {
                                                        this.setState({
                                                            showAuth: 0,
                                                            showForgot: 0,
                                                            showRegister: 0,
                                                            showFacebookReg: 1,
                                                        })
                                                    }} className={"btn btn-facebook"}>REGISTER WITH FACEBOOK</button>
                                                    <p className="subtitle">Or enter your details below</p>
                                                    <Form onSubmit={this.handleRegSubmit}>
                                                        <Form.Group controlId="firstName">
                                                            <Form.Control name={'firstName'} type="text"
                                                                          placeholder="First Name" required/>
                                                        </Form.Group>
                                                        <Form.Group controlId="lastname">
                                                            <Form.Control name={'lastName'} type="text"
                                                                          placeholder="Last Name" required/>
                                                        </Form.Group>

                                                        <Form.Group controlId="type">
                                                            <Form.Control value={this.state.userRegType} onChange={(e) => this.setState({
                                                                userRegType: e.target.value
                                                            })} className={'reg-type-select'} name={'type'} as="select"
                                                                          size="3" required>
                                                                <option value={''} style={{display: "none"}}>Please select account type
                                                                </option>
                                                                <option value={'Performer'}>Artist</option>
                                                                <option value={'Provider'}>Songwriter/Publisher</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {this.state.userRegType === '' ?
                                                            <Form.Group>
                                                                <Form.Control type="text" placeholder="Name" required />
                                                            </Form.Group>
                                                        :
                                                            this.state.userRegType === 'Performer' ?
                                                                <Form.Group controlId="artistname">
                                                                    <Form.Control name={'artistName'} type="text"
                                                                                placeholder="Artist Name" required/>
                                                                </Form.Group>
                                                            :
                                                                <Form.Group controlId="VendorName">
                                                                    <Form.Control name={'vendorName'} type="text"
                                                                                placeholder="Songwriter/Publisher Name"
                                                                                required/>
                                                                </Form.Group>
                                                        }

                                                        <Form.Group controlId="email">
                                                            <Form.Control name={'username'} type="email"
                                                                          placeholder="Email Address" required/>
                                                        </Form.Group>
                                                        <Form.Group controlId="password">
                                                            <Form.Control name={'password'} type="password"
                                                                          placeholder="Password"
                                                                          onChange={this.passwordHandler} required/>
                                                        </Form.Group>
                                                        <Form.Group controlId="confpassword">
                                                            <Form.Control type="password" placeholder="Confirm Password"
                                                                          onChange={this.confirmPassHandler} required/>
                                                        </Form.Group>
                                                        <Alert variant={'danger'}
                                                               style={{display: this.state.regMes ? 'block' : 'none'}}>{this.state.regMes}</Alert>

                                                        <button type="submit" className={"btn login"}
                                                                disabled={this.state.loading}>{this.state.loading ?
                                                            <Spinner animation="border"/> : 'REGISTER'}</button>
                                                    </Form>
                                                    <div className="text">By registering with Rocket Songs, you are agreeing to our <Link
                                                        to="/Legal" target="_blank">Terms of Service</Link> and <Link
                                                        to="/LicenseAgreement"
                                                        target="_blank">License Agreement</Link>.</div>
                                                </Col>
                                            </div>
                                            : this.state.showResetMessage ?
                                                <Row className={'auth-content-up'}>
                                                    <Col lg={{offset:1,span:10}} md={{offset:1,span:10}} sm={{offset:1,span:10}} xs={{offset:1,span:10}}>
                                                        <p className="title">Retrieve Password</p>
                                                        <p className="subtitle">An email has been sent to the email
                                                            provided in order to change your password</p>
                                                    </Col>
                                                </Row>
                                                : null

                        }
                    </Row>
                </nav>
            </div>
        )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onLogin: (token) => {
            dispatch({
                type: "LOGIN",
                payload: token
            })
        },
        onCartFetch: (cart) => {
            dispatch({
                type: "FETCH_CART",
                payload: cart
            })
        },
        onGetFavs: (favs) => {
            dispatch({
                type: "FETCH_FAVS",
                payload: favs
            })
        },
        onLogout: () => {
            dispatch({
                type: "LOGOUT"
            })
        },
    }), null, {forwardRef: true})(AuthBar);
