export default function player(state = {playMusic:{}}, action){
    if(action.type === 'PLAY'){
        state.playMusic={
            ...action.payload
        }
        return {
            ...state
        }
    }
    if(action.type === 'STOP'){

        return {
          playMusic: {

          }
        }
    }

    if(action.type === 'LOGOUT'){
        return {
            playMusic:{

            }
        }
    }

    return state;
}
