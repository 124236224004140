import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import axios from "axios";
import api from "../../../api";
import {Form, Modal, Spinner} from "react-bootstrap";
import moment from "moment";
import './SubscriptionModal.sass';
import Logo from '../../../images/logo_no_text.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";


class SubscriptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        if(!this.props.state.subscription?.subscribed && (!this.props.state.subscription?.lastShown || moment().utc(true).diff(moment(this.props.state.subscription?.lastShown),'hours')>=24) && !this.props.state.auth.token){
            this.setState({
                showModal:true
            })
            this.props.onModalShow();
        }
    }
    handleSubmitSubscription = (event) =>{
        const form = event.currentTarget;
        event.preventDefault();
        this.setState({
            loading:true
        })
        axios.request( {
            url: api.MailChimp.url,
            method: api.MailChimp.method,
            data: {
                Email:form.email.value,
                HasSongs:form.checkbox.checked
            }
        }).then(() =>{
            this.setState({
                showModal:false,
                loading:false
            })
            this.props.onSubscribed()
        })

    }

    render() {
        return <Modal className={'subscription-modal'} show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
            <Modal.Body className={'subscription-modal-body'}>
                <div style={{right:20}} onClick={() => this.setState({showModal: false})} className={'close-button'}><FontAwesomeIcon icon={faTimes}/></div>
                <div className={'logo-container'}>
                    <img alt={''} src={Logo}/>
                </div>
                <div className={'title-container'}>
                    <div className={'big-title'}>
                        <div>DON'T MISS THE CHANCE TO FIND YOUR</div>
                        <div>NEXT HIT SONG! STAY UPDATED!</div>
                    </div>
                    <div className={'small-title'}>
                        YES! I would like to receive news and offers from Rocket Songs!
                    </div>
                </div>
                <div className={'subscription-form'}>
                    <Form onSubmit={this.handleSubmitSubscription}>
                        <Form.Group controlId="sub-email">
                            <Form.Control type="email" name={'email'} required/>
                        </Form.Group>
                        <button type="submit">{this.state.loading?<Spinner animation="border" />:'SUBMIT'}</button>
                        <Form.Check type={'checkbox'} >
                            <Form.Check.Input name={'checkbox'} type={'checkbox'}  />
                            <Form.Check.Label>{'<-'} CLICK HERE ONLY if you have or own songs and want updates exclusively for Content Owners</Form.Check.Label>
                        </Form.Check>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onModalShow: () => {
            dispatch({
                type: "SHOW_MODAL"
            })
        },
        onSubscribed: () => {
            dispatch({
                type: "USER_SUBSCRIBED"
            })
        },
    }))(withRouter(SubscriptionModal));
