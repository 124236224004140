import React, {Component} from "react";
import { Col, Container, Row} from "react-bootstrap";
import Header from "../Fragments/Header";
import Footer from "../Fragments/Footer";
import userImg from '../../images/Author-Icon.png'
import placeholder from '../../images/placeholder-music.png'
import {withRouter} from "react-router-dom";
import api from "../../api";
import {connect} from "react-redux";
import MusicTable from "../Fragments/MusicTable/MusicTable";
import ReactHtmlParser from 'react-html-parser';
import Preloader from "../Fragments/Preloader";
import axios from "axios";
import MetaTags from "react-meta-tags";
class TrackOwner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            musicList:{},
            trackOwner:{},
            start:0,
            pageLoading:true,
            loading:true,

        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        axios.request( {
            url:`${api.TrackOwner.url}${this.props.match.params.id}`,
            method: api.TrackOwner.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response=>response.data).then(response=>{
            this.setState({
                trackOwner:response,
                pageLoading:false,
            })
            document.title = "About " + response.name;
            axios.request( {
                url:`${api.Songs.url}?keyword=${response.name}`,
                method: api.Songs.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(songs=>songs.data).then(songs=>{
                this.setState({
                    musicList:songs,
                    start:songs.pagination.nextStart,
                    loading:false
                })
            })
        })
    }
    getSongs=()=>{
        let scrollPos = window.pageYOffset;
        this.setState({loading:true})
        axios.request( {
            url:`${api.Songs.url}?start=${this.state.start}&keyword=${this.state.trackOwner.name}`,
            method: api.Songs.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response=>response.data).then(response=>{
            if(response.pagination){
                this.setState({
                    musicList:{
                        ...response,
                        results:this.state.musicList.results.concat(response.results)},
                    start:response.pagination.nextStart,
                    loading:false,
                },()=>window.scroll(0, scrollPos));
            }else{
                this.setState({
                    loading:false,
                });
            }

        })
    }
    render() {
        return (
            <div className={"trackowner"}>
                <MetaTags>
                    <meta name="description" content="Rocket songs is a leading company for original songs. You can buy original songs to record for original singers and artists from top writers in the USA." />
                </MetaTags>
                <Preloader display={this.state.pageLoading?'block':'none'}/>
                <Header title={<img alt="" src={userImg}/>} subtitle={<span className={'trackowner-subtitle'}>TRACK OWNER PROFILE</span>}/>
                <div className={'last-impression owner'}>
                    <h1 className={'trackowner-title'}>{this.state.trackOwner.name}</h1>
                    <p className={'trackowner-subtitle-white'}>{this.state.trackOwner.city}{this.state.trackOwner.country?','+this.state.trackOwner.country:null}</p>
                </div>
                <section>
                    <Container >
                        <Row style={{padding:'70px 0'}}>
                            <Col style={{textAlign: 'center'}} lg={4}>
                                <figure >
                                    <img alt="" style={{border: '1px solid #000',maxWidth:'272px'}} src={this.state.trackOwner.imageLink?this.state.trackOwner.imageLink:placeholder}/>
                                </figure>
                            </Col>
                            <Col style={{marginTop:'-7px'}} lg={8}>
                                <h3 className={'trackowner-content-title'}>ABOUT {this.state.trackOwner.name}</h3>
                                {ReactHtmlParser(this.state.trackOwner.bio)}
                            </Col>
                        </Row>
                    </Container>
                        <MusicTable header={this.header} musicList={this.state.musicList} getSongs={this.getSongs} loading={this.state.loading}/>

                </section>
                <Footer/>
            </div>
        )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({

    }))(withRouter(TrackOwner));
