import React, {Component} from "react";
import Header from "../../Fragments/Header";
import Footer from "../../Fragments/Footer";
import {withRouter} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {connect} from 'react-redux';
import Preloader from "../../Fragments/Preloader";
import './HomePage.sass'
import {Col, Container, Row} from "react-bootstrap";
import Actors from "./Fragments/Actors";
import SecondSection from "./Fragments/SecondSection";
import OpinionsCard from "./Fragments/OpinionsCard";
import ContactForm from "../../Fragments/ContactForm";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state={
            modalVisible:false
        }
    }
    f
    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.props.location.search==='?Verified=true'){
            this.props.Toast({
                type:'success',
                message:'Successfully verified'
            })
        }else if(this.props.location.search==='?Verified=false'){
            this.props.Toast({
                type:'error',
                message:'Verification failed'
            })
        }

    }


    render() {
        let opinions = [
            {
                title:'Destiny',
                text:'I love Rocket Songs! There are so many great songs and it meansI can sing original songs without having to find good songwriters— because Rocket Songs has it all right there.',
                date:'Jan 18, 2018'
            },
            {
                title:'Liz B.',
                text:'As a struggling artist, getting the instrumental backing tracks to usesaves me tons of money in a studio. The tracks are so well produced.',
                date:'Jan 18, 2018'
            },
            {
                title:'Victoria K.',
                text:'For any singer to get discovered or signed, you got to haveoriginal songs to sing. Thanks Rocket Songs.',
                date:'July 20, 2019'
            },
            {
                title:'Michael M.',
                text:'Finally a site for singers who need original songs— wish you guys were around years ago.',
                date:'Jan 22, 2019'
            },
            {
                title:'Shelly  V.',
                text:'Awesome.So easy to search and find great new songs quick … thanks!',
                date:'Jan 22, 2019'
            },
            {
                title:'John J.',
                text:'Personally, I\'ve always been a way better singer but not a good songwriter.With Rocket Songs, I can sing and record great songs and make them my own.',
                date:'Jan 22, 2019'
            },
            {
                title:'Tony C.',
                text:'For any singer who needs great original songs to sing,nothing beats Rocket Songs … it\'s GREAT!',
                date:'Jan 22, 2019'
            },
            {
                title:'Samantha F.',
                text:'It\'s really like listening to lots of original songsto sing until I find the one that speaks to me. It\'s likethe writer wrote it just for me — for my voice. ',
                date:'Jan 22, 2019'
            },


        ]

        return (
            <div className={'home-page'}>
                <MetaTags>
                    <title>Original Songs for Original Artists - Rocket Songs</title>
                    <meta name="description" content="Rocket songs is a leading company for original songs. You can buy original songs to record for original singers and artists from top writers in the USA." />
                </MetaTags>
                <Preloader display={this.state.pageLoading?'block':'none'}/>
                <Header ref={this.header} >
                    <div className={'header-title-child'}>Take your music career to the next level!</div>
                    <div className={'header-subtitle-child'}>Rocket Songs is the only place to legally get the best songs from the top songwriters from around the globe.</div>
                    <div className={'header-button'}><button onClick={()=>this.props.history.push('/shop')} className={'simple-orange-button'}>25% OFF YOUR FIRST PURCHASE</button> </div>
                </Header>

                <Actors/>

                <SecondSection/>

                <section className={'opinions-section'}>
                    <Container>
                        <Row>
                            <Col className={'centerable'} lg={6} md={6}>
                                <div className={'opinion-text-container'}>
                                        <div className={'opinions-t'}>Opinions</div>
                                        <div className={'opinions-title'}>What do they say about us?</div>
                                        <div className={'opinions-text'}>Our goal is to satisfy every creative person, from song writers and producers to artists that are looking for the track that is just for them. So we prefer full transparency. </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                                <Row className={'opinion-cards-container'}>
                                    <Col lg={6} md={12}  xs={6}>
                                        {opinions.map((opinion,key)=>{
                                            if((key+1)%2){
                                                return <OpinionsCard key={key} userName={opinion.title} text={opinion.text} date={opinion.date}/>
                                            }
                                            return null
                                        })}
                                    </Col>
                                    <Col lg={6} md={12} xs={6}>
                                        {opinions.map((opinion,key)=>{
                                            if(!((key+1)%2)){
                                                return <OpinionsCard key={key} userName={opinion.title} text={opinion.text} date={opinion.date}/>
                                            }
                                            return null
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
               <ContactForm/>
                <Footer/>
            </div>
        )
    }
}

export default connect( state => ({
        state
    }),
    dispatch => ({
        Toast: (data) => {
            dispatch({
                type: "NOTIFICATE",
                payload: data
            })
        },
    }))(withRouter(HomePage));
