import { combineReducers } from 'redux';
import auth from './auth'
import cart from "./cart";
import player from "./player";
import notification from "./notification";
import favorites from "./favorites";
import affiliate from "./affiliate";
import subscription from "./subscription";


export default combineReducers({
    auth,
    cart,
    player,
    notification,
    favorites,
    affiliate,
    subscription
})
